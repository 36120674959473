import type { State } from '@/app/imageLibrary/store/index';
import type { BrandSearchResult } from '@/app/imageLibrary/types';
import type { StateCreator } from 'zustand';

export interface BrandSlice {
    activeBrand: null | BrandSearchResult;

    setActiveBrand: (activeBrand: BrandSearchResult) => void;
}

export const createBrandSlice: StateCreator<
    State,
    [['zustand/devtools', never]],
    [],
    BrandSlice
> = (set) => ({
    activeBrand: null,

    setActiveBrand: (activeBrand: BrandSearchResult) => {
        set({ activeBrand });
    },
});
