import { Switch } from '@headlessui/react';

import { cn } from '@/utils/cn';

export interface Props {
    checked: boolean;
    onChange: (checked: boolean) => void;
    onClick?: (event) => void;
    srTitle?: string;
    size?: 'small' | 'medium' | 'large';
    className?: string;
    checkedBgColor?: string;
    disabled?: boolean;
}

const sizeMap: { [size: string]: { wrapper: string; dot: string; translate: string } } = {
    small: {
        wrapper: 'h-4 w-8',
        dot: 'size-3',
        translate: 'translate-x-4',
    },
    medium: {
        wrapper: 'h-6 w-12',
        dot: 'size-5',
        translate: 'translate-x-6',
    },
    large: {
        wrapper: 'h-10 w-20',
        dot: 'size-9',
        translate: 'translate-x-10',
    },
};

const Toggle = ({
    checked,
    onChange,
    onClick,
    srTitle,
    size = 'small',
    className,
    checkedBgColor = 'bg-green-500',
    disabled,
}: Props) => {
    return (
        <Switch
            checked={checked}
            onClick={!disabled ? onClick : undefined}
            onChange={!disabled ? onChange : undefined}
            className={cn(
                checked ? (disabled ? 'bg-gray-300' : checkedBgColor) : 'bg-gray-200',
                sizeMap[size].wrapper,
                className,
                'relative inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
                { 'cursor-not-allowed': disabled },
            )}
            disabled={disabled}
        >
            <span className="sr-only">{srTitle}</span>
            <span
                aria-hidden="true"
                className={cn(
                    checked ? sizeMap[size].translate : 'translate-x-0',
                    sizeMap[size].dot,
                    'pointer-events-none inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
            />
        </Switch>
    );
};

export default Toggle;
