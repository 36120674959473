import { useCallback, useContext } from 'react';

import { DraggableConfigurationInheritedValueAwareOverride } from '@/app/editor/engine/core/components/view/DraggableConfigurationInheritedValueAwareOverride';
import { EditorEngineNodeWrapper } from '@/app/editor/engine/core/components/view/EditorEngineNodeWrapper';

import type { useEditorEngineDragAndDropState } from '@/app/editor/engine/core/hooks/dragAndDrop/useEditorEngineDragAndDropState';
import type {
    EditorEngine,
    EditorEngineComponent,
    EditorEngineNode,
    EditorEngineDraggableConfiguration,
    EditorEngineCustomNodeWrapper,
    EditorEngineDefaultTypeInput,
} from '@/app/editor/engine/core/types';
import type { SetterFunction, ValueOrSetterFunction } from '@/app/editor/engine/core/types/util';
import type { DraggableConfiguration } from '@/app/editor/engine/core/utils/dragAndDrop/configuration/builder/DraggableConfiguration';
import type { Context, ReactNode } from 'react';

export interface EditorEngineNodeRendererProps<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> {
    /**
     * The context of this editor engine instance.
     */
    context: Context<EditorEngine<TEditorEngineTypeInput>>;
    /**
     * The nodes to render.
     */
    nodes: EditorEngineNode<TEditorEngineTypeInput['Data']>[];
    /**
     * This function will filter the nodes to render.
     *
     * If `undefined`, all nodes will be rendered.
     */
    filter?: (node: EditorEngineNode<TEditorEngineTypeInput['Data']>) => boolean;
    /**
     * The renderer function that will render the nodes.
     *
     * This function might layout nodes in a way specific to a certain use case,
     * but the `renderNode` function will be eventually used to show the nodes.
     *
     * An example of this function might be a function that places nodes in a
     * sequence, or a function that places nodes in a grid.
     *
     * @see {getSequenceRenderer}
     */
    renderer: (props: {
        /**
         * The component to use to render the node.
         */
        Component: EditorEngineComponent<
            TEditorEngineTypeInput['Document'],
            TEditorEngineTypeInput['Data']
        >;
        /**
         * A function that will identify a node.
         */
        identify: (node: EditorEngineNode<TEditorEngineTypeInput['Data']>) => string;
        /**
         * The document that is managed by the editor engine.
         */
        document: TEditorEngineTypeInput['Document'];
        /**
         * The nodes to render.
         */
        nodes: EditorEngineNode<TEditorEngineTypeInput['Data']>[];
        /**
         * The drag and drop state.
         */
        dragAndDrop: ReturnType<typeof useEditorEngineDragAndDropState<TEditorEngineTypeInput>>;
        /**
         * A function that will render a node.
         */
        renderNode: (props: {
            node: EditorEngineNode<TEditorEngineTypeInput['Data']>;
        }) => ReactNode;
    }) => ReactNode;
    /**
     * The draggable configuration to use.
     */
    draggableConfiguration?:
        | DraggableConfiguration<TEditorEngineTypeInput>
        | ValueOrSetterFunction<EditorEngineDraggableConfiguration<TEditorEngineTypeInput>>;
}

/**
 * The editor engine core will call this function to obtain the base node
 * renderer. All other renderers use either this renderer or a renderer that
 * wraps this renderer.
 */
export const getEditorEngineNodeRenderer = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>({
    CustomNodeWrapper,
}: {
    /**
     * A custom node wrapper to use when rendering a node.
     */
    CustomNodeWrapper?: EditorEngineCustomNodeWrapper<TEditorEngineTypeInput>;
}) => {
    /**
     * The renderer that will render the nodes.
     */
    const EditorEngineNodeRenderer = ({
        context,
        renderer,
        nodes,
        filter = () => true,
        draggableConfiguration,
    }: EditorEngineNodeRendererProps<TEditorEngineTypeInput>) => {
        const { documentManager, nodeManager, dragAndDrop, Component } = useContext(context);
        const filteredNodes = nodes.filter(filter);
        const NodeWrapper = CustomNodeWrapper || EditorEngineNodeWrapper<TEditorEngineTypeInput>;

        const renderNode = useCallback(
            ({ node }: { node: EditorEngineNode<TEditorEngineTypeInput['Data']> }) => {
                if (!node) {
                    return null;
                }

                return (
                    <NodeWrapper
                        key={nodeManager.identify(node)}
                        Component={Component}
                        node={node}
                        dragAndDropState={dragAndDrop}
                        documentManager={documentManager}
                        nodeManager={nodeManager}
                        useDraggableConfiguration={dragAndDrop.useDraggableConfiguration}
                        childIndex={filteredNodes.indexOf(node)}
                        Wrapper={EditorEngineNodeWrapper}
                    />
                );
            },
            [NodeWrapper, nodeManager, Component, dragAndDrop, documentManager, filteredNodes],
        );

        const identityConfiguration = useCallback(
            (oldValue) => oldValue,
            [],
        ) satisfies SetterFunction<EditorEngineDraggableConfiguration<TEditorEngineTypeInput>>;

        return (
            <DraggableConfigurationInheritedValueAwareOverride
                dragAndDropContext={dragAndDrop.context}
                draggableConfiguration={draggableConfiguration ?? identityConfiguration}
            >
                {renderer({
                    identify: nodeManager.identify,
                    Component,
                    document: documentManager.document,
                    nodes: filteredNodes,
                    dragAndDrop,
                    renderNode,
                })}
            </DraggableConfigurationInheritedValueAwareOverride>
        );
    };

    return EditorEngineNodeRenderer;
};
