import {
    arrow,
    offset,
    flip,
    shift,
    autoUpdate,
    useFloating,
    useInteractions,
    useRole,
    useDismiss,
    useHover,
    useId,
    useClick,
    safePolygon,
    FloatingFocusManager,
    FloatingPortal,
    FloatingArrow,
    size,
} from '@floating-ui/react';
import { animated, useTransition } from '@react-spring/web';
import { cloneElement, useEffect, useMemo, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';

import { cn } from '@/utils/cn';
import { animationDirectionMap, smoothConfig } from '@/utils/spring/configs';

import type {
    Placement,
    Side,
    Middleware,
    UseFloatingOptions,
    OpenChangeReason,
} from '@floating-ui/react';
import type { ReactNode } from 'react';

export interface Props {
    open?: boolean;
    openOnHover?: boolean;
    render: (data: { popoverId: string }) => ReactNode;
    onExit?: () => void;
    animateFrom?: Side | 'none';
    placement?: Placement;
    children: JSX.Element;
    mainAxisOffsetValue?: number;
    crossAxisOffsetValue?: number;
    fallbackPlacements?: Placement[];
    onOpenChange?: (open: boolean, reason?: OpenChangeReason) => void;
    isAnimated?: boolean;
    hasShift?: boolean;
    flipCrossAxis?: boolean;
    zIndexClass?: string;
    middleware?: Middleware[];
    showArrow?: boolean;
    elements?: UseFloatingOptions['elements'];
    sameWidthAsReference?: boolean;
    disabled?: boolean;
}

const APPEAR_ANIMATION_DURATION = 500;

export const Popover = ({
    children,
    open: openFromProps,
    openOnHover = false,
    onExit,
    render,
    animateFrom = 'top',
    placement,
    mainAxisOffsetValue = 5,
    crossAxisOffsetValue = 0,
    onOpenChange,
    fallbackPlacements,
    isAnimated = true,
    hasShift = true,
    flipCrossAxis = true,
    zIndexClass = 'z-50',
    middleware: extendedMiddleware = [],
    showArrow = false,
    elements,
    sameWidthAsReference = false,
    disabled = false,
}: Props) => {
    const [open, setOpen] = useState(false);
    const [animatePosition, setAnimatePosition] = useState(false);
    const arrowRef = useRef(null);

    const isOpen = openFromProps ?? open;

    useEffect(() => {
        if (isOpen && isAnimated) {
            setTimeout(() => {
                if (isOpen) {
                    setAnimatePosition(true);
                }
            }, APPEAR_ANIMATION_DURATION); // Wait until initial appear animation has finished
        } else {
            setAnimatePosition(false);
        }
    }, [isAnimated, isOpen]);

    const handleOpenChange = (open: boolean, _: Event, reason?: OpenChangeReason) => {
        setOpen(open);

        onOpenChange?.(open, reason);
    };

    const middleware = [
        offset({
            mainAxis: showArrow ? mainAxisOffsetValue + 5 : mainAxisOffsetValue,
            crossAxis: crossAxisOffsetValue,
        }),
        flip({
            crossAxis: flipCrossAxis,
            fallbackPlacements,
        }),
        arrow({
            element: arrowRef,
        }),
        ...(sameWidthAsReference
            ? [
                  size({
                      apply({ rects, elements }) {
                          Object.assign(elements.floating.style, {
                              width: `${rects.reference.width}px`,
                          });
                      },
                  }),
              ]
            : []),
        ...extendedMiddleware,
    ];

    if (hasShift) {
        middleware.push(shift());
    }

    const { x, y, strategy, context, refs } = useFloating({
        open: isOpen,
        onOpenChange: handleOpenChange,
        middleware,
        placement,
        whileElementsMounted: autoUpdate,
        elements,
    });

    const popoverId = useId();

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context, {
            enabled: !openOnHover && !disabled,
        }),
        useHover(context, {
            enabled: openOnHover,
            handleClose: safePolygon(),
        }),
        useRole(context),
        useDismiss(context),
    ]);

    // Preserve the consumer's ref
    const ref = useMemo(
        () => mergeRefs([refs.setReference, (children as any).ref]),
        [refs, children],
    );

    // React spring
    const transitions = useTransition(isOpen, {
        ...animationDirectionMap[animateFrom],
        config: smoothConfig,
        reverse: isOpen,
        onDestroyed: () => {
            onExit?.();
        },
    });

    return (
        <>
            {cloneElement(children, getReferenceProps({ ref, ...children.props }))}

            {transitions((style, visible) => {
                if (visible) {
                    return (
                        <FloatingPortal>
                            <FloatingFocusManager
                                context={context}
                                modal={false}
                                order={['reference', 'content']}
                                returnFocus={false}
                            >
                                <animated.div
                                    ref={refs.setFloating}
                                    style={{
                                        ...(isAnimated ? style : {}),
                                        position: strategy,
                                        top: y ?? 0,
                                        left: x ?? 0,
                                    }}
                                    className={cn('z-popover outline-none', zIndexClass, {
                                        'transition-all duration-300 ease-out':
                                            animatePosition && isAnimated,
                                    })}
                                    {...getFloatingProps()}
                                >
                                    {showArrow && (
                                        <FloatingArrow
                                            context={context}
                                            ref={arrowRef}
                                            className="-translate-y-px fill-white [&>path:first-of-type]:stroke-gray-200"
                                            tipRadius={1}
                                            strokeWidth={0.5}
                                            style={{ transform: 'translateY(-1px)' }}
                                        />
                                    )}
                                    {render({ popoverId })}
                                </animated.div>
                            </FloatingFocusManager>
                        </FloatingPortal>
                    );
                }
            })}
        </>
    );
};

export default Popover;
