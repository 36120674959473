import { useMemo } from 'react';

import { EditorEngineNodeDragStateType } from '@/app/editor/engine/core/types';
import { usePropsFromParent } from '@/app/editor/engine/hooks/usePropsFromParent';

import type {
    EditorEngineComponent,
    EditorEngineNodeData,
    EditorEngineNode,
    EditorEngineDocument,
    EditorEngineNodeDragInfo,
    EditorEngineDropPosition,
} from '@/app/editor/engine/core/types';
import type { useSortable } from '@dnd-kit/sortable';

type Props<
    TDocument extends EditorEngineDocument,
    TNodeData extends EditorEngineNodeData,
    TPreview extends boolean,
> = {
    /**
     * The unique identifier of the node.
     */
    id: string;
    /**
     * The component to use to render the node.
     */
    Component: EditorEngineComponent<TDocument, TNodeData>;
    /**
     * The document being managed by the editor engine.
     */
    document: TDocument;
    /**
     * The node to be rendered.
     */
    node: EditorEngineNode<TNodeData>;
    /**
     * The index of the node among its siblings.
     */
    childIndex: number;
    /**
     * Whether the view is a preview.
     *
     * todo(editorengine): should not be part of the core
     */
    isPreview: TPreview;
    /**
     * If this node is the target of a drag operation, this will contain
     * the information about the drag operation.
     */
    targetInformation?: {
        /**
         * The position of the drop.
         */
        position: EditorEngineDropPosition;
    };
    /**
     * Whether dragging is disabled for this node because of the draggable
     * configuration.
     */
    isDraggingDisabled?: boolean;
} & (TPreview extends false
    ? {
          dragContext: ReturnType<typeof useSortable>;
      }
    : {
          dragContext: undefined;
      });

/**
 * The view for a node in the editor.
 */
export const EditorEngineNodeView = <
    TDocument extends EditorEngineDocument,
    TNodeData extends EditorEngineNodeData,
    TPreview extends boolean,
>({
    id,
    Component,
    document,
    node,
    dragContext,
    childIndex,
    isPreview,
    targetInformation,
    isDraggingDisabled = false,
}: Props<TDocument, TNodeData, TPreview>) => {
    // todo(editorengine): should not be part of the core
    const propsFromParent = usePropsFromParent();

    const dragInfo = useMemo(() => {
        if (isPreview) {
            return {
                type: EditorEngineNodeDragStateType.Unavailable,
                isDragged: false,
                isDragOperationInProgress: false,
            } satisfies EditorEngineNodeDragInfo;
        }

        if (targetInformation) {
            return {
                type: EditorEngineNodeDragStateType.Target,
                isDragged: false,
                isDragOperationInProgress: true,
                position: targetInformation.position,
            } satisfies EditorEngineNodeDragInfo;
        }

        return {
            type: EditorEngineNodeDragStateType.Original,
            context: dragContext,
            isDragged: dragContext.active?.id === id,
            isDragOperationInProgress: Boolean(dragContext.active),
        } satisfies EditorEngineNodeDragInfo;
    }, [dragContext, id, isPreview, targetInformation]);

    return (
        <Component
            document={document}
            node={node}
            dragInfo={dragInfo}
            propsFromParent={propsFromParent}
            childIndex={childIndex}
            isDraggingDisabled={isDraggingDisabled}
        />
    );
};
