import { guid } from '@/app/editor/engine/utils/guid';

import type { EditorEngineParentId } from '@/app/editor/engine/core/types';
import type { getMakeBlockFromPreview } from '@/app/editor/engine/hooks/usePerspectiveDocumentManager/getMakeBlockFromPreview';
import type { PerspectiveEditorEngineNestedInsertNodeAtPayload } from '@/app/editor/engine/types';

export const getGetPreviewBlockPayloadsRecursively = ({
    getPreviewById,
    makeBlockFromPreview,
}: {
    getPreviewById: (id: string) => any;
    makeBlockFromPreview: ReturnType<typeof getMakeBlockFromPreview>;
}) => {
    const recursiveFunction = ({
        previewId,
        parentId,
        index,
    }: {
        previewId: string;
        parentId: EditorEngineParentId;
        index: number;
    }): PerspectiveEditorEngineNestedInsertNodeAtPayload => {
        const fakeId = guid();
        const previewBlock = getPreviewById(previewId);
        const preview = makeBlockFromPreview({ id: fakeId, previewId });
        const children = previewBlock?.relationships?.components?.data || [];
        const childBlocks = children.map((child, index) => {
            return recursiveFunction({
                previewId: child.id,
                parentId: fakeId,
                index,
            });
        });

        return {
            payload: {
                node: {
                    block: preview,
                },
                parentId,
                index,
            },
            children: childBlocks,
        };
    };

    return recursiveFunction;
};
