import { createSlice } from '@reduxjs/toolkit';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import Router from 'next/router';

import { getCampaignActiveVersion, getCampaignLatestVersion } from '@/app/campaigns/helpers';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import { fetchCampaign, getCampaignById } from './campaigns';
import { getBuildingCampaigns, removeFromBuildingCampaigns } from './publish';
import { fetchVersion, getCampaignVersionById } from './versions';
import { NAME, MAX_LAUNCH_EVENT_FETCH_RETRIES } from '../constants';

import type { LaunchEventResource } from '../types';
import type { ToastOptions } from '@/app/toasts/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    launchEvents: {
        [eventId: string]: LaunchEventResource;
    };
    launchEventEstimates: {
        [eventId: string]: number;
    };
    pendingLaunchEvents: string[];
    retries: number;
}

const initialState: State = {
    launchEvents: EMPTY_OBJECT,
    launchEventEstimates: EMPTY_OBJECT,
    pendingLaunchEvents: EMPTY_ARRAY,
    retries: 0,
};

export const launchEventsSlice = createSlice({
    name: `${NAME}/launchEvents`,
    initialState,
    reducers: {
        setLaunchEvent(
            state,
            action: PayloadAction<{ eventId: string; launchEvent: LaunchEventResource }>,
        ) {
            return {
                ...state,
                launchEvents: {
                    ...state.launchEvents,
                    [action.payload.eventId]: action.payload.launchEvent,
                },
            };
        },
        setLaunchEventEstimate(
            state,
            action: PayloadAction<{ eventId: string; estimation: number }>,
        ) {
            return {
                ...state,
                launchEventEstimates: {
                    ...state.launchEventEstimates,
                    [action.payload.eventId]: action.payload.estimation,
                },
            };
        },
        setPendingLaunchEvents(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                pendingLaunchEvents: action.payload,
            };
        },
        setRetries(state, action: PayloadAction<number>) {
            return {
                ...state,
                retries: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLaunchEvent, setLaunchEventEstimate, setPendingLaunchEvents, setRetries, reset } =
    launchEventsSlice.actions;

// === Selectors ======

export const getLaunchEventById = (state: AppState, eventId: string) =>
    get(state[NAME], `launchEventsReducer.launchEvents[${eventId}]`, undefined);

export const getPendingLaunchEvents = (state: AppState) =>
    state[NAME]?.launchEventsReducer?.pendingLaunchEvents;

export const getLaunchEventByCampaignId = (state: AppState, campaignId: string) => {
    const campaign = getCampaignById(state, campaignId);
    const campaignLatestVersion = getCampaignLatestVersion(campaign);
    const latestVersionId = campaignLatestVersion?.id;
    const latestVersion = getCampaignVersionById(latestVersionId)(state);

    if (latestVersion?.id) {
        const launchEventId = get(latestVersion, 'relationships.launchEvent.data.id');
        const launchEvent = getLaunchEventById(state, launchEventId);

        return launchEvent?.id ? launchEvent : ({} as LaunchEventResource);
    }

    return {} as LaunchEventResource;
};

export const getIsBuildingCampaign = (state: AppState, campaignId: string) => {
    const launchEvent = getLaunchEventByCampaignId(state, campaignId);
    const pendingLaunchEvents = getPendingLaunchEvents(state);
    const buildingCampaigns = getBuildingCampaigns(state);

    return buildingCampaigns?.includes(campaignId) || pendingLaunchEvents?.includes(launchEvent.id);
};

export const getRetries = (state: AppState) => state[NAME]?.launchEventsReducer?.retries;

// === Helper ======

const dataFetchPendingLaunchEvents = (): AppThunk<Promise<ResponseData>> => async () => {
    try {
        return await apiGet('/events/launch?status=pending&page=1');
    } catch (err) {
        handleRuntimeError(err, { debugMessage: 'fetching pending launch events failed:' });
    }
};

// === Thunks ======

export const fetchPendingLaunchEvents = (): AppThunk => {
    return async (dispatch) => {
        const response = await dispatch(dataFetchPendingLaunchEvents());
        const events = getDataFromResponse(response);

        if (events) {
            const eventIds = events.map((event) => event.id);

            dispatch(setPendingLaunchEvents(eventIds));
        }

        return response;
    };
};

export const addPendingLaunchEvent = (eventId: string): AppThunk => {
    return (dispatch, getState) => {
        const state = getState();
        const pendingEvents = getPendingLaunchEvents(state).slice();
        pendingEvents.push(eventId);

        dispatch(setPendingLaunchEvents(pendingEvents));
    };
};

export const removePendingLaunchEvent = (eventId: string): AppThunk => {
    return (dispatch, getState) => {
        const state = getState();
        const pendingEvents = getPendingLaunchEvents(state);
        const index = pendingEvents.indexOf(eventId);
        const updatedPendingEvents = pendingEvents.slice();

        if (index > -1) {
            updatedPendingEvents.splice(index, 1);
        }

        dispatch(setPendingLaunchEvents(updatedPendingEvents));
    };
};

export const updateLaunchEvents = (pendingEvents: LaunchEventResource[]): AppThunk => {
    return async (dispatch) => {
        if (pendingEvents?.length) {
            for (const pendingEvent of pendingEvents) {
                if (pendingEvent?.id) {
                    await dispatch(
                        setLaunchEvent({ eventId: pendingEvent.id, launchEvent: pendingEvent }),
                    );
                }
            }
        }
    };
};

export const fetchLaunchEvent = (eventId: string): AppThunk<Promise<LaunchEventResource>> => {
    return async (dispatch) => {
        if (!eventId) {
            return Promise.reject('No launch eventId provided');
        }

        try {
            const response = await apiGet(`/events/launch/${eventId}`);
            const data = getDataFromResponse(response);

            dispatch(setLaunchEvent({ eventId, launchEvent: data }));

            return data;
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: `error fetching launch event ${eventId}:`,
            });
        }
    };
};

export const fetchCampaignAndVersions = (campaignId: string): AppThunk<Promise<void>> => {
    return async (dispatch) => {
        const campaign = await dispatch(fetchCampaign(campaignId));

        const latestVersion = getCampaignLatestVersion(campaign);
        const activeVersion = getCampaignActiveVersion(campaign);
        const latestVersionId = latestVersion?.id;
        const activeVersionId = activeVersion?.id;

        if (latestVersionId) {
            const latestVersion = await dispatch(fetchVersion(latestVersionId));
            const launchEventId = get(latestVersion, 'relationships.launchEvent.data.id');

            if (launchEventId) {
                await dispatch(fetchLaunchEvent(launchEventId));
            }
        }

        if (activeVersionId) {
            await dispatch(fetchVersion(activeVersionId));
        }
    };
};

const handleCompletedEvent = (
    event: LaunchEventResource,
    errorToastData: ToastOptions,
): AppThunk => {
    return async (dispatch) => {
        const pathname = Router.pathname;
        const campaignId = event?.relationships?.campaign?.data?.id;

        await dispatch(removeFromBuildingCampaigns(campaignId));
        await dispatch(removePendingLaunchEvent(event?.id));
        await dispatch(fetchCampaignAndVersions(campaignId));

        // Re-fetch completed Event and check for errors
        const eventData = await dispatch(fetchLaunchEvent(event?.id));

        if (eventData?.attributes?.status === 'error' && pathname.indexOf('/publish') > -1) {
            showToast({
                type: 'warning',
                ...errorToastData,
            });
        }
    };
};

export const checkAndUpdateCompletedEvents = (
    pendingEvents: LaunchEventResource[],
    errorToastData: ToastOptions,
): AppThunk => {
    return (dispatch, getState) => {
        const state = getState();
        const storedPendingEventIds = getPendingLaunchEvents(state);

        // Check for each pending event if it's still pending; if not -> completed
        storedPendingEventIds.forEach((storedPendingEventId) => {
            if (findIndex(pendingEvents, { id: storedPendingEventId }) === -1) {
                const completedEvent = getLaunchEventById(state, storedPendingEventId);
                dispatch(handleCompletedEvent(completedEvent, errorToastData));
            }
        });
    };
};

export const refreshLaunchEvents = (errorToastData: ToastOptions): AppThunk => {
    return async (dispatch, getState) => {
        const state = getState();
        const retries = getRetries(state);

        if (retries < MAX_LAUNCH_EVENT_FETCH_RETRIES) {
            const pendingEventsResponse = await dispatch(dataFetchPendingLaunchEvents());
            const pendingEvents = getDataFromResponse(pendingEventsResponse);

            await dispatch(updateLaunchEvents(pendingEvents));
            await dispatch(checkAndUpdateCompletedEvents(pendingEvents, errorToastData));
            await dispatch(setRetries(retries + 1));
        }
    };
};

export const fetchLaunchEventEstimate = (eventId: string): AppThunk => {
    return async (dispatch) => {
        const response = await apiGet(`/events/launch/${eventId}/estimate`);

        const { estimation } = getDataFromResponse(response);

        dispatch(setLaunchEventEstimate({ eventId, estimation }));
    };
};

export default launchEventsSlice.reducer;
