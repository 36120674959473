import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import { change, Field, reduxForm, getFormValues } from 'redux-form';

import { useFrameSubmit } from '@/app/editor/blocks/hooks/useFrameSubmit';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getActiveIndexBySliderId } from '@/app/editor/blocks/models/slider';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import CropButton from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/CropButton';
import FrameToggle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/FrameToggle';
import GenericSize from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/GenericSize';
import Slides from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Slides';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import { getThemeColor } from '@/app/editor/themes/helpers';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import { setActiveFromBlockId, setSelectedSize, setValueMap } from '../../models/mediaSize';

import type { BlockResource, DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { Props as GenericSizeProps } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/GenericSize';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({
    form,
    blockId,
    handleSubmit,
    activeTheme,
    initialValues,
}) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const activeImageIndex = useAppSelector((state) => getActiveIndexBySliderId(state, blockId));
    const images = initialValues?.attributes?.content?.images;
    const formValues = useAppSelector((state) => getFormValues(form)(state) as BlockResource);
    const { isInColumn } = useLayoutInfo(blockId);

    const imageSliderValueMap: GenericSizeProps['valueMap'] = useMemo(() => {
        return {
            S: { value: 'small', tooltip: t('picture.size.tooltip.s') },
            M: { value: 'medium', tooltip: t('picture.size.tooltip.m') },
            L: { value: 'large', tooltip: t('picture.size.tooltip.l') },
            XL: { value: 'xLarge', tooltip: t('picture.size.tooltip.xl') },
        };
    }, [t]);

    useEffect(() => {
        dispatch(setActiveFromBlockId(form));
        dispatch(setValueMap(imageSliderValueMap));
        dispatch(setSelectedSize(formValues?.attributes?.content?.size));
    }, [dispatch, form, formValues?.attributes?.content?.size, imageSliderValueMap]);

    const handleCropSave = (src: string) => {
        dispatch(change(blockId, `attributes.content.images[${activeImageIndex}]`, src));

        setTimeout(handleSubmit);
    };

    const onFrameSubmit = useFrameSubmit(blockId, handleSubmit);

    return (
        <>
            <Field
                name="attributes.content.size"
                component={GenericSize}
                submit={handleSubmit}
                valueMap={imageSliderValueMap}
            />

            <div className="grid w-full grid-cols-3 gap-2">
                <CropButton
                    src={images[activeImageIndex]}
                    onSave={handleCropSave}
                    size={initialValues?.attributes?.content?.size}
                    withSizeSlider
                />

                {!isInColumn && (
                    <>
                        <Field
                            name="attributes.content.framed"
                            component={FrameToggle}
                            submit={onFrameSubmit}
                        />

                        <Field
                            name="attributes.content.box.backgroundColor"
                            component={Color}
                            expand="left"
                            submit={handleSubmit}
                            themeColor={getThemeColor(activeTheme, 'backgroundColor')}
                            tooltip={t('background-color')}
                        />
                    </>
                )}
            </div>

            <Subheading text={t('slides')} />

            <Field
                name="attributes.content.images"
                component={Slides}
                submit={handleSubmit}
                blockId={blockId}
            />
        </>
    );
};

Form.displayName = 'ImageSliderEditForm';

export default reduxForm({})(Form);
