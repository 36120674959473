import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Router from 'next/router';
import { i18n } from 'next-i18next';

import { getCampaignWorkspaceId, getEditorUrl } from '@/app/campaigns/helpers';
import { getCampaigns, setCampaigns } from '@/app/campaigns/models/campaigns';
import { hideModal } from '@/app/modals/models/modals';
import { showToast } from '@/app/toasts/utils/showToast';
import { fetchWorkspaceById } from '@/app/workspaces/models/workspaces';
import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import { NAME } from '../constants';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = {
    loading: false,
};

export const duplicateSlice = createSlice({
    name: `${NAME}/duplicate`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = duplicateSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.duplicateReducer?.loading;

// === Thunks ======

export const duplicateCampaign =
    (campaignId: string, newCampaignName: string = 'Copy'): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setLoading(true));

        const campaigns = getCampaigns(getState());

        try {
            const res = await apiPost(`/campaigns/${campaignId}/duplicate`, {
                data: { name: newCampaignName },
            });

            const campaign = getDataFromResponse(res);

            if (isEmpty(campaign)) {
                throw new Error('campaign does not exist');
            }
            const workspaceId = getCampaignWorkspaceId(campaign);

            dispatch(fetchWorkspaceById(workspaceId));
            dispatch(setCampaigns({ [campaign.id]: campaign, ...campaigns }));

            const getSuccessMessage = () => i18n?.t('campaigns:duplicate-success-message');
            const openFunnel = () => Router.push(getEditorUrl(campaign.id));

            showToast({
                type: 'success',
                message: getSuccessMessage(),
                actionText: i18n?.t('common:open-funnel'),
                onActionClick: openFunnel,
            });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'duplicating campaign failed:' });
        } finally {
            dispatch(setLoading(false));
            dispatch(hideModal());
        }
    };

export default duplicateSlice.reducer;
