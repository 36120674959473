import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { i18n } from 'next-i18next';

import { track } from '@/core/tracking';

import { ADD_NEW_PROPERTY_LIST, NAME, PROPERTY_DEFAULT_ICON_MAP } from '../constants';
import { PropertyView } from '../types';
import { FieldType, UniqueFieldName } from '../types';

import type { AddPropertyListOption, SchemaProperty } from '../types';

export const getIsMultilineText = (fieldType: FieldType, fieldName: string) => {
    if (fieldType !== FieldType.text) {
        return false;
    }

    const isSingleLineTextField =
        fieldType === FieldType.text &&
        ['address', UniqueFieldName.firstName, UniqueFieldName.lastName, UniqueFieldName.name].some(
            (field) => fieldName.startsWith(field),
        );

    if (isSingleLineTextField) {
        return false;
    }

    return true;
};

export const getPropertyVisibleFromView = (view: PropertyView) => (property: SchemaProperty) => {
    // @todo: Do not default to `property?.visible` for Kanban
    // Temp fix until backend migrates data to visibility
    if (view === PropertyView.kanban) {
        return !!property?.visibility?.[view];
    }

    // @todo: remove case property.visible, when backend migrates data to visibility
    return property?.visibility?.[view] ?? property?.visible;
};

export const getIsExemptedTableField = (fieldname: string) => {
    return [UniqueFieldName.firstName, UniqueFieldName.lastName, UniqueFieldName.name].includes(
        fieldname as UniqueFieldName,
    );
};

export const getAddPropertyListOptions = (
    handleAddProperty: (propertyType: FieldType) => () => void,
): AddPropertyListOption[] =>
    ADD_NEW_PROPERTY_LIST.map((property) => ({
        type: property,
        label: i18n?.t(`${NAME}:property-${property}-label`),
        icon: PROPERTY_DEFAULT_ICON_MAP[property],
        handleClick: handleAddProperty(property),
    }));

export const trackPropertyVisibilitySet = (
    view: PropertyView,
    fieldName: string,
    visible: boolean,
) => {
    if (view === PropertyView.kanban) {
        track(TRACKING_EVENTS.crm.kanban.property.visibility, {
            property_visible: visible,
            crm_property_name: fieldName,
        });
    }
};

export const trackPropertyReordered = (view: PropertyView, fieldName: string) => {
    if (view === PropertyView.kanban) {
        track(TRACKING_EVENTS.crm.kanban.property.order, {
            crm_property_name: fieldName,
        });
    }
};

export const getFilteredTableProperties = (properties: SchemaProperty[]) => {
    return properties.filter((property) => {
        return property.fieldType && !getIsExemptedTableField(property.fieldName);
    });
};

export const getVisibleProperties = (properties: SchemaProperty[], view: PropertyView) => {
    return properties.filter(getPropertyVisibleFromView(view));
};

export const getTableVisibleProperties = (properties: SchemaProperty[]) => {
    const filteredProperties = getFilteredTableProperties(properties);

    return getVisibleProperties(filteredProperties, PropertyView.table);
};

export const getNextVisiblePropertyIndex = (
    properties: SchemaProperty[],
    columnOrder: string[],
    columnToInsert: string,
) => {
    const propertyIndex = properties.findIndex((prop) => prop.fieldName === columnToInsert);

    const slicedProperties = properties.slice(propertyIndex);
    const nearestVisibleProperty = slicedProperties.find((property) =>
        getPropertyVisibleFromView(PropertyView.table)(property),
    );

    return nearestVisibleProperty
        ? columnOrder.findIndex((column) => column === nearestVisibleProperty.fieldName)
        : columnOrder.length;
};
