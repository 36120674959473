import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { PerspectiveEditorEnginePreviewAwareNodeRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareNodeRenderer';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';

import Box from '../_wrapper/Box';
import Text from '../Text/Component';

import type { Alignment, DefaultBlockComponentProps, Size } from '@/app/editor/blocks/types';
import type { RawDraftContentState } from 'draft-js';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    align: Alignment;
    size: Size;
    color: string;
    fieldName: string;
    wysiwyg: RawDraftContentState;
}

const Component: FC<Props> = ({
    artBoardIndex,
    box,
    blockId,
    align,
    color,
    wysiwyg,
    activeTheme,
    isDragged,
    isPreview,
}) => {
    const { isInColumn } = useLayoutInfo(blockId, isPreview);

    return (
        <Box
            box={getBoxAttributes({ box, isInColumn })}
            isDragged={isDragged}
            artBoardIndex={artBoardIndex}
        >
            <Text
                align={align}
                blockId={blockId}
                wysiwyg={wysiwyg}
                color={getThemedOrCustomColor(color, 'fontColor', activeTheme)}
            />
            <PerspectiveEditorEnginePreviewAwareNodeRenderer
                parentId={blockId}
                propsFromParent={{ align }}
                renderer={({ nodes, renderNode }) => {
                    return nodes.map((node) => {
                        return renderNode({ node });
                    });
                }}
            />
        </Box>
    );
};

Component.displayName = 'QuestionForm';

export default Component;
