import { useMemo } from 'react';

import { getIsCrmUser } from '@/app/user/helper';
import { getUser } from '@/app/user/models/user';
import { getIsPreview } from '@/app/workspaces/models/workspaces';
import { useAppSelector } from '@/core/redux/hooks';

export const enum CapabilitiesTarget {
    Campaign = 'campaign',
    Workspaces = 'workspaces',
}

interface Capabilities {
    read: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
}

const allCapabilities: Capabilities = {
    read: true,
    create: true,
    update: true,
    delete: true,
} as const;

const workspaceUserCapabilities: Capabilities = {
    read: true,
    create: false,
    update: false,
    delete: false,
} as const;

export const useUserCapabilities = (
    target: CapabilitiesTarget,
): { canCreate: boolean; canUpdate: boolean; canRead: boolean; canDelete: boolean } => {
    const user = useAppSelector(getUser);
    const isPreview = useAppSelector(getIsPreview);
    const isWorkspaceUser = getIsCrmUser(user);

    // Show Workspace User Role view of the App if `preview` is true
    const hasWorkspaceUserRole = isWorkspaceUser || isPreview;

    return useMemo(() => {
        const capabilities = {
            [CapabilitiesTarget.Campaign]: hasWorkspaceUserRole
                ? { ...workspaceUserCapabilities }
                : { ...allCapabilities },
            [CapabilitiesTarget.Workspaces]: hasWorkspaceUserRole
                ? { ...workspaceUserCapabilities }
                : { ...allCapabilities },
        };

        return {
            canCreate: !!capabilities[target]?.create,
            canUpdate: !!capabilities[target]?.update,
            canRead: !!capabilities[target]?.read,
            canDelete: !!capabilities[target]?.delete,
        };
    }, [hasWorkspaceUserRole, target]);
};
