import { createSlice } from '@reduxjs/toolkit';

import { dataFetchCampaigns } from '@/app/campaigns/models/campaigns';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiDelete, handleRuntimeError } from '@/core/api';

import { NAME } from '../constants';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = {
    loading: false,
};

export const deleteSlice = createSlice({
    name: `${NAME}/delete`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = deleteSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.deleteReducer?.loading;

// === Thunks ======

// Delete single campaign
export const deleteCampaign =
    (campaignId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setLoading(true));

        try {
            await apiDelete(`/campaigns/${campaignId}`);

            // Re-fetch campaign list
            await dispatch(dataFetchCampaigns());

            showToast({ type: 'success', message: `${NAME}:delete-success` });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'deleting campaign failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default deleteSlice.reducer;
