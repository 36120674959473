import dynamic from 'next/dynamic';

import { Modals } from './types';

// Modal components
const CompletionOverlay = dynamic(() => import('../pathToSuccess/components/CompletionOverlay'));
const LeadNotificationsModal = dynamic(
    () => import('../integrations/components/LeadNotificationsModal/LeadNotificationsModal'),
);
const LessonOverlay = dynamic(() => import('../pathToSuccess/components/LessonOverlay'));
const PageScriptModal = dynamic(
    () => import('@/app/editor/pages/components/modals/PageScriptModal'),
);
const PageSlugModal = dynamic(() => import('@/app/editor/pages/components/modals/PageSlugModal'));
const TemplateDetails = dynamic(() => import('../templateStore/components/TemplateDetails'));
const TrackingNameModal = dynamic(
    () => import('@/app/editor/blocks/components/Input/TrackingNameModal'),
);
const ChangeNameModal = dynamic(() => import('../settings/components/modals/ChangeNameModal'));
const ChangePasswordModal = dynamic(
    () => import('../settings/components/modals/ChangePasswordModal'),
);
const ChangeEmailModal = dynamic(() => import('../settings/components/modals/ChangeEmailModal'));
const DeleteAccountModal = dynamic(
    () => import('../settings/components/modals/DeleteAccountModal'),
);
const CopyPageToFunnelModal = dynamic(
    () => import('@/app/editor/pages/components/modals/CopyPageToFunnelModal'),
);
const LegalSettingsModal = dynamic(
    () => import('@/app/editor/blocks/components/Footer/modals/LegalSettings'),
);
const ImageLibraryModal = dynamic(() => import('@/app/imageLibrary/components/ImageLibraryModal'));
const ImageCroppingModal = dynamic(() => import('@/app/imageLibrary/components/CropModal'));
const SubscriptionConfirmModal = dynamic(() => import('@/app/billing/components/ConfirmModal'));
const CalendlyPreviewModal = dynamic(
    () => import('@/app/editor/blocks/components/QuestionForm/CalendlyPreviewModal'),
);
const CalendlyIntroModal = dynamic(
    () => import('@/app/editor/blocks/components/QuestionForm/CalendlyIntroModal'),
);
const EmbedIntroModal = dynamic(
    () => import('@/app/editor/blocks/components/Embed/EmbedIntroModal'),
);
const AddCustomVariableModal = dynamic(
    () =>
        import(
            '@/app/editor/blocks/components/Text/DraftEditor/Personalization/AddCustomVariableModal'
        ),
);
const EditFallbackModal = dynamic(
    () =>
        import('@/app/editor/blocks/components/Text/DraftEditor/Personalization/EditFallbackModal'),
);
const SplitTestingIntroModal = dynamic(
    () => import('@/app/editor/pages/components/modals/SplitTestingIntro'),
);
const DeletionAlertModal = dynamic(() => import('@/ui/components/DeletionAlertModal'));

const CampaignDuplicationModal = dynamic(
    () => import('../campaigns/components/modals/DuplicationModal'),
);
const ConfirmArchiveCampaignModal = dynamic(
    () => import('../campaigns/components/modals/ConfirmArchiveCampaignModal'),
);
const HowToEmbedFunnelModal = dynamic(() =>
    import('../campaigns/components/modals/HowToEmbedFunnelModal').then(
        (mod) => mod.HowToEmbedFunnelModal,
    ),
);
const DunningModal = dynamic(() => import('@/app/billing/components/DunningModal'));
const EndSplitTestModal = dynamic(
    () => import('@/app/analytics/components/Analytics/AnalyticsDashboard/EndSplitTestModal'),
);
const ExportSessionsModal = dynamic(
    () => import('@/app/analytics/components/Analytics/SessionsDashboard/ExportModal'),
);
const FunnelSettingsModal = dynamic(
    () => import('@/app/funnelSettings/funnelSettings/components/FunnelSettingsModal'),
);
const IntegrationModal = dynamic(() => import('@/app/integrations/components/IntegrationModal'));
const IntegrationProfileModal = dynamic(
    () => import('@/app/integrations/components/IntegrationProfileModal/IntegrationProfileModal'),
);
const WebhooksModal = dynamic(
    () => import('@/app/integrations/components/WebhooksModal/WebhooksModal'),
);
const InvoicesModal = dynamic(() => import('@/app/settings/components/Invoices/InvoicesModal'));
const FacebookVerificationModal = dynamic(
    () => import('@/app/domains/components/FacebookVerificationModal'),
);
const PasteEmbedSnippet = dynamic(
    () => import('@/app/editor/blocks/components/Embed/PasteSnippetModal'),
);
const GoalHelpModal = dynamic(() => import('@/app/surveys/components/GoalHelpModal'));
const DeleteContactsModal = dynamic(() => import('@/app/crm/components/DeleteContactsModal'));
const DeleteStatusPropertyModal = dynamic(
    () =>
        import('@/app/crm/components/Property/PropertyForm/StatusSelect/DeleteStatusPropertyModal'),
);
const ShareContactsModal = dynamic(
    () => import('@/app/crm/components/TopBar/ShareButton/ShareContactsModal'),
);
const ApplyThemeModal = dynamic(() => import('@/app/editor/themes/components/ApplyThemeModal'));

const WorkspaceSetUpExitModal = dynamic(
    () => import('@/app/workspaces/components/WorkspaceSetup/components/ExitModal'),
);
const WorkspaceSettingsModal = dynamic(() => import('@/app/workspaces/components/SettingsModal'));
const CustomFontsModal = dynamic(() => import('@/app/editor/themes/components/CustomFontsModal'));
const NewSenderModal = dynamic(() => import('@/app/editor/messages/components/NewSenderModal'));
const MakeIntegrationModal = dynamic(
    () => import('@/app/integrations/components/MakeIntegration/MakeIntegrationModal'),
);
const DomainSetupTutorialModal = dynamic(
    () => import('@/app/domains/components/DomainSetupTutorialModal'),
);
const DeleteFileConfirmationModal = dynamic(
    () => import('../crm/components/Property/PropertyForm/FileInfo/DeleteFileConfirmationModal'),
);

const StatusTemplatesModal = dynamic(
    () => import('@/app/crm/components/StatusTemplates/StatusTemplatesModal'),
);

const AddNewContactModal = dynamic(
    () => import('@/app/crm/components/AddNewContact/AddContactModal'),
);

const CopyMessageToFunnelModal = dynamic(
    () =>
        import(
            '@/app/editor/messages/components/CopyMessageToFunnelModal/CopyMessageToFunnelModal'
        ),
);

const StatusReplacementModal = dynamic(
    () =>
        import(
            '@/app/crm/components/KanbanView/KanbanBoard/ContactColumn/ColumnSettings/StatusReplacementModal'
        ),
);

const WatchVideoModal = dynamic(() =>
    import('@/app/referrals/components/Sharing/WatchVideoModal').then((mod) => mod.WatchVideoModal),
);

export const modalMap = {
    // Common
    [Modals.DELETION_ALERT]: DeletionAlertModal,

    // Campaigns
    [Modals.DUPLICATE_CAMPAIGN]: CampaignDuplicationModal,
    [Modals.CONFIRM_ARCHIVE_CAMPAIGN]: ConfirmArchiveCampaignModal,
    [Modals.HOW_TO_EMBED_FUNNEL_MODAL]: HowToEmbedFunnelModal,

    // Domains
    [Modals.FACEBOOK_VERIFICATION]: FacebookVerificationModal,
    [Modals.DOMAIN_SETUP_TUTORIAL]: DomainSetupTutorialModal,

    // Dunning
    [Modals.DUNNING]: DunningModal,

    // Template Store
    [Modals.TEMPLATE_DETAILS]: TemplateDetails,

    // Path To Success
    [Modals.P2S_COURSE_LESSON]: LessonOverlay,
    [Modals.P2S_COURSE_COMPLETED]: CompletionOverlay,

    // Subscription
    [Modals.SUBSCRIPTION_CONFIRM]: SubscriptionConfirmModal,

    // Editor
    [Modals.INPUT_TRACKING_NAME]: TrackingNameModal,
    [Modals.EDIT_PAGE_SLUG]: PageSlugModal,
    [Modals.EDIT_PAGE_SCRIPT]: PageScriptModal,
    [Modals.COPY_PAGE_TO_FUNNEL]: CopyPageToFunnelModal,
    [Modals.LEGAL_SETTINGS]: LegalSettingsModal,
    [Modals.CALENDLY_PREVIEW]: CalendlyPreviewModal,
    [Modals.CALENDLY_INTRO]: CalendlyIntroModal,
    [Modals.EMBED_INTRO]: EmbedIntroModal,
    [Modals.ADD_CUSTOM_VARIABLE]: AddCustomVariableModal,
    [Modals.EDIT_VARIABLE_FALLBACK]: EditFallbackModal,
    [Modals.SPLIT_TESTING_INTRO]: SplitTestingIntroModal,
    [Modals.PASTE_EMBED_SNIPPET]: PasteEmbedSnippet,
    [Modals.APPLY_THEME_MODAL]: ApplyThemeModal,
    [Modals.CUSTOM_FONTS_MODAL]: CustomFontsModal,
    [Modals.NEW_SENDER_MODAL]: NewSenderModal,
    [Modals.COPY_MESSAGE_TO_FUNNEL]: CopyMessageToFunnelModal,

    // Image library
    [Modals.IMAGE_LIBRARY]: ImageLibraryModal,
    [Modals.IMAGE_CROPPING]: ImageCroppingModal,
    [Modals.MAKE_INTEGRATION]: MakeIntegrationModal,

    // Integrations
    [Modals.INTEGRATION]: IntegrationModal,
    [Modals.INTEGRATION_PROFILE]: IntegrationProfileModal,
    [Modals.WEBHOOKS]: WebhooksModal,
    [Modals.LEAD_NOTIFICATIONS]: LeadNotificationsModal,

    // Settings
    [Modals.CHANGE_NAME]: ChangeNameModal,
    [Modals.CHANGE_PASSWORD]: ChangePasswordModal,
    [Modals.CHANGE_EMAIL]: ChangeEmailModal,
    [Modals.DELETE_ACCOUNT]: DeleteAccountModal,
    [Modals.INVOICES]: InvoicesModal,

    // Analytics
    [Modals.END_SPLIT_TEST]: EndSplitTestModal,
    [Modals.EXPORT_SESSIONS]: ExportSessionsModal,

    // Funnel settings
    [Modals.FUNNEL_SETTINGS]: FunnelSettingsModal,

    // Onboarding goal
    [Modals.GOAL_HELP]: GoalHelpModal,

    // CRM
    [Modals.DELETE_CRM_CONTACTS]: DeleteContactsModal,
    [Modals.DELETE_STATUS_PROPERTY]: DeleteStatusPropertyModal,
    [Modals.STATUS_TEMPLATES]: StatusTemplatesModal,
    [Modals.ADD_CONTACT]: AddNewContactModal,
    [Modals.SHARE_CONTACTS_MODAL]: ShareContactsModal,
    [Modals.CONFIRM_FILE_DELETION]: DeleteFileConfirmationModal,
    [Modals.STATUS_REPLACEMENT]: StatusReplacementModal,

    // Workspace
    [Modals.WORKSPACE_SETUP_EXIT]: WorkspaceSetUpExitModal,
    [Modals.WORKSPACE_SETTINGS]: WorkspaceSettingsModal,

    // Referral Dashboard
    [Modals.REFERRAL_WATCH_VIDEO]: WatchVideoModal,
};

export default modalMap;
