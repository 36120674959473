import { PhoneValidationKind } from '@/app/editor/types';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import ToggleButton from '@/ui/components/_BlockEditFields/ToggleButton';

import type { BorderRadius } from '@/app/editor/themes/types';
import type { ToggleIcon } from '@/ui/components/_BlockEditFields/ToggleButton';
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

export interface Props {
    icon: ToggleIcon;
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    toggleType?: 'inputType' | 'borderRadius' | 'darkMode' | 'phoneValidationKind' | 'buttonAlign';
    submit: () => void;
    tooltip?: string;
    disabled?: boolean;
    monochrome?: boolean;
    inverted?: boolean;
}

const IconToggleButton = ({
    input,
    submit,
    tooltip,
    icon,
    toggleType,
    disabled,
    monochrome,
    inverted,
}: Props) => {
    const { onChange, value } = input;

    const handleChange = (checked: boolean) => {
        let formValue: string | boolean;

        switch (toggleType) {
            case 'inputType':
                formValue = checked ? 'number' : 'text';
                break;
            case 'borderRadius':
                formValue = (checked ? 'large' : 'small') satisfies BorderRadius;
                break;
            case 'darkMode':
                formValue = checked ? 'dark' : 'light';
                break;
            case 'phoneValidationKind':
                formValue = checked
                    ? PhoneValidationKind.CountryFormat
                    : PhoneValidationKind.Numeric;
                break;
            case 'buttonAlign':
                formValue = checked ? 'left' : 'center';
                break;
            default:
                formValue = checked;
        }

        onChange(formValue);

        if (submit) {
            setTimeout(submit);
        }
    };

    let checked: boolean;

    switch (toggleType) {
        case 'inputType':
            checked = value === 'number';
            break;
        case 'borderRadius':
            checked = (value as BorderRadius) === 'large';
            break;
        case 'darkMode':
            checked = value === 'dark';
            break;
        case 'phoneValidationKind':
            checked = value === PhoneValidationKind.CountryFormat;
            break;
        case 'buttonAlign':
            checked = value === 'left';
            break;
        default:
            checked = value;
    }

    return (
        <FieldContainer>
            <ToggleButton
                icon={icon}
                checked={checked}
                onToggle={handleChange}
                tooltip={tooltip}
                disabled={disabled}
                monochrome={monochrome}
                inverted={inverted}
            />
        </FieldContainer>
    );
};

export default IconToggleButton;
