import { getPreviewOrActiveTheme } from '@/app/editor/themes/models/themes';
import { useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';

import Transition from './Transition';

import type { BoxProps } from '../../types';
import type { ReactNode } from 'react';

export interface Props {
    box: BoxProps;
    isDragged?: boolean;
    noBackground?: boolean;
    children: ReactNode;
    artBoardIndex?: number;
    limiterClass?: string;
}

const sizeMap = {
    none: '0',
    small: '8px',
    medium: '16px',
    large: '24px',
    xLarge: '32px',
};

export const defaultBox: BoxProps = {
    top: 'none',
    right: 'none',
    bottom: 'none',
    left: 'none',
    backgroundColor: 'transparent',
};

const Box = ({
    artBoardIndex,
    box,
    children,
    isDragged,
    noBackground,
    limiterClass = 'max-w-4xl',
}: Props) => {
    const boxData = box ? box : defaultBox;
    const { top, right, bottom, left, backgroundColor } = boxData;
    const previewOrActiveTheme = useAppSelector(getPreviewOrActiveTheme);

    // Prevents transparent block when being dragged (looks weird)
    const draggingBgColor =
        backgroundColor === 'transparent' || !backgroundColor
            ? previewOrActiveTheme?.attributes?.backgroundColor
            : backgroundColor;

    return (
        <div
            className="transition-all"
            style={{
                paddingTop: sizeMap[top],
                paddingRight: sizeMap[right],
                paddingBottom: sizeMap[bottom],
                paddingLeft: sizeMap[left],
                background: noBackground
                    ? undefined
                    : cn('no-repeat', isDragged ? draggingBgColor : backgroundColor),
            }}
        >
            <div className={cn('mx-auto w-full', limiterClass)}>
                <Transition artBoardIndex={artBoardIndex}>{children}</Transition>
            </div>
        </div>
    );
};

export default Box;
