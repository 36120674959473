import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';

import { openHelpArticle } from '@/app/campaigns/models/publish';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import { MAX_LAUNCH_EVENT_FETCH_RETRIES, NAME, SHORT_LAUNCH_EVENT_INTERVAL } from '../../constants';
import { getPendingLaunchEvents, getRetries, refreshLaunchEvents } from '../../models/launchEvents';

import type { ToastOptions } from '@/app/toasts/types';

const LaunchEventWatcher = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(NAME);

    const refreshInterval = useRef<NodeJS.Timeout>();

    const retries = useAppSelector(getRetries);
    const pendingLaunchEvents = useAppSelector(getPendingLaunchEvents);

    // Start interval if there are pending launch events, and we don't already have an interval
    useEffect(() => {
        if (pendingLaunchEvents?.length > 0 && !refreshInterval.current) {
            refreshInterval.current = setInterval(() => {
                dispatch(
                    refreshLaunchEvents({
                        message: t('publish failed toast content'),
                        actionText: t('publish failed toast action'),
                        onActionClick: openHelpArticle,
                    } as ToastOptions),
                );
            }, SHORT_LAUNCH_EVENT_INTERVAL);
        }

        // If there are no pending launch events or the retry limit has been reached -> kill the interval
        if (!pendingLaunchEvents?.length || retries >= MAX_LAUNCH_EVENT_FETCH_RETRIES) {
            clearInterval(refreshInterval.current);
            refreshInterval.current = null;
        }
    }, [dispatch, pendingLaunchEvents?.length, retries, t]);

    // Kill interval on unmount
    useEffect(() => {
        return () => {
            clearInterval(refreshInterval.current);
            refreshInterval.current = null;
        };
    }, []);

    return null;
};

export default LaunchEventWatcher;
