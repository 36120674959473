import { PerspectiveEditorEnginePreviewAwareNodeRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareNodeRenderer';
import { PropsFromParentOverrideProvider } from '@/app/editor/engine/core/components/view/PropsFromParentOverrideProvider';
import { DraggableConfigurationHelper } from '@/app/editor/engine/core/utils/dragAndDrop/configuration';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { cn } from '@/utils/cn';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {}

const Component: FC<Props> = ({ additionalBlocks, blockId }) => {
    const mediaAnswerMediaBorderRadius = useBorderRadius('topWrapper');
    const mediaAnswerTextBorderRadius = useBorderRadius('bottomWrapper');

    return (
        <div className="flex h-full flex-col">
            <PerspectiveEditorEnginePreviewAwareNodeRenderer
                draggableConfiguration={DraggableConfigurationHelper.alwaysDisable}
                parentId={blockId}
                renderer={({ nodes, renderNode }) => {
                    return (
                        <div>
                            <PropsFromParentOverrideProvider
                                propsFromParent={{
                                    wrapperClass: cn(
                                        'overflow-hidden rounded-b-none',
                                        mediaAnswerMediaBorderRadius,
                                    ),
                                    themedBackgroundColor: 'formFieldBackgroundColor',
                                    formData: additionalBlocks?.[nodes[0]?.block.id],
                                }}
                            >
                                {renderNode({
                                    node: nodes[0],
                                })}
                            </PropsFromParentOverrideProvider>

                            <PropsFromParentOverrideProvider
                                propsFromParent={{
                                    wrapperClass: cn(
                                        'h-full overflow-hidden rounded-t-none *:h-full',
                                        mediaAnswerTextBorderRadius,
                                    ),
                                    blockContainerClass: 'flex-grow',
                                    blockWrapperClass: 'h-full',
                                    themedBackgroundColor: 'formFieldBackgroundColor',
                                    isLightnessDependent: true,
                                }}
                            >
                                {renderNode({
                                    node: nodes[1],
                                })}
                            </PropsFromParentOverrideProvider>
                        </div>
                    );
                }}
            />
        </div>
    );
};

Component.displayName = 'QuestionMediaAnswer';

export default Component;
