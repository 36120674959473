import get from 'lodash/get';
import { forwardRef, memo } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { getFormValues } from 'redux-form';

import { getBlockComponent, getBlockConfig, getPreviewIdToShow } from '@/app/editor/blocks/helpers';
import {
    getActiveBlockId,
    getActiveBlockParent,
    getBlockById,
    getBlockParent,
    getHasActiveChild,
    getHasActiveDirectChild,
    setActiveBlock,
} from '@/app/editor/blocks/models/blocks';
import {
    getActiveSectionPreviewId,
    getInsertingInBlankColumnId,
    getPreviewBlockId,
} from '@/app/editor/sections/models/insert';
import { getPreviewOrActiveTheme } from '@/app/editor/themes/models/themes';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { EMPTY_ARRAY } from '@/utils/empty';

import BlockView from './Block.view';
import { getIsBlockSelectable, getIsBorderMenuVisible } from '../../../helper';

import Block from './index';

import type { EditFormValues, PropsFromParent } from '../../../../blocks/types';
import type { RelationshipObject } from '@/core/api/types';
import type { DraggableAttributes } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import type { MouseEvent } from 'react';

export interface Props {
    blockId: string;
    nestedLevel?: number;
    artBoardIndex?: number;
    // DNDKit
    isDragged?: boolean;
    isDragPreview?: boolean;
    dragStyle?: any;
    dragListeners?: SyntheticListenerMap;
    dragAttributes?: DraggableAttributes;
    // can be passed in `Component.tsx` of parent block to
    // its child (this block), e.g. MediaQuestion
    propsFromParent?: PropsFromParent;
}

const BlockController = forwardRef<HTMLDivElement, Props>(
    (
        {
            blockId,
            nestedLevel,
            artBoardIndex,
            isDragged,
            isDragPreview,
            dragStyle,
            dragAttributes,
            dragListeners,
            propsFromParent,
        },
        ref,
    ) => {
        // Redux
        const dispatch = useAppDispatch();
        const block = useAppSelector((state) => getBlockById(state, blockId));
        const formData = useAppSelector((state) => getFormValues(blockId)(state) as EditFormValues);
        const activeBlockId = useAppSelector(getActiveBlockId);
        const previewOrActiveTheme = useAppSelector(getPreviewOrActiveTheme);
        const sectionPreviewId = useAppSelector(getActiveSectionPreviewId);
        const insertingInBlankColumnId = useAppSelector(getInsertingInBlankColumnId);
        const hasActiveDirectChild = useAppSelector((state) =>
            getHasActiveDirectChild(state, blockId),
        );
        const hasActiveChild = useAppSelector((state) => getHasActiveChild(state, blockId));
        const activeParent = useAppSelector(getActiveBlockParent);
        const previewBlockId = useAppSelector(getPreviewBlockId);
        const parent = useAppSelector((state) => getBlockParent(state, blockId));

        // Local state for hover and block data
        const [hovered, setHovered] = useState(false);
        const [blockData, setBlockData] = useState(block);

        const isActiveBlock = blockId === activeBlockId;

        // Populate block data
        useEffect(() => {
            const data = isActiveBlock && formData ? formData : block;

            setBlockData(data);
        }, [isActiveBlock, formData, block]);

        // Get Block Config and Component to render
        const blockComponentType = block?.attributes?.componentType;
        const BlockComponent = useMemo(
            () => getBlockComponent(blockComponentType),
            [blockComponentType],
        );
        const blockConfig = useMemo(() => getBlockConfig(blockComponentType), [blockComponentType]);

        // show Menu according to block config and position
        const hasBorderMenu = getIsBorderMenuVisible({
            config: blockConfig,
            isDragPreview,
            parentComponentType: activeParent?.attributes?.componentType,
        });

        // Check if block is selectable
        const isSelectable = getIsBlockSelectable({
            config: blockConfig,
            parentComponentType: parent?.attributes?.componentType,
        });

        // Determine if a section preview is active/should be shown below the block
        const previewIdToShow = useMemo(
            () =>
                getPreviewIdToShow({
                    blockId,
                    previewBlockId,
                    sectionPreviewId,
                }),
            [blockId, previewBlockId, sectionPreviewId],
        );

        // Prevent crash if data is missing
        if (typeof blockData === 'undefined' || !blockData?.id) {
            return null;
        }

        // Click a block and start editing
        const handleClick = (event: MouseEvent) => {
            isSelectable && event.stopPropagation();

            // Check for active text selection
            // fixes this issue: https://linear.app/perspective/issue/PER-1886/selecting-text-and-release-on-other-block)
            const selection = window?.getSelection();

            // only handle click when there is no active text selection
            if (selection?.toString().length === 0 && isSelectable) {
                const clickCount = event.detail;

                if (clickCount === 1) {
                    dispatch(setActiveBlock(blockId));
                }

                // Double-click on top level block -> run double click from block config
                if (clickCount === 2 && blockConfig?.actions?.onDoubleClick) {
                    dispatch(blockConfig.actions.onDoubleClick(blockData, activeParent));
                }
            }
        };

        // Hover states
        const handleHover = (event: MouseEvent) => {
            if (isSelectable) {
                event.stopPropagation();
                setHovered(true);
            }
        };

        const handleLeave = (event: MouseEvent) => {
            if (isSelectable) {
                event.stopPropagation();
            }

            setHovered(false);
        };

        const sharedBlockProps = {
            ref,
            blockId,
            block: blockData,
            component: BlockComponent,
            nestedLevel,
            hasBorderMenu,
            artBoardIndex,
            isActive: isActiveBlock,
            isHovered: hovered,
            onClick: handleClick,
            onHover: handleHover,
            onLeave: handleLeave,
            isDragged,
            isDragPreview,
            dragStyle,
            dragAttributes,
            dragListeners,
            propsFromParent,
            activeTheme: previewOrActiveTheme,
            insertingInBlankColumnId,
            sectionPreviewId: previewIdToShow,
            additionalBlocks: formData?.additionalBlocks,
            isHighlighted: false,
        };

        if (typeof BlockComponent !== 'undefined') {
            // Get children of Block
            const childComponents: RelationshipObject[] = get(
                block,
                'relationships.components.data',
                EMPTY_ARRAY,
            );

            // Has children: Render Block and with recursive children (Block itself must render `children`)
            if (childComponents.length) {
                return (
                    <BlockView
                        {...sharedBlockProps}
                        isHighlighted={hasActiveDirectChild && blockConfig.highlightWithActiveChild}
                        hasActiveChild={hasActiveChild}
                    >
                        {childComponents.map((child) => {
                            // Recursion
                            return (
                                <Block
                                    key={child.id}
                                    blockId={child.id}
                                    nestedLevel={nestedLevel + 1}
                                    propsFromParent={propsFromParent}
                                />
                            );
                        })}
                    </BlockView>
                );
            }

            // No children: Just render the Block
            return <BlockView {...sharedBlockProps} />;
        }

        return null;
    },
);

BlockController.displayName = 'BlockController'; // required because of forwardRef

export default memo(BlockController);
