import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/react';
import { useTransition } from '@react-spring/web';

import { smoothConfig } from '@/utils/spring/configs';

export const useFloatingDropdown = (open: boolean) => {
    const { x, y, strategy, context, refs } = useFloating({
        open,
        middleware: [
            offset(4),
            flip({
                fallbackPlacements: ['top-start', 'top-end'],
                padding: 10,
            }),
            size({
                apply({ rects, elements, availableHeight }) {
                    Object.assign(elements.floating.style, {
                        maxHeight: `${availableHeight}px`,
                        minWidth: `${rects.reference.width}px`,
                    });
                },
            }),
        ],
        placement: 'bottom-start',
        whileElementsMounted: autoUpdate,
    });

    // React spring
    const transitions = useTransition(open, {
        from: { opacity: 0, y: -4 },
        enter: { opacity: 1, y: 0 },
        leave: { opacity: 0, y: -4 },
        config: smoothConfig,
        reverse: open,
    });

    return { x, y, strategy, context, refs, transitions };
};
