import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';

import { useAuthGuard } from '@/app/auth/hooks/useAuthGuard';
import { isDecember } from '@/utils/dates';
import { getIsWorkspaceEnv } from '@/utils/environments';

import type { ReactNode } from 'react';

export interface Props {
    children?: ReactNode;
}

export const Opener = ({ children }: Props) => {
    const isAuthenticated = useAuthGuard();

    const isWorkspaceEnv = getIsWorkspaceEnv({ genericHost: true });
    const perspectiveLogo = isDecember() ? '/logo_xmas.png' : '/logo_128x128.png';

    const logoSrc = isWorkspaceEnv ? '/workspace-icon.svg' : perspectiveLogo;
    const logoAlt = isWorkspaceEnv ? 'workspace logo' : 'perspective logo';

    return (
        <AnimatePresence mode="popLayout">
            {isAuthenticated ? (
                <motion.div
                    key="authenticated"
                    className="h-[inherit]"
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                    transition={{ ease: 'easeOut' }}
                >
                    {children}
                </motion.div>
            ) : (
                <motion.div
                    key="unauthenticated"
                    className="fixed inset-0 flex h-dvh w-dvw items-center justify-center"
                    aria-hidden
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 1.5 }}
                    transition={{ ease: 'easeOut' }}
                >
                    <div className="animate-pulse text-[0]">
                        <Image src={logoSrc} alt={logoAlt} width={48} height={48} priority />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
