import type { AxiosError, AxiosProgressEvent, InternalAxiosRequestConfig } from 'axios';

export interface ResponseData<T = any, E = any, M = any> {
    data: T;
    error?: E;
    meta?: M;
}

export interface Resource<Attributes, Relationship> {
    id: string;
    type: string;
    attributes: Attributes;
    relationships?: Relationship;
}

export interface RelationshipObject<T extends string = string> {
    id: string;
    type: T;
}

export interface Relationship {
    data: RelationshipObject | null;
}

export interface RelationshipArray {
    data: RelationshipObject[];
}

export interface Pagination {
    count: number;
    pageCount: number;
    totalCount: number;
    next?: string | null;
    previous?: string | null;
    first?: string;
    last?: string;
    self?: string;
}

export type UploadProgressEventHandler = (progressEvent: AxiosProgressEvent) => void;

export const enum StatusCodes {
    OK = 200,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    PAYMENT_REQUIRED = 402,
    UPGRADE_REQUIRED = 426,
    INTERNAL_SERVER_ERROR = 500,
}

export type RetryConfig<T> = InternalAxiosRequestConfig<T> & {
    _retryCount: number;
};

export interface CheckEmailResponse {
    valid: boolean;
    alreadyInCompany: boolean;
    message: string;
}

export type ApiError = AxiosError<{ message: string }>;

type RequestState = 'idle' | 'progress' | 'success' | 'error' | 'done';
export type RequestApiState<T extends string> = Record<T, RequestState>;
