import { animated, useTransition } from '@react-spring/web';
import { useEffect, useState } from 'react';

import { cn } from '@/utils/cn';
import { smoothConfig } from '@/utils/spring/configs';

import LoadingSpinner from '../LoadingSpinner';

import type { ReactNode } from 'react';

export interface Props {
    loading: boolean;
    children: ReactNode;
    narrow?: boolean;
    size?: 'tiny' | 'small' | 'medium';
}

export const Loader = ({ loading, narrow, children, size }: Props) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(!loading);
    }, [loading]);

    const transitions = useTransition(visible, {
        from: { opacity: 0, y: 4 },
        enter: { opacity: 1, y: 0 },
        leave: { opacity: 0, y: 4 },
        config: smoothConfig,
    });

    return (
        <div className="relative w-full">
            {loading && (
                <div
                    className={cn(
                        'flex w-full items-center justify-center',
                        narrow ? 'h-auto' : 'h-64',
                    )}
                >
                    <LoadingSpinner size={size} />
                </div>
            )}
            {transitions((style, item) => {
                if (item) {
                    return <animated.div style={style}>{children}</animated.div>;
                }
            })}
        </div>
    );
};

export default Loader;
