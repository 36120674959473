import { PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS } from '@/app/crm/constants';

import Tooltip from '@/ui/components/Tooltip';

import type { MouseEvent, ReactNode, SVGProps } from 'react';

export interface Props {
    title?: string;
    href?: string;
    onClick?: (evt: MouseEvent<HTMLAnchorElement>) => void;
    icon?: (props: SVGProps<SVGSVGElement>) => ReactNode;
    openInNewTab?: boolean;
}

const getUrl = (url?: string) => {
    if (!url) {
        return;
    }

    const hasProtocolPrefix = /^(.+:)/.test(url);

    if (hasProtocolPrefix) {
        return url;
    }

    return `//${url}`;
};

export const TextInputIconButton = ({
    title,
    href,
    onClick,
    icon: Icon,
    openInNewTab = false,
}: Props) => {
    return (
        <Tooltip content={title} delay={0}>
            <a
                target={openInNewTab ? '_blank' : undefined}
                role="button"
                href={getUrl(href)}
                onClick={onClick}
                className={PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS}
                rel="noopener noreferrer"
            >
                <Icon className="size-4 text-gray-500 hover:text-gray-900" />
            </a>
        </Tooltip>
    );
};
