import { useCallback, useMemo, useState } from 'react';

import { useProcessingQueue } from '@/app/editor/engine/core/hooks/queue/useProcessingQueue';
import {
    type EditorEngineActionUpdater,
    type EditorEngineDefaultTypeInput,
    type EditorEngineEventEmitter,
    EditorEngineHistoryEntryExecutionAvailability,
    type EditorEnginePersistedUpdate,
} from '@/app/editor/engine/core/types';
import { canRedo } from '@/app/editor/engine/core/utils/history/canRedo';
import { canUndo } from '@/app/editor/engine/core/utils/history/canUndo';
import { getCurrentEntry } from '@/app/editor/engine/core/utils/history/getCurrentEntry';
import { getEntryExecutionAvailability } from '@/app/editor/engine/core/utils/history/getEntryExecutionAvailability';
import { getHistoryEntryPositioning } from '@/app/editor/engine/core/utils/history/getHistoryEntryPositioning';
import { getProcessActionFunction } from '@/app/editor/engine/core/utils/history/getProcessActionFunction';
import { getRedoFunction } from '@/app/editor/engine/core/utils/history/getRedoFunction';
import { getUndoFunction } from '@/app/editor/engine/core/utils/history/getUndoFunction';
import { indexOfEntry } from '@/app/editor/engine/core/utils/history/indexOfEntry';
import { processPersistedUpdateQueue } from '@/app/editor/engine/core/utils/history/processPersistedUpdateQueue';
import { updateEntryActionRecordedResult } from '@/app/editor/engine/core/utils/history/updateEntryActionRecordedResult';
import { guid } from '@/app/editor/engine/utils/guid';

import type {
    EditorEngineHistoryEntryCollection,
    EditorEngineHistoryDefaultEntry,
    EditorEngineActionInstance,
} from '@/app/editor/engine/core/types';

/**
 * Hook that provides history management for the editor engine.
 *
 * This hook will:
 * - store all history entries
 * - determine whether undo and redo are available
 * - provide the undo and redo functions
 * - provide with the ability to enqueue actions
 */
export const useEditorEngineHistory = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>({
    documentManager,
    nodeManager,
    extraContext,
    eventEmitter,
}: {
    /**
     * The document manager instance.
     */
    documentManager: TEditorEngineTypeInput['DocumentManager'];
    /**
     * The node manager instance.
     */
    nodeManager: TEditorEngineTypeInput['NodeManager'];
    /**
     * Extra context to be provided.
     */
    extraContext: TEditorEngineTypeInput['ExtraContext'];
    /**
     * The event emitter instance.
     */
    eventEmitter: EditorEngineEventEmitter;
}) => {
    const [entries, setEntries] = useState<
        Omit<EditorEngineHistoryEntryCollection<TEditorEngineTypeInput>, 'currentIndex'>
    >({
        entries: [],
        current: null,
    });

    const entriesInfo = useMemo(() => {
        return {
            ...entries,
            currentIndex: indexOfEntry({
                entries: entries.entries,
                entry: entries.current,
            }),
        };
    }, [entries]);

    const updateAction = (({ entry, result }) => {
        updateEntryActionRecordedResult({
            entry,
            setEntriesInfo: setEntries,
            result,
        });
    }) satisfies EditorEngineActionUpdater;

    const persistedUpdateQueue = useProcessingQueue<EditorEnginePersistedUpdate>({
        identify: (update) => update.entry.id,
        processor: ({ item, onFail }) =>
            processPersistedUpdateQueue({
                update: item,
                onFail,
                updateAction,
                eventEmitter,
            }),
    });

    const getExecutionAvailability = useCallback(
        (entry: EditorEngineHistoryDefaultEntry) => {
            return getEntryExecutionAvailability({
                entry,
                isInQueue: persistedUpdateQueue.isInQueue,
            });
        },
        [persistedUpdateQueue.isInQueue],
    );

    const process = useMemo(() => {
        return getProcessActionFunction<TEditorEngineTypeInput>({
            entriesInfo,
            setEntries,
            documentManager,
            nodeManager,
            extraContext: extraContext,
            addUpdateToQueue: persistedUpdateQueue.addItemToQueue,
            eventEmitter,
        });
    }, [
        entriesInfo,
        setEntries,
        documentManager,
        nodeManager,
        extraContext,
        persistedUpdateQueue,
        eventEmitter,
    ]);
    const nextUndoableEntry = getCurrentEntry({
        entriesInfo,
    });
    const nextRedoableEntry = getCurrentEntry({
        entriesInfo,
        offset: 1,
    });
    const undoAvailability = nextUndoableEntry
        ? getExecutionAvailability(nextUndoableEntry)
        : EditorEngineHistoryEntryExecutionAvailability.NoEntry;
    const undo = getUndoFunction({
        entriesInfo,
        setEntries,
        addUpdateToQueue: persistedUpdateQueue.addItemToQueue,
        isInQueue: persistedUpdateQueue.isInQueue,
        extraContext,
        eventEmitter,
    });
    const redoAvailability = nextRedoableEntry
        ? getExecutionAvailability(nextRedoableEntry)
        : EditorEngineHistoryEntryExecutionAvailability.NoEntry;
    const redo = getRedoFunction({
        entriesInfo,
        setEntries,
        addUpdateToQueue: persistedUpdateQueue.addItemToQueue,
        isInQueue: persistedUpdateQueue.isInQueue,
        extraContext,
        eventEmitter,
    });

    const entryQueue = useProcessingQueue<EditorEngineActionInstance<TEditorEngineTypeInput>>({
        identify: (item) => item.id,
        processor: async ({ item }) => {
            await Promise.resolve(); // If many items are enqueued, this will allow the UI to update before processing the next item.
            await process({
                action: item.action,
            });
        },
    });

    const enqueue = useMemo(() => {
        return (action: (typeof entries)['entries'][number]['action']) => {
            entryQueue.addItemToQueue({
                id: guid(),
                action,
            });
        };
    }, [entryQueue]);

    const getEntryPositioning = useCallback(
        (entryId: string) => {
            return getHistoryEntryPositioning({
                entryId,
                entriesInfo,
            });
        },
        [entriesInfo],
    );

    const canUndoValue = useMemo(() => {
        return !entryQueue.isProcessing && canUndo(undoAvailability);
    }, [entryQueue.isProcessing, undoAvailability]);

    const canRedoValue = useMemo(() => {
        return !entryQueue.isProcessing && canRedo(redoAvailability);
    }, [entryQueue.isProcessing, redoAvailability]);

    return {
        enqueue,
        entries: entries.entries,
        currentEntry: entries.current,
        currentEntryIndex: entriesInfo.currentIndex,
        undoAvailability,
        canUndo: canUndoValue,
        undo,
        redoAvailability,
        canRedo: canRedoValue,
        redo,
        setEntries,
        getEntryPositioning,
        getExecutionAvailability,
        persistedUpdateQueue,
    };
};
