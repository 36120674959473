export enum LOLI_FEATURE_FLAG_NAME {
    navigationUpdates = 'wks-navigation-updates',
    defaultWorkspace = 'wks-default-workspace',
    editorEngine = 'fun-editor-engine',
    logoBlock = 'fun-logo-block',
    reviewsBlock = 'fun-reviews-block',
    allWorkspacesPage = 'wks-all-workspaces-page',
    metricsPage = 'con-metrics-page',
}

export const OVERWRITES_STORAGE_KEY_PREFIX = 'loli_feature_flag_overwrite__';

export const LAST_INTERCOM_FEATURE_FLAG_EVENT_VALUES_LOCAL_STORAGE_KEY =
    'loli_feature_flags_last_intercom_event_values';
