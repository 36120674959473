import { createSelector, createSlice } from '@reduxjs/toolkit';
import formatDateIntl from 'date-fns/intlFormat';
import get from 'lodash/get';
import Router from 'next/router';

import { getAnalyticsContactsUrl, getAnalyticsSessionsUrl } from '@/app/campaigns/helpers';
import { hideModal } from '@/app/modals/models/modals';
import { apiGet, apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse, getMetaFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@/utils/empty';
import { localeMap } from '@/utils/hooks/useDateFormat';
import { LocalStorageValue } from 'types/generic';

import { NAME, LS_DELETE_SESSION_REMINDER_KEY } from '../constants';
import { createFetchSessionsUrl, enrichSessions, removeSession } from '../helper';

import type {
    Session,
    SessionAttributes,
    SessionProfile,
    SessionsPaginationAttributes,
    SessionsSortingAttributes,
} from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
    pagination: SessionsPaginationAttributes;
    sessions: Session[];
    search: string;
    details: { [sessionId: string]: SessionProfile[] };
    sorting: SessionsSortingAttributes;
    selectedRows: string[];
    deselectedRows: string[];
    allSelected: boolean;
    isContactsOnly: boolean;
}

const initialState: State = {
    loading: false,
    pagination: EMPTY_OBJECT,
    sessions: EMPTY_ARRAY,
    search: EMPTY_STRING,
    details: EMPTY_OBJECT,
    sorting: {
        orderField: 'createdAt',
        orderValue: -1,
    },
    selectedRows: EMPTY_ARRAY,
    deselectedRows: EMPTY_ARRAY,
    allSelected: false,
    isContactsOnly: false,
};

export const sessionsSlice = createSlice({
    name: `${NAME}/sessions`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setSessions(state, action: PayloadAction<Session[]>) {
            return {
                ...state,
                sessions: action.payload,
            };
        },
        setSearch(state, action: PayloadAction<string>) {
            return {
                ...state,
                search: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<SessionsPaginationAttributes>) {
            return {
                ...state,
                pagination: action.payload,
            };
        },
        setSorting(state, action: PayloadAction<SessionsSortingAttributes>) {
            return {
                ...state,
                sorting: action.payload,
            };
        },
        setSessionDetails(
            state,
            action: PayloadAction<{ sessionId: string; details: SessionProfile[] }>,
        ) {
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.sessionId]: action.payload.details,
                },
            };
        },
        setSelectedRows(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                selectedRows: action.payload,
            };
        },
        setDeselectedRows(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                deselectedRows: action.payload,
            };
        },
        setAllSelected(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                allSelected: action.payload,
            };
        },
        setIsContactsOnly(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isContactsOnly: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setLoading,
    setSessions,
    setSearch,
    setPagination,
    setSorting,
    setSessionDetails,
    setSelectedRows,
    setDeselectedRows,
    setAllSelected,
    setIsContactsOnly,
    reset,
} = sessionsSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.sessionsReducer?.loading;

export const getSessions = (state: AppState) => state[NAME]?.sessionsReducer?.sessions;

export const getSearch = (state: AppState) => state[NAME]?.sessionsReducer?.search;

export const getSessionsLength = (state: AppState) =>
    state[NAME]?.sessionsReducer?.sessions?.length;

export const getSessionsPagination = (state: AppState) => state[NAME]?.sessionsReducer?.pagination;

export const getSessionsTotalCount = (state: AppState) =>
    state[NAME]?.sessionsReducer?.pagination?.totalCount;

export const getSessionsSorting = (state: AppState) => state[NAME]?.sessionsReducer?.sorting;

const getSessionDetails = (state: AppState) => state[NAME]?.sessionsReducer?.details || EMPTY_ARRAY;

export const getSessionDetailsById = (sessionId: string) =>
    createSelector(getSessionDetails, (sessionDetails) =>
        get(sessionDetails, sessionId, EMPTY_ARRAY),
    );

export const getSelectedRows = (state: AppState) => state[NAME]?.sessionsReducer?.selectedRows;

export const getDeselectedRows = (state: AppState) => state[NAME]?.sessionsReducer?.deselectedRows;

export const getAllSelected = (state: AppState) => state[NAME]?.sessionsReducer?.allSelected;

export const getIsContactsOnly = (state: AppState) => state[NAME]?.sessionsReducer?.isContactsOnly;

// === Thunks ======

const createSessionDetails =
    (sessions: Session[]): AppThunk =>
    (dispatch) => {
        if (!sessions) {
            return;
        }

        const language = Router.locale;

        sessions.forEach((session) => {
            if (!session?.id) {
                return;
            }

            const createdAtDate = new Date(session?.attributes?.createdAt);

            const createdAtFormatted = formatDateIntl(
                createdAtDate,
                {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                },
                {
                    locale: localeMap[language]?.code,
                },
            );
            const details = [
                {
                    fieldName: 'createdAt',
                    title: 'column createdAt',
                    value: createdAtFormatted,
                },
                ...session.attributes.profile,
            ];

            dispatch(setSessionDetails({ sessionId: session.id, details }));
        });
    };

const addToSelectedRows =
    (sessions: Session[]): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const sessionIds = sessions.map((session) => session.id);

        const selectedRows = getSelectedRows(state).slice();
        selectedRows.push(...sessionIds);

        dispatch(setSelectedRows(selectedRows));
    };

const fetchSessionsSuccessful =
    (fetchedSessions: Session[], addToExisting: boolean = false): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        dispatch(createSessionDetails(fetchedSessions));

        const enrichedSessions = enrichSessions(fetchedSessions);

        if (addToExisting) {
            const existingSessions = getSessions(state).slice();
            const allSelected = getAllSelected(state);

            if (allSelected) {
                dispatch(addToSelectedRows(fetchedSessions));
            }

            existingSessions.push(...enrichedSessions);

            return dispatch(setSessions(existingSessions));
        }

        return dispatch(setSessions(enrichedSessions));
    };

const dataFetchSessions =
    (url: string, addToExisting: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiGet(url);
            dispatch(fetchSessionsSuccessful(getDataFromResponse(response), addToExisting));
            dispatch(setPagination(getMetaFromResponse(response)));
            dispatch(setLoading(false));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'data fetch sessions failed:' });
            dispatch(setLoading(false));
        }
    };

export const fetchSessions =
    ({
        campaignId,
        page,
        status,
        search,
    }: {
        campaignId: string;
        page?: number;
        status?: SessionAttributes['status'];
        search?: string;
    }): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const sorting = getSessionsSorting(state);
        const url = createFetchSessionsUrl({
            campaignId,
            page,
            search,
            status,
            ...sorting,
        });

        dispatch(dataFetchSessions(url, false));
    };

export const fetchSessionsPage =
    (url: string, addToExisting: boolean = true): AppThunk =>
    (dispatch) => {
        const croppedUrl = `/${url.split('/').pop()}`;
        dispatch(dataFetchSessions(croppedUrl, addToExisting));
    };

const fetchPagination =
    (url: string): AppThunk =>
    async (dispatch) => {
        try {
            const croppedUrl = `/${url.split('/').pop()}`;
            const response = await apiGet(croppedUrl);

            dispatch(setPagination(getMetaFromResponse(response)));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetch pagination failed:' });
        }
    };

export const sortAndRefetchSessions =
    (campaignId: string, sorting: SessionsSortingAttributes): AppThunk =>
    async (dispatch, getState) => {
        const isContactsOnly = getIsContactsOnly(getState());

        await dispatch(setSorting(sorting));
        dispatch(
            fetchSessions({
                campaignId,
                page: 1,
                status: isContactsOnly ? 'lead' : undefined,
            }),
        );
    };

const optimisticDeleteSelectedRows = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const selectedRows = getSelectedRows(state);
    const deselectedRows = getDeselectedRows(state);
    const allSelected = getAllSelected(state);
    const allSessions = getSessions(state);
    let updatedSessions = getSessions(state).slice();

    // delete everything except deselected rows
    if (allSelected) {
        updatedSessions = allSessions.filter((session) => deselectedRows.includes(session.id));

        return dispatch(setSessions(updatedSessions));
    }

    // delete only selected rows
    selectedRows.forEach((sessionId) => {
        updatedSessions = removeSession(updatedSessions, sessionId);
    });

    return dispatch(setSessions(updatedSessions));
};

const dataDeleteSessions =
    (campaignId: string, status: 'lead' | 'visitor'): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const search = getSearch(state);
        const allSelected = getAllSelected(state);
        const selectedRows = getSelectedRows(state);
        const deselectedRows = getDeselectedRows(state);

        try {
            // @todo BUG: Prevent bulk delete with search. Fix TBI
            if (allSelected && !!search) {
                throw new Error('Bulk delete currently unavailable.');
            }

            return await apiPost('/sessions/bulk-delete', {
                data: {
                    campaignId,
                    status,
                    evaluation: {
                        ids: allSelected ? deselectedRows : selectedRows,
                        kind: allSelected ? 'blacklist' : 'whitelist',
                    },
                },
            });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'bulk delete sessions failed:' });
        }
    };

export const resetSelection = (): AppThunk => (dispatch) => {
    dispatch(setSelectedRows(EMPTY_ARRAY));
    dispatch(setDeselectedRows(EMPTY_ARRAY));
    dispatch(setAllSelected(false));
};

export const deleteSelectedSessions =
    (campaignId: string, skipNextTime?: boolean, isContactsOnly?: boolean): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();

        if (skipNextTime) {
            localStorage.setItem(LS_DELETE_SESSION_REMINDER_KEY, LocalStorageValue.TRUE);
        }

        const pagination = getSessionsPagination(state);
        dispatch(optimisticDeleteSelectedRows());

        // Delete in DB
        await dispatch(dataDeleteSessions(campaignId, isContactsOnly ? 'lead' : 'visitor'));

        // re-fetch session pagination
        dispatch(fetchPagination(pagination?.self));

        dispatch(hideModal());
        dispatch(resetSelection());
    };

const dataDeleteSingleSession =
    (sessionId: string, campaignId: string, status: 'lead' | 'visitor'): AppThunk =>
    async () => {
        try {
            return await apiPost('/sessions/bulk-delete', {
                data: {
                    campaignId,
                    evaluation: {
                        ids: [sessionId],
                        kind: 'whitelist',
                    },
                    status,
                },
            });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'delete single session failed:' });
        }
    };

const optimisticDeleteSessionId =
    (sessionId: string): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        let updatedSessions = getSessions(state).slice();

        updatedSessions = removeSession(updatedSessions, sessionId);

        dispatch(setSessions(updatedSessions));
    };

export const deleteSingleSession =
    ({
        sessionId,
        campaignId,
        skipNextTime = false,
        status,
    }: {
        sessionId: string;
        campaignId: string;
        status: 'lead' | 'visitor';
        skipNextTime?: boolean;
    }): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const isContactsOnly = getIsContactsOnly(state);
        const pagination = getSessionsPagination(state);
        const redirectRoute = isContactsOnly
            ? getAnalyticsContactsUrl(campaignId)
            : getAnalyticsSessionsUrl(campaignId);

        if (skipNextTime) {
            localStorage.setItem(LS_DELETE_SESSION_REMINDER_KEY, LocalStorageValue.TRUE);
        }

        dispatch(optimisticDeleteSessionId(sessionId));
        await dispatch(dataDeleteSingleSession(sessionId, campaignId, status));

        // re-fetch session pagination
        await dispatch(fetchPagination(pagination.self));

        dispatch(hideModal());
        Router.push(redirectRoute, null, { shallow: true });
    };

// Table Thunks
export const toggleRowSelection =
    (rowId: string): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const allSelected = getAllSelected(state);
        const selection = allSelected ? getDeselectedRows(state) : getSelectedRows(state);
        const newSelection = selection.slice();
        const index = newSelection.indexOf(rowId);

        if (index > -1) {
            newSelection.splice(index, 1);

            if (allSelected) {
                return dispatch(setDeselectedRows(newSelection));
            }

            return dispatch(setSelectedRows(newSelection));
        }

        if (allSelected) {
            return dispatch(setDeselectedRows([...newSelection, rowId]));
        }

        return dispatch(setSelectedRows([...newSelection, rowId]));
    };

export const toggleSelectAll =
    (allSelected: boolean): AppThunk =>
    (dispatch) => {
        dispatch(setAllSelected(allSelected));
        dispatch(setSelectedRows(EMPTY_ARRAY));
        dispatch(setDeselectedRows(EMPTY_ARRAY));
    };

export default sessionsSlice.reducer;
