import { MIN_LABEL_WIDTH, TALL_BLOCK_OFFSET } from '@/app/editor/editor/constants';

import { autoUpdate, offset, shift, useFloating, limitShift } from '@floating-ui/react';
import dynamic from 'next/dynamic';
import { useContext, useMemo } from 'react';
import { createElement, forwardRef, memo } from 'react';

import { isPreviewContext } from '@/app/editor/engine/core/context/isPreviewContext';
import {
    EditorEngineDropPosition,
    EditorEngineNodeDragStateType,
} from '@/app/editor/engine/core/types';
import { usePerspectiveEditorEngine } from '@/app/editor/engine/PerspectiveEditorEngine';
import { cn } from '@/utils/cn';

import { Label } from './Label';
import { SectionPreview } from '../SectionPreview';

import type { BlockResource, EditFormValues, PropsFromParent } from '../../../../blocks/types';
import type { EditorEngineNodeDragInfo } from '@/app/editor/engine/core/types';
import type { ThemeResource } from '@/app/editor/themes/types';
import type { DraggableAttributes } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import type { FunctionComponent, ComponentClass, MouseEvent } from 'react';

const BorderMenu = dynamic(() => import('./BorderMenu'));

export interface Props {
    blockId: string;
    block: BlockResource;
    component: FunctionComponent | ComponentClass;
    activeTheme: ThemeResource;
    nestedLevel: number;
    hasBorderMenu: boolean;
    artBoardIndex?: number;
    children?: any;
    isActive?: boolean;
    isHovered?: boolean;
    isHighlighted?: boolean;
    hasActiveChild?: boolean;
    insertingInBlankColumnId?: string;
    sectionPreviewId?: string;
    onClick: (event: MouseEvent) => void;
    onHover: (event: MouseEvent) => void;
    onLeave: (event: MouseEvent) => void;
    propsFromParent?: PropsFromParent;
    // DNDKit
    isDragged?: boolean;
    isDragPreview?: boolean;
    dragStyle?: any;
    dragListeners?: SyntheticListenerMap;
    dragAttributes?: DraggableAttributes;
    additionalBlocks?: EditFormValues['additionalBlocks'];
    dragInfo?: EditorEngineNodeDragInfo;
    isDraggingDisabled?: boolean;
}

// App editor navbar is 64px high.
const BORDER_MENU_SHIFT_TOP_OFFSET = 64;

const BlockView = forwardRef<HTMLDivElement, Props>(
    (
        {
            children,
            blockId,
            block,
            nestedLevel,
            hasBorderMenu,
            artBoardIndex,
            component,
            activeTheme,
            isActive,
            isHovered,
            isHighlighted,
            hasActiveChild,
            insertingInBlankColumnId,
            sectionPreviewId,
            onClick,
            onHover,
            onLeave,
            isDragged,
            isDragPreview,
            dragStyle,
            dragAttributes,
            dragListeners,
            propsFromParent,
            additionalBlocks,
            dragInfo,
            isDraggingDisabled = false,
        },
        ref,
    ) => {
        const { isActive: isWithinEditorEngine } = usePerspectiveEditorEngine();
        const isPreview = useContext(isPreviewContext);
        // Enrich Block Component with props
        const blockElement = useMemo(() => {
            return component
                ? createElement(
                      component,
                      {
                          blockId: block.id,
                          componentType: block.attributes.componentType,
                          nestedLevel,
                          activeTheme,
                          isDragged,
                          isDragPreview,
                          isActive,
                          artBoardIndex,
                          additionalBlocks,
                          dragInfo,
                          isPreview: isWithinEditorEngine ? isPreview : undefined,
                          ...propsFromParent,
                          ...block.attributes.content,
                      },
                      children,
                  )
                : null;
        }, [
            dragInfo,
            activeTheme,
            block.attributes.componentType,
            block.attributes.content,
            block.id,
            children,
            component,
            nestedLevel,
            propsFromParent,
            isDragged,
            isDragPreview,
            isActive,
            artBoardIndex,
            additionalBlocks,
            isPreview,
            isWithinEditorEngine,
        ]);

        const isTargetedByWithinDrop =
            isWithinEditorEngine &&
            dragInfo?.type === EditorEngineNodeDragStateType.Target &&
            dragInfo?.position === EditorEngineDropPosition.Within;

        // DnD and hover classes
        const className = cn(
            'relative cursor-pointer rounded-sm outline-offset-2',
            propsFromParent?.blockWrapperClass,
            {
                'scale-100': isHovered,
                // todo(editorengine): styles applied during a drag and drop operation are yet to be finalized
                'scale-105 animate-start-drag shadow-xl': isDragged && !isWithinEditorEngine,
                '!outline-none': isDragPreview,
                'outline-2': !isDragPreview,
                'z-30 outline outline-blue-500':
                    isActive || (isActive && isHovered) || isTargetedByWithinDrop,
                'z-10 outline outline-dashed outline-gray-300':
                    isHovered !== isHighlighted && !isActive,
                'z-10 outline outline-dashed outline-blue-500': isHighlighted && isHovered,
                'z-20': hasActiveChild,
            },
        );

        // Styling
        const borderMenuPosition = propsFromParent?.borderMenuPosition || 'right';

        // Floating UI
        const blockElem = document.getElementById(blockId);
        const isTallBlock = blockElem?.getBoundingClientRect()?.height > TALL_BLOCK_OFFSET;
        const isWideEnoughForLabel = blockElem?.getBoundingClientRect()?.width > MIN_LABEL_WIDTH;

        const { x, y, strategy, refs } = useFloating({
            placement: borderMenuPosition,
            middleware: [
                isTallBlock &&
                    shift({
                        // Simple fix so that the floating border menu does not
                        // go "behind" the editor top navbar.
                        boundary: {
                            y: BORDER_MENU_SHIFT_TOP_OFFSET,
                            x: 0,
                            width: window.innerWidth,
                            height: window.innerHeight - BORDER_MENU_SHIFT_TOP_OFFSET,
                        },
                        limiter: limitShift({
                            crossAxis: false,
                            offset: ({ rects }) => {
                                if (rects.reference.height > rects.floating.height) {
                                    return rects.floating.height;
                                }

                                return 0;
                            },
                        }),
                    }),
                offset(16),
            ],
            whileElementsMounted: autoUpdate,
        });

        return (
            <div
                id={blockId}
                className={cn(
                    'relative',
                    isDragged
                        ? propsFromParent?.draggedBlockContainerClass
                        : propsFromParent?.blockContainerClass,
                )}
                style={dragStyle}
                ref={ref}
            >
                <div
                    onClick={onClick}
                    onMouseOver={onHover}
                    onMouseOut={onLeave}
                    className={className}
                    ref={refs.setReference}
                >
                    {isActive && isWideEnoughForLabel && !isDragPreview && (
                        <Label
                            block={block}
                            dragListeners={{ ...dragListeners }}
                            dragAttributes={{ ...dragAttributes }}
                            isDragged={isDragged}
                            isDraggingDisabled={isDraggingDisabled}
                        />
                    )}
                    {blockElement}
                </div>

                {/* Border Menu */}
                {hasBorderMenu && isActive && !isDragged && (
                    <div
                        className="z-20 py-2"
                        ref={refs.setFloating}
                        style={{
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                            width: 'max-content',
                        }}
                    >
                        <BorderMenu
                            blockId={block.id}
                            dragListeners={{ ...dragListeners }}
                            dragAttributes={{ ...dragAttributes }}
                            tooltipLeft={borderMenuPosition === 'left'}
                            tooltipDisabled={isDragged}
                        />
                    </div>
                )}

                {/* Section Preview */}
                {sectionPreviewId && !insertingInBlankColumnId && (
                    <SectionPreview sectionPreviewId={sectionPreviewId} />
                )}
            </div>
        );
    },
);

BlockView.displayName = 'BlockView'; // required because of forwardRef wrapper

export default memo(BlockView);
