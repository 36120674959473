import { ENDPOINTS, MUTATION_KEYS } from '@/app/imageLibrary/constants';

import { useMutation } from '@tanstack/react-query';

import { apiPost } from '@/core/api';
import { reportMessage } from '@/utils/sentry';

export const useUseImage = () => {
    return useMutation({
        mutationKey: MUTATION_KEYS.useImage(),
        mutationFn: async ({ imageId }: { imageId: string }) => {
            await apiPost(ENDPOINTS.POST.useImage(imageId), {});
        },
        onError: (err) => {
            reportMessage({
                message: `Failed to use image: ${err.message}`,
                source: 'image-library',
                severityLevel: 'warning',
            });
        },
    });
};
