import { useEffect, useState } from 'react';

import type { MutableRefObject } from 'react';

export const useDropdownDirection = (
    ref: MutableRefObject<HTMLElement | null>,
    height: number,
): string => {
    const [direction, setDirection] = useState('');

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const elementRect = ref.current?.getBoundingClientRect();
        const spaceAbove = elementRect.top;
        const spaceBelow = window.innerHeight - elementRect.bottom;

        if (spaceBelow < height && spaceAbove > height) {
            setDirection('up');
        }
    }, [height, ref]);

    return direction;
};
