import { createSlice } from '@reduxjs/toolkit';
import { convertFromRaw, convertToRaw, EditorState, Modifier } from 'draft-js';
import isEqual from 'lodash/isEqual';
import { change } from 'redux-form';

import { fixRawState } from '@/app/editor/blocks/components/Text/DraftEditor/helper';
import { getBlockById } from '@/app/editor/blocks/models/blocks';
import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';

import { UpdateBlockCommand } from '../../commands/commands/updateBlockCommand';
import getHistoryController from '../../commands/utils/HistoryControllers';
import { getActiveTextSize } from '../../editor/components/Sidebar/BlockEdit/elements/TextSize/helper';
import {
    AI_TEXT_SUGGESTION_GENERATOR_URL,
    AI_TEXT_SUGGESTION_TEMPERATURE,
    MAX_WORDS_GENERATED_LONG,
    MAX_WORDS_GENERATED_SHORT,
} from '../../editor/constants';
import { NAME } from '../constants';

import type { DraftEditorPlugins } from '@/app/editor/blocks/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RawDraftContentState } from 'draft-js';

interface State {
    draftEditorStates: {
        [textBlockId: string]: EditorState;
    };
    draftEditorPlugins: {
        [textBlockId: string]: DraftEditorPlugins;
    };
    originalAiPromptData: {
        [textBlockId: string]: {
            originalPrompt?: string;
            lastGeneratedText?: string;
        };
    };
    generatingText: boolean;
}

const initialState: State = {
    draftEditorStates: EMPTY_OBJECT,
    draftEditorPlugins: EMPTY_OBJECT,
    originalAiPromptData: EMPTY_OBJECT,
    generatingText: false,
};

export const draftEditorStatesSlice = createSlice({
    name: `editor/${NAME}/draftEditorStates`,
    initialState,
    reducers: {
        setDraftEditorState(
            state,
            action: PayloadAction<{ textBlockId: string; editorState: EditorState }>,
        ) {
            return {
                ...state,
                draftEditorStates: {
                    ...state.draftEditorStates,
                    [action.payload.textBlockId]: action.payload.editorState,
                },
            };
        },
        setOriginalAiPrompt(state, action: PayloadAction<{ textBlockId: string; prompt: string }>) {
            return {
                ...state,
                originalAiPromptData: {
                    ...state.originalAiPromptData,
                    [action.payload.textBlockId]: {
                        ...(state.originalAiPromptData?.[action.payload.textBlockId] || {}),
                        originalPrompt: action.payload.prompt,
                    },
                },
            };
        },
        setLastGeneratedText(state, action: PayloadAction<{ textBlockId: string; text: string }>) {
            return {
                ...state,
                originalAiPromptData: {
                    ...state.originalAiPromptData,
                    [action.payload.textBlockId]: {
                        ...(state.originalAiPromptData?.[action.payload.textBlockId] || {}),
                        lastGeneratedText: action.payload.text,
                    },
                },
            };
        },
        setGeneratingText(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                generatingText: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setDraftEditorState,
    setOriginalAiPrompt,
    setLastGeneratedText,
    setGeneratingText,
    reset,
} = draftEditorStatesSlice.actions;

// === Selectors ======

export const getEditorState = (state: AppState, textBlockId: string) =>
    state[NAME]?.draftEditorStatesReducer?.draftEditorStates[textBlockId];

export const getGeneratingText = (state: AppState) =>
    state[NAME]?.draftEditorStatesReducer?.generatingText;

export const getOriginalAiPromptDataByBlockId = (state: AppState, textBlockId: string) =>
    state[NAME]?.draftEditorStatesReducer?.originalAiPromptData[textBlockId];

// === Thunks ======

// init editorState in store for text block
export const initDraftEditorState =
    (textBlockId: string, wysiwyg: RawDraftContentState): AppThunk =>
    (dispatch) => {
        const editorState = EditorState.createWithContent(convertFromRaw(fixRawState(wysiwyg)));

        dispatch(setDraftEditorState({ textBlockId, editorState }));
    };

// Update editorState in redux and update Block (redux and DB)
export const saveDraftState =
    (textBlockId: string, editorState: EditorState, path = 'wysiwyg'): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const block = getBlockById(state, textBlockId);
        const content = editorState.getCurrentContent();
        const rawContent = convertToRaw(content);
        const historyController = getHistoryController();

        await dispatch(setDraftEditorState({ textBlockId, editorState }));

        // also update redux form data
        await dispatch(change(textBlockId, `attributes.content.${path}`, rawContent));

        const updatedBlock = {
            ...block,
            attributes: {
                ...block.attributes,
                content: {
                    ...block.attributes.content,
                    ...(path === 'wysiwyg' && {
                        wysiwyg: rawContent,
                    }),
                    ...(path === 'misc.wysiwyg' && {
                        misc: {
                            ...block.attributes.content.misc,
                            wysiwyg: rawContent,
                        },
                    }),
                },
            },
        };

        const isDraftStateEqual = isEqual(block, updatedBlock);

        if (!isDraftStateEqual) {
            const updateBlockCommand = new UpdateBlockCommand(updatedBlock);
            historyController.executeCommand(updateBlockCommand);
        }
    };

// Replace text in editorState in redux and update Block (redux and DB)
export const generateAiTextSuggestion =
    (textBlockId: string, lang = 'de'): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setGeneratingText(true));
        const state = getState();

        try {
            const editorState = getEditorState(state, textBlockId);

            if (!editorState) {
                return;
            }

            const originalAiPromptData = getOriginalAiPromptDataByBlockId(state, textBlockId);

            const textSize = getActiveTextSize(editorState);
            const generatedTextMaxLength = ['L', 'XL'].includes(textSize)
                ? MAX_WORDS_GENERATED_SHORT
                : MAX_WORDS_GENERATED_LONG;

            const { replaceText } = Modifier;

            const contentState = editorState.getCurrentContent();
            const selectionState = editorState.getSelection();
            const anchorKey = selectionState.getAnchorKey();

            const block = editorState.getCurrentContent().getBlockForKey(anchorKey);
            const blockText = block.getText();

            const promptToSubmit =
                originalAiPromptData?.originalPrompt?.length &&
                blockText === originalAiPromptData?.lastGeneratedText
                    ? originalAiPromptData.originalPrompt
                    : blockText;

            const res = await apiPost(AI_TEXT_SUGGESTION_GENERATOR_URL, {
                data: {
                    text: promptToSubmit,
                    lang,
                    maxWords: generatedTextMaxLength,
                    // This affects the probability of how random the next generated word in the response text will be
                    // Higher temperature means more "creative" text, while lower temp (<1.0) means the generated text would be more similar every time
                    temperature: AI_TEXT_SUGGESTION_TEMPERATURE,
                },
            });

            const generatedText = getDataFromResponse(res);

            const newContentState = replaceText(
                contentState,
                selectionState.merge({
                    // The starting position of the range to be replaced.
                    anchorOffset: 0,
                    // The end position of the range to be replaced.
                    focusOffset: contentState.getPlainText()?.length ?? 0,
                }),
                generatedText?.text,
                editorState.getCurrentInlineStyle(),
            );

            dispatch(setLastGeneratedText({ textBlockId, text: generatedText?.text }));

            dispatch(
                saveDraftState(
                    textBlockId,
                    EditorState.push(editorState, newContentState, 'change-block-data'),
                ),
            );

            if (originalAiPromptData?.originalPrompt !== promptToSubmit) {
                dispatch(setOriginalAiPrompt({ textBlockId, prompt: promptToSubmit }));
            }
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'generating AI text suggestion failed' });
        } finally {
            dispatch(setGeneratingText(false));
        }
    };

export default draftEditorStatesSlice.reducer;
