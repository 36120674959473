import type { ReactNode } from 'react';

export interface Props {
    children: ReactNode;
    className?: string;
}

const FieldContainer = ({ children, className, ...rest }: Props) => {
    return (
        <div className={className ? className : 'mb-2 w-full'} {...rest}>
            {children}
        </div>
    );
};

export default FieldContainer;
