import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import isEqual from 'lodash/isEqual';

import { getBlockById, getBlockParent } from '@/app/editor/blocks/models/blocks';
import { BlockComponentType } from '@/app/editor/blocks/types';
import { UpdateBlockCommand } from '@/app/editor/commands/commands/updateBlockCommand';
import getHistoryController from '@/app/editor/commands/utils/HistoryControllers';
import { getUser } from '@/app/user/models/user';
import { handleRuntimeError } from '@/core/api';
import { EMPTY_OBJECT } from '@/utils/empty';

import { NAME } from '../constants';

import type { EditFormValues } from '@/app/editor/blocks/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { JSONContent } from '@tiptap/core';

interface State {
    jwt: string;
    // Used for undo/redo and when content is empty
    previousContent: Record<string, JSONContent>;
}

const initialState: State = {
    jwt: '',
    previousContent: EMPTY_OBJECT,
};

export const tipTapSlice = createSlice({
    name: `editor/${NAME}/tipTap`,
    initialState,
    reducers: {
        setAiJWT: (state, action: PayloadAction<string>) => {
            state.jwt = action.payload;
        },
        setPreviousContent: (
            state,
            action: PayloadAction<{ blockId: string; content: JSONContent }>,
        ) => {
            state.previousContent[action.payload.blockId] = action.payload.content;
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setAiJWT, setPreviousContent, reset } = tipTapSlice.actions;

// === Selectors ======

export const getTipTapJWT = (state: AppState) => state[NAME]?.tipTapReducer?.jwt;

export const getTipTapContent = (state: AppState, blockId: string) => {
    const block = getBlockById(state, blockId);

    return block?.attributes?.content?.wysiwyg || block?.attributes?.content?.misc?.wysiwyg;
};

export const getTipTapPreviousContentByBlockId = (state: AppState, blockId: string) =>
    state[NAME]?.tipTapReducer?.previousContent[blockId];

// === Thunks ======

export const signTipTapAIJWT = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const user = getUser(state);

    try {
        const response = await axios.post('/api/sign-tip-tap-jwt', {
            userId: user?.id || 'unknown',
        });

        const { token } = await response.data;

        dispatch(setAiJWT(token));
    } catch (error) {
        handleRuntimeError(error, { message: 'Failed to sign JWT for TipTap AI' });
    }
};

export const clearPreviousContent =
    (blockId: string): AppThunk =>
    (dispatch) => {
        dispatch(setPreviousContent({ blockId, content: null }));
    };

// todo(editorengine): https://linear.app/perspective/issue/FUN-968/ensure-the-tiptap-rules-are-enforced-in-the-editor-engine
export const updateTipTapContent =
    ({
        blockId,
        json,
        plainText,
        isEmpty,
        overrideCommand,
    }: {
        blockId: string;
        json: JSONContent;
        plainText: string;
        isEmpty?: boolean;
        overrideCommand?: (values: EditFormValues) => void;
    }): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const block = getBlockById(state, blockId);
        const parent = getBlockParent(state, blockId);
        const isStandaloneText =
            (!parent || parent.attributes.componentType === BlockComponentType.GRID_COLUMN) &&
            block?.attributes?.componentType === BlockComponentType.TEXT;

        if (!block) {
            return;
        }

        const existingContent = getTipTapContent(state, blockId);
        const historyController = getHistoryController();

        // Checkbox inputs are special cases where the content is stored in a different path (misc.wysiwyg)
        const hasMiscWysiwygPath =
            block.attributes.componentType === 'input' &&
            block.attributes.content.inputType === 'checkbox';

        // No need to save if new content is equal to existing content
        if (isEqual(json, existingContent)) {
            return;
        }

        // Texts in answers and questions cannot be empty,
        // since we need them to be tracked and displayed properly in Analytics and the CRM.
        // Standalone texts can be empty (e.g. after cmd+x)
        if (isEmpty && !isStandaloneText) {
            const wysiwyg =
                block?.attributes?.content?.wysiwyg ?? block?.attributes?.content?.misc?.wysiwyg;

            return dispatch(setPreviousContent({ blockId: block.id, content: wysiwyg }));
        }

        const updatedBlock = {
            ...block,
            attributes: {
                ...block.attributes,
                content: {
                    ...block.attributes.content,
                    plainText,
                    ...(hasMiscWysiwygPath
                        ? {
                              misc: {
                                  ...block.attributes.content.misc,
                                  wysiwyg: json,
                              },
                          }
                        : {
                              wysiwyg: json,
                          }),
                },
            },
        };

        // Needed for the editor engine to override the actual update
        if (overrideCommand) {
            overrideCommand(updatedBlock);

            return;
        }

        const updateBlockCommand = new UpdateBlockCommand(updatedBlock);
        historyController.executeCommand(updateBlockCommand);
    };

export default tipTapSlice.reducer;
