import {
    DEFAULT_DRAGGABLE_SIDE_THRESHOLD,
    DRAGGABLE_DETECTION_DISTANCE,
} from '@/app/editor/engine/core/constants';

import { EditorEngineDropPosition } from '@/app/editor/engine/core/types';

import type { CollisionDescriptor, CollisionDetection } from '@dnd-kit/core';
import type { Coordinates } from '@dnd-kit/utilities';

/**
 * An interface that describes a rectangle.
 */
interface ClientRect {
    /**
     * The width of the rectangle.
     */
    width: number;
    /**
     * The height of the rectangle.
     */
    height: number;
    /**
     * The top position of the rectangle.
     */
    top: number;
    /**
     * The left position of the rectangle.
     */
    left: number;
    /**
     * The right position of the rectangle.
     */
    right: number;
    /**
     * The bottom position of the rectangle.
     */
    bottom: number;
}

/**
 * Sorts collisions in ascending order.
 */
const sortCollisionsAsc = (
    { data: { value: left } }: CollisionDescriptor,
    { data: { value: right } }: CollisionDescriptor,
) => left - right;

/**
 * Checks if a pointer is close enough to a rectangle.
 */
const isPointerCloseEnoughToRect = (pointer: Coordinates, rect: ClientRect) =>
    pointer.x >= rect.left - DRAGGABLE_DETECTION_DISTANCE &&
    pointer.x <= rect.right + DRAGGABLE_DETECTION_DISTANCE &&
    pointer.y >= rect.top - DRAGGABLE_DETECTION_DISTANCE &&
    pointer.y <= rect.bottom + DRAGGABLE_DETECTION_DISTANCE;

/**
 * Finds the closest point on a side of a rectangle to the pointer.
 */
const closestPointOnSide = (pointer: Coordinates, start: Coordinates, end: Coordinates) => {
    const lengthX = end.x - start.x;
    const lengthY = end.y - start.y;

    const length = Math.hypot(lengthX, lengthY);
    const t = Math.max(
        0,
        Math.min(
            1,
            ((pointer.x - start.x) * lengthX + (pointer.y - start.y) * lengthY) / (length * length),
        ),
    );

    return {
        x: start.x + t * lengthX,
        y: start.y + t * lengthY,
    };
};

/**
 * A collision detection function that finds the closest sides of targetable
 * containers to the pointer.
 *
 * If a container is delimited by a rectangle that is close enough to the
 * pointer, the function will find the closest side of the rectangle to the
 * pointer and consider it a collision.
 */
export const closestPointerCollisionDetection: CollisionDetection = ({
    droppableContainers,
    droppableRects,
    pointerCoordinates,
}) => {
    if (!pointerCoordinates) {
        return [];
    }

    const collisions: CollisionDescriptor[] = [];

    droppableContainers.forEach((droppableContainer) => {
        const { id } = droppableContainer;
        const rect = droppableRects.get(id);

        if (rect && isPointerCloseEnoughToRect(pointerCoordinates, rect)) {
            const topStart = {
                x: rect.left + rect.width * DEFAULT_DRAGGABLE_SIDE_THRESHOLD,
                y: rect.top,
            };
            const topEnd = {
                x: rect.right - rect.width * DEFAULT_DRAGGABLE_SIDE_THRESHOLD,
                y: rect.top,
            };
            const bottomStart = {
                x: rect.left + rect.width * DEFAULT_DRAGGABLE_SIDE_THRESHOLD,
                y: rect.bottom,
            };
            const bottomEnd = {
                x: rect.right - rect.width * DEFAULT_DRAGGABLE_SIDE_THRESHOLD,
                y: rect.bottom,
            };
            const leftStart = {
                x: rect.left,
                y: rect.top + rect.height * DEFAULT_DRAGGABLE_SIDE_THRESHOLD,
            };
            const leftEnd = {
                x: rect.left,
                y: rect.bottom - rect.height * DEFAULT_DRAGGABLE_SIDE_THRESHOLD,
            };
            const rightStart = {
                x: rect.right,
                y: rect.top + rect.height * DEFAULT_DRAGGABLE_SIDE_THRESHOLD,
            };
            const rightEnd = {
                x: rect.right,
                y: rect.bottom - rect.height * DEFAULT_DRAGGABLE_SIDE_THRESHOLD,
            };

            const sides = [
                { position: EditorEngineDropPosition.Top, start: topStart, end: topEnd },
                { position: EditorEngineDropPosition.Bottom, start: bottomStart, end: bottomEnd },
                { position: EditorEngineDropPosition.Left, start: leftStart, end: leftEnd },
                { position: EditorEngineDropPosition.Right, start: rightStart, end: rightEnd },
            ];

            sides.forEach(({ position, start, end }) => {
                const closestPoint = closestPointOnSide(pointerCoordinates, start, end);
                const distance = Math.hypot(
                    pointerCoordinates.x - closestPoint.x,
                    pointerCoordinates.y - closestPoint.y,
                );

                collisions.push({
                    id,
                    data: {
                        droppableContainer,
                        value: distance,
                        position,
                    },
                });
            });

            if (
                pointerCoordinates.x >= rect.left &&
                pointerCoordinates.x <= rect.right &&
                pointerCoordinates.y >= rect.top &&
                pointerCoordinates.y <= rect.bottom
            ) {
                const center = {
                    x: rect.left + rect.width / 2,
                    y: rect.top + rect.height / 2,
                };
                const distance = Math.hypot(
                    pointerCoordinates.x - center.x,
                    pointerCoordinates.y - center.y,
                );

                collisions.push({
                    id,
                    data: {
                        droppableContainer,
                        value: distance,
                        position: EditorEngineDropPosition.Within,
                    },
                });
            }
        }
    });

    collisions.sort(sortCollisionsAsc);

    return collisions;
};
