import { cn } from '@/utils/cn';

import { formatBytes } from './utils';

import type { ReactNode } from 'react';

interface Props {
    filename: string;
    filesize?: number;
    actions?: ReactNode;
    statusLabel?: ReactNode;
    inline: boolean;
}

export const FileInfo = ({ filename, filesize, inline, statusLabel, actions }: Props) => {
    return (
        <div
            className={cn('flex h-full cursor-default items-center gap-2 rounded-md p-2', {
                'bg-gray-100': !inline,
            })}
        >
            <p className="grow truncate" title={filename}>
                {filename}
            </p>

            <div className="flex items-center">
                {!!filesize && (
                    <span className="mr-2 whitespace-nowrap text-xs font-medium text-gray-500">
                        {formatBytes(filesize)}
                    </span>
                )}
                {statusLabel && (
                    <span className="mr-2 whitespace-nowrap text-xs font-medium">
                        {statusLabel}
                    </span>
                )}
                {actions}
            </div>
        </div>
    );
};
