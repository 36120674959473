import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createBrandSlice } from '@/app/imageLibrary/store/brandSlice';
import { createFolderSlice } from '@/app/imageLibrary/store/folderSlice';
import { createImageSlice } from '@/app/imageLibrary/store/imageSlice';
import { createSearchSlice } from '@/app/imageLibrary/store/searchSlice';
import { createSelectors } from '@/utils/createSelectors';

import type { BrandSlice } from '@/app/imageLibrary/store/brandSlice';
import type { FolderSlice } from '@/app/imageLibrary/store/folderSlice';
import type { ImageSlice } from '@/app/imageLibrary/store/imageSlice';
import type { SearchSlice } from '@/app/imageLibrary/store/searchSlice';

export type State = SearchSlice & ImageSlice & FolderSlice & BrandSlice;

const useImageLibraryStoreBase = create<State>()(
    devtools((...args) => ({
        ...createSearchSlice(...args),
        ...createImageSlice(...args),
        ...createFolderSlice(...args),
        ...createBrandSlice(...args),
    })),
);

export const useImageLibraryStore = createSelectors(useImageLibraryStoreBase);
