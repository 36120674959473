import isEqual from 'lodash/isEqual';

import { EditorEngineLogLevel } from '@/app/editor/engine/core/types/log';
import { changeNodeStep } from '@/app/editor/engine/core/utils/transaction/steps/changeNodeStep';
import { PerspectiveEditorEngineActionName } from '@/app/editor/engine/types';
import { apiPatch } from '@/core/api';

import type { BlockResource, EditFormValues } from '@/app/editor/blocks/types';
import type { EditorEngineActionCreatorPayload } from '@/app/editor/engine/core/types';
import type { PerspectiveEditorEngineActionCreator } from '@/app/editor/engine/types';

interface Payload extends EditorEngineActionCreatorPayload {
    /**
     * The values to update the block with.
     */
    values: EditFormValues;
}

/**
 * Action that will update the properties of a block.
 */
export const changeBlockAction = (({
    args: { values },
    nodeManager,
    extraContext: { clientSideComponents },
    log,
}) => {
    const { additionalBlocks, ...rootBlock } = values;
    let oldBlocks: BlockResource[] = [];
    const blocksToUpdate = [rootBlock];

    if (additionalBlocks) {
        const blockIds = Object.keys(additionalBlocks);

        blockIds.forEach((blockId) => {
            const block = nodeManager.getNode(blockId).block;
            const updatedBlock = {
                ...block,
                attributes: {
                    ...block.attributes,
                    content: {
                        ...block.attributes.content,
                        ...additionalBlocks[blockId].attributes.content,
                    },
                },
            };

            blocksToUpdate.push(updatedBlock);
        });
    }

    return {
        name: PerspectiveEditorEngineActionName.ChangeBlock,
        debug: {},
        shouldBeSkipped() {
            for (const blockToUpdate of blocksToUpdate) {
                const oldBlock = nodeManager.getNode(blockToUpdate.id).block;

                if (!isEqual(oldBlock, blockToUpdate)) {
                    return false;
                }
            }

            // Skip if all blocks are the same as before
            return true;
        },
        getTransaction() {
            return blocksToUpdate.map((blockToUpdate) =>
                changeNodeStep({
                    id: blockToUpdate.id,
                    newDataGetter: () => ({ block: blockToUpdate }),
                }),
            );
        },
        onBeforeOptimisticForward() {
            oldBlocks = blocksToUpdate.map(
                (blockToUpdate) => nodeManager.getNode(blockToUpdate.id)?.block,
            );
        },
        onAfterOptimisticForward(isRedo) {
            if (isRedo) {
                clientSideComponents.scrollToComponent.focusOn(rootBlock);
            }
        },
        onAfterOptimisticBackward() {
            clientSideComponents.scrollToComponent.focusOn(rootBlock);
        },
        async execute() {
            for (const blockToUpdate of blocksToUpdate) {
                await apiPatch(`/components/${nodeManager.resolveVirtualId(blockToUpdate.id)}`, {
                    data: { attributes: { content: blockToUpdate.attributes.content } },
                });
            }

            return {
                success: true,
            };
        },
        async undo() {
            for (const oldBlock of oldBlocks) {
                if (oldBlock) {
                    await apiPatch(`/components/${nodeManager.resolveVirtualId(rootBlock.id)}`, {
                        data: { attributes: { content: oldBlock.attributes.content } },
                    });
                } else {
                    log({
                        level: EditorEngineLogLevel.Warning,
                        message: `Old block is not set when undoing ${PerspectiveEditorEngineActionName.ChangeBlock}`,
                    });
                }
            }

            return {
                success: true,
            };
        },
    };
}) satisfies PerspectiveEditorEngineActionCreator<Payload, {}, {}>;
