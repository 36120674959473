import { LOLI_FEATURE_FLAG_NAME } from '@/core/loli-feature-flags/constants';

import { getLoliFeatureFlag, useLoliFeatureFlag } from '@/core/loli-feature-flags/store';

export const useNavigationUpdatesEnabled = () => {
    return useLoliFeatureFlag(LOLI_FEATURE_FLAG_NAME.navigationUpdates);
};

export const areNavigationUpdatesEnabled = () => {
    return getLoliFeatureFlag(LOLI_FEATURE_FLAG_NAME.navigationUpdates);
};
