import type { BlockResource } from '@/app/editor/blocks/types';

/**
 * Returns a function that resolves virtual IDs in a block.
 *
 * A block contains information about its own parent (when applicable) and the
 * blocks that it contains. When using the block as payload for a backend
 * request, all virtual IDs must be resolved to concrete IDs.
 */
export const getResolveVirtualIdsInBlock = ({
    resolveVirtualId,
}: {
    resolveVirtualId: (virtualId: string) => string;
}) => {
    return (block: BlockResource) => {
        return {
            ...block,
            id: resolveVirtualId(block.id),
            relationships: {
                ...block.relationships,
                parent: {
                    data: block.relationships.parent.data
                        ? {
                              ...block.relationships.parent.data,
                              id: resolveVirtualId(block.relationships.parent.data.id),
                          }
                        : null,
                },
                components: {
                    data: block.relationships.components.data.map((component) => ({
                        ...component,
                        id: resolveVirtualId(component.id),
                    })),
                },
            },
        };
    };
};
