import { NAME } from '@/app/imageLibrary/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import * as Slider from '@radix-ui/react-slider';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect } from 'react';

import { track } from '@/core/tracking';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import { Tooltip } from '@/ui/components/Tooltip';

import type { WrappedFieldInputProps } from 'redux-form';

interface Props {
    input: WrappedFieldInputProps;
    submit: () => void;
}

const getOpacity = (value: number | undefined): number => {
    if (value === undefined || typeof value !== 'number') {
        return 100;
    }

    return value;
};

export const BgImageOpacity = ({ input, submit }: Props) => {
    const { t } = useTranslation(NAME);
    const { value, onChange } = input;

    // Init with 100% opacity if not set
    useEffect(() => {
        if (value === undefined || typeof value !== 'number') {
            onChange(100);
            setTimeout(submit);
        }
    }, [onChange, submit, value]);

    const handleChangeCommit = useCallback(
        (value: number[]) => {
            track(TRACKING_EVENTS.campaign.editor.bgImage.opacity, {});
            onChange(value[0]);
            setTimeout(submit);
        },
        [onChange, submit],
    );

    const handleChange = useCallback(
        (value: number[]) => {
            onChange(value[0]);
        },
        [onChange],
    );

    return (
        <FieldContainer>
            <Tooltip
                content={t('image-opacity', {
                    opacity: getOpacity(value),
                })}
            >
                <div className="rounded-lg bg-gray-100 px-4">
                    <Slider.Root
                        className="relative flex h-12 w-full cursor-pointer items-center"
                        defaultValue={[getOpacity(value)]}
                        onValueChange={handleChange}
                        onValueCommit={handleChangeCommit}
                    >
                        <Slider.Track className="relative h-0.5 grow rounded-full bg-gray-300">
                            <Slider.Range className="absolute h-full rounded-full bg-gray-300" />
                        </Slider.Track>
                        <Slider.Thumb
                            className="block size-3 cursor-pointer rounded-full bg-gray-400 hover:bg-gray-600 focus:outline-none"
                            aria-label="Opacity"
                        />
                    </Slider.Root>
                </div>
            </Tooltip>
        </FieldContainer>
    );
};
