import { LOLI_FEATURE_FLAG_NAME } from '@/core/loli-feature-flags/constants';

import { getLoliFeatureFlag, useLoliFeatureFlag } from '@/core/loli-feature-flags/store';

export const useAllWorkspacesPageEnabled = () => {
    return useLoliFeatureFlag(LOLI_FEATURE_FLAG_NAME.allWorkspacesPage);
};

export const getAllWorkspacesPageEnabled = () => {
    return getLoliFeatureFlag(LOLI_FEATURE_FLAG_NAME.allWorkspacesPage);
};
