import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { useEffect, useState } from 'react';

import { getThemeColorsAsArray } from '@/app/editor/themes/helpers';
import { getPreviewOrActiveTheme } from '@/app/editor/themes/models/themes';
import { useAppSelector } from '@/core/redux/hooks';
import { track } from '@/core/tracking';
import ColorPicker from '@/ui/components/_BlockEditFields/ColorPicker';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import Tooltip from '@/ui/components/Tooltip';

import type { ColorPickerExpandDirection } from '@/app/editor/editor/types';
import type { ThemeColorKey } from '@/app/editor/themes/types';
import type { ColorPickerHistory } from '@/ui/components/_BlockEditFields/ColorPicker';
import type { FieldInputProps } from 'react-final-form';
import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps | FieldInputProps<string>;
    tooltip?: string;
    className?: string;
    themeColor: string;
    themeKey?: ThemeColorKey;
    hiddenThemingButton?: boolean;
    hasGradient?: boolean;
    expand: ColorPickerExpandDirection;
    submit?: () => void;
    colorPickerHistory?: ColorPickerHistory;
    onColorPickerHistoryChange?: (newColorPickerHistory: ColorPickerHistory) => void;
}

const Color = ({
    className,
    hiddenThemingButton,
    hasGradient,
    expand,
    input,
    submit,
    themeColor,
    themeKey,
    tooltip,
    colorPickerHistory,
    onColorPickerHistoryChange,
}: Props) => {
    const previewOrActiveTheme = useAppSelector(getPreviewOrActiveTheme);
    const [currentColorPickerHistory, setCurrentColorPickerHistory] = useState<
        ColorPickerHistory | undefined
    >(colorPickerHistory);

    const onChange = (color: string) => {
        const { onChange } = input;

        setTimeout(() => onChange(color));
    };

    const onHideComplete = () => {
        track(TRACKING_EVENTS.campaign.editor.color.changed, {
            source: 'sidebar',
        });

        if (submit) {
            setTimeout(submit);
        }
    };

    useEffect(() => {
        setCurrentColorPickerHistory(colorPickerHistory);
    }, [colorPickerHistory]);

    return (
        <FieldContainer className={className}>
            <Tooltip content={tooltip}>
                <div>
                    <ColorPicker
                        onChange={onChange}
                        color={input.value}
                        themeColor={themeColor}
                        expand={expand}
                        onHide={onHideComplete}
                        themeColors={getThemeColorsAsArray(previewOrActiveTheme)}
                        hiddenThemingButton={hiddenThemingButton}
                        hasGradient={hasGradient}
                        themeKey={themeKey}
                        colorPickerHistory={currentColorPickerHistory}
                        onColorPickerHistoryChange={(newColorPickerHistory) => {
                            setCurrentColorPickerHistory(newColorPickerHistory);
                            onColorPickerHistoryChange?.(newColorPickerHistory);
                        }}
                    />
                </div>
            </Tooltip>
        </FieldContainer>
    );
};

export default Color;
