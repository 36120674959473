import { combineReducers } from 'redux';

import analyticsReducer from './analytics';
import archiveReducer from './archive';
import campaignsReducer from './campaigns';
import deleteReducer from './delete';
import domainEditingReducer from './domainEditing';
import duplicateReducer from './duplicate';
import launchEventsReducer from './launchEvents';
import overviewReducer from './overview';
import publishReducer from './publish';
import restoreReducer from './restore';
import splitTestReducer from './splitTest';
import updateReducer from './update';
import versionsReducer from './versions';

export default combineReducers({
    analyticsReducer,
    campaignsReducer,
    domainEditingReducer,
    launchEventsReducer,
    overviewReducer,
    publishReducer,
    archiveReducer,
    restoreReducer,
    splitTestReducer,
    updateReducer,
    versionsReducer,
    duplicateReducer,
    deleteReducer,
});
