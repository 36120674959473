import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'react-i18next';
import { change, Field, getFormValues, reduxForm } from 'redux-form';

import BoxSettings from '@/app/editor/blocks/components/_helper/BoxSettings';
import { getIsImgixImage } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getActiveIndexBySliderId } from '@/app/editor/blocks/models/slider';
import CropButton from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/CropButton';
import IconToggleButton from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/IconToggleButton';
import Slides from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Slides';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import type { BlockResource, DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({ blockId, handleSubmit, activeTheme }) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const { isInColumn } = useLayoutInfo(blockId);

    const activeImageIndex = useAppSelector((state) => getActiveIndexBySliderId(state, blockId));
    const formValues = useAppSelector((state) => getFormValues(blockId)(state) as BlockResource);
    const images = formValues?.attributes?.content?.images || [];
    const showImages = formValues?.attributes?.content?.showImages;
    const activeImage = images[activeImageIndex];

    const handleCropSave = (src: string) => {
        dispatch(change(blockId, `attributes.content.images[${activeImageIndex}]`, src));

        setTimeout(handleSubmit);
    };

    return (
        <>
            <div className="grid w-full grid-cols-3 gap-2">
                <Field
                    name="attributes.content.showImages"
                    component={IconToggleButton}
                    submit={handleSubmit}
                    icon="avatars"
                    tooltip={t('show-avatars')}
                />

                <CropButton
                    src={images[activeImageIndex]}
                    onSave={handleCropSave}
                    size="custom"
                    disabled={!getIsImgixImage(activeImage)}
                    showTooltipWhenDisabled
                    overridenTooltip={
                        !getIsImgixImage(activeImage) ? t('brandfetch-crop-warning') : undefined
                    }
                />
            </div>

            {showImages && (
                <>
                    <Subheading text="Avatars" />

                    <Field
                        name="attributes.content.images"
                        component={Slides}
                        submit={handleSubmit}
                        blockId={blockId}
                    />
                </>
            )}

            <BoxSettings
                blockId={blockId}
                handleSubmit={handleSubmit}
                activeTheme={activeTheme}
                hiddenColor={isInColumn}
            />
        </>
    );
};

Form.displayName = 'ReviewsEditForm';

export default reduxForm({})(Form);
