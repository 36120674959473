import { createContext, useContext } from 'react';

import type {
    EditorEngineDefaultTypeInput,
    EditorEngineDraggableConfiguration,
} from '@/app/editor/engine/core/types';

/**
 * This function creates the context that holds the draggable configuration.
 * At any point in the node tree, this configuration can be extended or
 * overridden. When dragging nodes, this configuration is used to determine
 * whether a drag operation can be started, completed, and how it will be
 * executed.
 */
export const createDraggableConfigurationContext = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>() => {
    const context = createContext<EditorEngineDraggableConfiguration<TEditorEngineTypeInput>>({
        canBeDragged: () => false,
        canBeTargeted: () => false,
        canTarget: () => false,
        executeDrop: () => ({
            handler: () => {},
        }),
    });
    const useDraggableConfiguration = () => {
        return useContext(context);
    };

    return {
        context,
        Provider: context.Provider,
        useDraggableConfiguration,
    };
};
