import qs from 'query-string';

export const NAME = 'imageLibrary';

export const IMAGES_PER_PAGE = 30; // Max is 30 because the Unsplash API only allows 30 images per page
export const MAX_FILE_SIZE = 10_000_000;
export const MAX_FILE_COUNT = 30;
export const UPLOAD_RESET_TIMEOUT_MS = 4_000;
export const UNSPLASH_UTM_NAME = 'perspective';
export const MAX_FOLDERS = 100;
export const RECENT_UNSPLASH_SEARCHES = 'perspective.recent-unsplash-searches';
export const MAX_UNSPLASH_RECENT_SEARCH = 5;
export const DESKTOP_CONTENT_MAX_WIDTH = 1440;
export const BRANDFETCH_CDN_URL = 'https://cdn.brandfetch.io';

export const FIXED_FOLDER_IDS = {
    all: 'all',
    unsplash: 'unsplash',
    brandfetch: 'brandfetch',
} as const;

export const FIXED_FOLDER_NAMES = {
    [FIXED_FOLDER_IDS.all]: 'all-images',
    [FIXED_FOLDER_IDS.unsplash]: 'Unsplash',
    [FIXED_FOLDER_IDS.brandfetch]: 'Brandfetch',
} as const;

export const IMAGE_SIZE_ASPECT_MAP = {
    small: 16 / 9,
    medium: 4 / 3,
    large: 5 / 4,
    xLarge: 1,
    answer: 4 / 3,
} as const;

export const ACCEPTED_FILE_TYPES = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/webp': ['.webp'],
    'image/gif': ['.gif'],
};

export const ENDPOINTS = {
    GET: {
        folders: () => '/folders',
        folderImages: ({
            folderId,
            pageParam = 0,
            searchTerm,
        }: {
            folderId: string;
            pageParam: number;
            searchTerm?: string;
        }) => {
            const filterQuery = qs.stringify({
                limit: IMAGES_PER_PAGE,
                page: pageParam,
                search: searchTerm ? searchTerm : undefined,
            });

            return folderId === FIXED_FOLDER_IDS.all
                ? `/images?${filterQuery}`
                : `/folders/${folderId}/images?${filterQuery}`;
        },
        brandDetails: ({ domain }: { domain: string }) => `/brands/details/${domain}`,
        brandSearch: ({ searchTerm }: { searchTerm: string }) =>
            `/brands/search?query=${searchTerm}`,
        trackImageDownload: (imageId: string) =>
            `/photos/${imageId}/download?client_id=${process.env.NEXT_PUBLIC_UNSPLASH_APP_ID}`,
    },
    POST: {
        createFolder: () => '/folders',
        moveImage: (imageId: string) => `/images/${imageId}/folder`,
        moveUnsplashImage: () => '/images/unsplash',
        moveBrandfetchImage: () => '/images/brandfetch',
        useImage: (imageId: string) => `/images/${imageId}/use`,
        uploadImages: () => '/images',
    },
    PATCH: {
        updateFolder: (folderId: string) => `/folders/${folderId}`,
    },
    DELETE: {
        deleteFolder: (folderId: string) => `/folders/${folderId}`,
        deleteImage: (imageId: string) => `/images/${imageId}`,
        removeImageFromFolder: (imageId: string, folderId: string) =>
            `/images/${imageId}/folder/${folderId}`,
    },
} as const;

export const QUERY_KEYS = {
    folders: () => ['folders', 'list'],
    allFolderImages: () => ['folder', 'images', 'list'],
    folderImages: (folderId: string, searchTerm?: string) => {
        const key = ['folder', 'images', 'list', folderId];

        if (searchTerm) {
            key.push(searchTerm);
        }

        return key;
    },
    unsplashImages: (searchTerm: string) => ['unsplashImages', searchTerm],
    brands: (searchTerm: string) => ['brands', 'list', searchTerm],
    brandImages: (brandDomain: string) => ['brandDetails', brandDomain],
} as const;

export const MUTATION_KEYS = {
    createFolder: () => ['folder', 'create'],
    updateFolder: (folderId: string) => ['folder', 'update', folderId],
    deleteFolder: (folderId: string) => ['folder', 'delete', folderId],
    deleteImage: (folderId: string, imageId: string) => ['image', 'delete', imageId, folderId],
    moveImage: () => ['image', 'move'],
    useImage: () => ['image', 'use'],
    trackImageDownload: () => ['image', 'track-download'],
    uploadImages: () => ['image', 'upload'],
} as const;

export const BRANDFETCH_PLACEHOLDER_LOGOS = [
    {
        name: 'Google',
        url: 'https://cdn.brandfetch.io/id6O2oGzv-/theme/dark/logo.svg',
    },
    {
        name: 'Nike',
        url: 'https://cdn.brandfetch.io/id_0dwKPKT/theme/dark/logo.svg',
    },
    {
        name: 'Coca Cola',
        url: 'https://cdn.brandfetch.io/idftrGPfCd/theme/dark/logo.svg',
    },
    {
        name: 'Apple',
        url: 'https://cdn.brandfetch.io/idnrCPuv87/theme/dark/logo.svg',
    },
    {
        name: 'Lego',
        url: 'https://cdn.brandfetch.io/idmoRM4Wwz/w/400/h/400/theme/dark/icon.jpeg',
    },
    {
        name: 'Braun',
        url: 'https://cdn.brandfetch.io/idrq7oeNQD/w/820/h/347/theme/dark/logo.png',
    },
];
