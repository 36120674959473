import { BillingTimeframe } from '@/app/billing/types';

export const NAME = 'billing';

export const THROTTLE_DELAY = 300;

export const TIMEFRAME_SWITCH_MAP = {
    [BillingTimeframe.yearly]: BillingTimeframe.monthly,
    [BillingTimeframe.monthly]: BillingTimeframe.yearly,
};

export const PLAN_OVERVIEW_ENTRY_ID = '2aYCajjakdvmgTlhRIMl7Y';

export const ADDON_CAMPAIGNS_ID = 'ps-prepaid-campaigns';
export const ADDON_VISITORS_ID = 'ps-prepaid-visitors';
export const ADDON_DOMAINS_ID = 'ps-prepaid-domains';

export const ADDON_LABEL_MAP = {
    [ADDON_VISITORS_ID]: 'visitors',
    [ADDON_CAMPAIGNS_ID]: 'funnels',
    [ADDON_DOMAINS_ID]: 'domains',
};

export const TIMEFRAME_SUFFIX_MAP = {
    [BillingTimeframe.yearly]: '12-month',
    [BillingTimeframe.monthly]: '1-month',
};

export const TERM_OPTIONS = [
    {
        duration: 3,
        saving: 0,
    },
    {
        duration: 6,
        saving: 15,
    },
    {
        duration: 12,
        saving: 30,
    },
];

export const CONTINGENT_OPTIONS = {
    [ADDON_VISITORS_ID]: {
        min: 30000,
        steps: 10000,
        max: 200000,
        id: ADDON_VISITORS_ID,
    },
    [ADDON_CAMPAIGNS_ID]: {
        min: 30,
        steps: 10,
        max: 200,
        id: ADDON_CAMPAIGNS_ID,
    },
    [ADDON_DOMAINS_ID]: {
        min: 10,
        steps: 10,
        max: 200,
        id: ADDON_DOMAINS_ID,
    },
};

export const FEATURE_IDS = {
    campaigns: 'campaigns',
    visitors: 'visitors',
    domains: 'domains',
    subDomains: 'subDomains',
    customDomains: 'customDomains',
    customDomain: 'customDomain',
    integrationsFacebook: 'integrationsFacebook',
    integrationsAll: 'integrationsAll',
    multipleNotifications: 'multipleNotifications',
    favicon: 'favicon',
    leadExport: 'leadExport',
    badge: 'badge',
    branding: 'branding',
    dynamicLinking: 'dynamicLinking',
    supportPrimary: 'supportPrimary',
    supportSecondary: 'supportSecondary',
    personalization: 'personalisation',
    splitTesting: 'splitTesting',
    dynamicHeadlines: 'dynamicHeadlines',
    crm: 'crm',
    internal: 'internal',
    workspacesBeta: 'workspacesBeta',
    aiText: 'aiText',
    phoneFormatValidation: 'phoneFormatValidation',
    customFonts: 'customFonts',
    funnelEmbed: 'funnelEmbed',
    funnelEmbedAlpha: 'funnelEmbedAlpha',
    customHtmlEmbedBlock: 'customHtmlEmbedBlock',
    customHtmlEmbedBlockNativeEmbed: 'customHtmlEmbedBlockNativeEmbed',
    betaTester: 'betaTester',
    emailAutomation: 'emailAutomation',
    paymentBlock: 'paymentBlock',
} as const;

// These features can also be overridden production via the localStorage. (staging + dev too)
//
// FYI: URL parameter overrides are independent of this list here and
// is only possible on staging and locally.
export const OVERRIDABLE_FEATURE_IDS = [
    FEATURE_IDS.funnelEmbed,
    FEATURE_IDS.customHtmlEmbedBlock,
    FEATURE_IDS.emailAutomation,

    // It's safe to let this be overridden (also in production), because the hook
    // "useCustomHtmlBlockFlags" also checks for the "internal" flag to be present.
    FEATURE_IDS.customHtmlEmbedBlockNativeEmbed,
] satisfies (typeof FEATURE_IDS)[keyof typeof FEATURE_IDS][];

// Translation keys for Plan page
export const FEATURE_KEYS = {
    [FEATURE_IDS.campaigns]: 'feature funnels',
    [FEATURE_IDS.visitors]: 'feature sessions',
    [FEATURE_IDS.domains]: 'feature custom domains',
    [FEATURE_IDS.subDomains]: 'feature custom domain',
    [FEATURE_IDS.customDomain]: 'feature custom domain',
    [FEATURE_IDS.customDomains]: 'feature custom domains',
    [FEATURE_IDS.integrationsFacebook]: 'feature facebook pixel',
    [FEATURE_IDS.integrationsAll]: 'feature direct integrations',
    [FEATURE_IDS.workspacesBeta]: 'feature workspaces',
    [FEATURE_IDS.multipleNotifications]: 'feature multiple leads',
    [FEATURE_IDS.favicon]: 'feature favicon',
    [FEATURE_IDS.leadExport]: 'feature lead export',
    [FEATURE_IDS.badge]: 'feature remove ad',
    [FEATURE_IDS.branding]: 'feature remove ad',
    [FEATURE_IDS.supportSecondary]: 'feature ticket support',
    [FEATURE_IDS.dynamicLinking]: 'feature dynamic linking',
    [FEATURE_IDS.personalization]: 'feature personalization',
    [FEATURE_IDS.splitTesting]: 'feature split testing',
    [FEATURE_IDS.dynamicHeadlines]: 'feature dynamic headlines',
    [FEATURE_IDS.customFonts]: 'feature custom fonts',
    [FEATURE_IDS.customHtmlEmbedBlock]: 'feature custom html embed block',
};

export const PLAN_RANKING = {
    'ps-pro-1-month': 1,
    'ps-pro-12-month': 2,
    'ps-business-1-month': 3,
    'ps-business-12-month': 4,
    'ps-agency-1-month': 5,
    'ps-agency-12-month': 6,
    'ps-individual': 7,
};

// Subscription status
export const SUB_NON_RENEWING = 'non_renewing';
export const SUB_CANCELLED = 'cancelled';
export const SUB_IN_TRIAL = 'in_trial';

// Intercom Button IDs
export const INTERCOM_BUTTON_IDS = {
    CONFIRM_REACTIVATION: 'confirm-reactivation',
    CONFIRM_UPGRADE: 'confirm-upgrade',
    REQUEST_DOWNGRADE: 'request-downgrade',
    CHOOSE_PLAN: 'choose-plan',
};
