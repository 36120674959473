import { FEATURE_IDS } from '@/app/billing/constants';

import { useFeatureAvailability } from '@/app/company/hooks/useFeatureAvailability';

export const useCustomHtmlBlockFlags = () => {
    return {
        canAddHtmlEmbedBlocks: useFeatureAvailability(FEATURE_IDS.customHtmlEmbedBlock),
        canEnableNativeEmbedding: useFeatureAvailability(
            FEATURE_IDS.customHtmlEmbedBlockNativeEmbed,
        ),
    };
};
