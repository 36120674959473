import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';

import Box from '../_wrapper/Box';

import type { DefaultBlockComponentProps } from '../../types';

interface LogosProps {
    images: string[];
    isGrayscale: boolean;
}

export interface ComponentProps extends DefaultBlockComponentProps, LogosProps {}

const Component = ({ artBoardIndex, blockId, box }: ComponentProps) => {
    const { isInColumn } = useLayoutInfo(blockId);

    return (
        <Box
            box={{
                ...getBoxAttributes({ box, isInColumn }),
            }}
            artBoardIndex={artBoardIndex}
        >
            {/* TODO: Actual implementation (FUN-1038) */}
            Reviews
        </Box>
    );
};

Component.displayName = 'Reviews';

export default Component;
