import { NAME } from '@/app/imageLibrary/constants';
import { MODAL_OPTIONS } from '@/app/modals/constants';

import { FolderIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';

import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { useAppDispatch } from '@/core/redux/hooks';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import { Tooltip } from '@/ui/components/Tooltip';

interface Props {
    currentSrc: string;
    onChange: (value: string) => void;
}

export const ImageLibraryButton = ({ currentSrc, onChange }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();

    const handleClick = useCallback(() => {
        dispatch(
            showModal(
                Modals.IMAGE_LIBRARY,
                { currentSrc, onChange },
                MODAL_OPTIONS[Modals.IMAGE_LIBRARY],
            ),
        );
    }, [dispatch, currentSrc, onChange]);

    return (
        <FieldContainer>
            <Tooltip content={t('browse-library')}>
                <button
                    className="bump flex h-12 w-full items-center justify-center rounded-lg bg-gray-100 px-4 text-gray-400 hover:text-gray-600"
                    type="button"
                    onClick={handleClick}
                >
                    <FolderIcon className="size-5" />
                </button>
            </Tooltip>
        </FieldContainer>
    );
};
