import { i18n } from 'next-i18next';

import { DEFAULT_BG_IMAGE_SRC } from '../../editor/constants';
import { NAME } from '../constants';

import type { BoxProps } from '../../blocks/types';
import type {
    MessageBodyImageItem,
    MessageBodyDividerItem,
    MessageBodyTextItem,
    MessageBodyGraphicItem,
} from '../types';

const defaultBox: BoxProps = {
    bottom: 'small',
    top: 'small',
    right: 'xLarge',
    left: 'xLarge',
    backgroundColor: 'transparent',
};

export const initTipTapBlock = (): MessageBodyTextItem => {
    const t = i18n?.getFixedT(null, NAME);

    return {
        type: 'text',
        wysiwyg: {
            type: 'doc',
            content: [
                {
                    type: 'paragraph',
                    content: [
                        {
                            type: 'text',
                            text: t?.('email-notification.body.default-text'),
                            marks: [
                                {
                                    attrs: {
                                        fontSize: '14px',
                                        color: 'black',
                                    },
                                    type: 'textStyle',
                                },
                            ],
                        },
                    ],
                    attrs: {
                        class: '',
                    },
                },
            ],
        },
        box: defaultBox,
        align: 'left',
    };
};

export const initGraphicBlock = (): MessageBodyGraphicItem => {
    return {
        type: 'graphic',
        box: defaultBox,
        color: 'red',
        size: 'small',
        icon: '2760',
        iconName: 'expand-arrow',
        platform: 'ios7',
    };
};

export const initImageBlock = (): MessageBodyImageItem => {
    return {
        type: 'image',
        box: defaultBox,
        framed: false,
        href: 'https://perspective.co',
        size: 'large',
        src: DEFAULT_BG_IMAGE_SRC,
    };
};

export const initSpacerBlock = (): MessageBodyDividerItem => {
    return {
        type: 'divider',
        box: defaultBox,
        lineStyle: 'solid',
        lineColor: 'black',
    };
};

export const createMessageBodyBlock = (sectionName: string) => {
    const isTextBlock = sectionName === '1b-text';
    const isSpacerBlock = sectionName === '1b-divider';
    const isImageBlock = sectionName === '1b-image';
    const isGraphicBlock = sectionName === '1b-graphic';

    switch (true) {
        case isTextBlock:
            return initTipTapBlock();
        case isSpacerBlock:
            return initSpacerBlock();
        case isImageBlock:
            return initImageBlock();
        case isGraphicBlock:
            return initGraphicBlock();
        // @todo(AD): tbd
        default:
            return initTipTapBlock();
    }
};
