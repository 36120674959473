import { EditorEngineDropPosition } from '@/app/editor/engine/core/types';
import { EditorEngineOrientation } from '@/app/editor/engine/core/types/util';
import { cn } from '@/utils/cn';

interface Props {
    /**
     * The orientation of the drop preview.
     */
    orientation: EditorEngineOrientation;
    /**
     * The position of the drop preview.
     */
    position: EditorEngineDropPosition;
    /**
     * Whether the drop preview is visible.
     */
    visible: boolean;
}

/**
 * A drop preview that shows where a dragged node will be dropped.
 */
export const EditorEngineDropPreview = ({ orientation, position, visible }: Props) => {
    if (position === EditorEngineDropPosition.Within) {
        // Must be rendered by the component itself
        return null;
    }

    return (
        <div
            className={cn(
                'pointer-events-none absolute z-10 flex items-center justify-center opacity-0',
                {
                    'h-full w-0.5': orientation === EditorEngineOrientation.Horizontal,
                    'h-0.5 w-full': orientation === EditorEngineOrientation.Vertical,
                    'left-0 top-0 justify-start': [
                        EditorEngineDropPosition.Left,
                        EditorEngineDropPosition.Top,
                    ].includes(position),
                    'bottom-0 right-0 justify-end': [
                        EditorEngineDropPosition.Right,
                        EditorEngineDropPosition.Bottom,
                    ].includes(position),
                    'opacity-100': visible,
                },
            )}
        >
            <div
                className={cn('rounded-full bg-neutral-800', {
                    'h-full w-0.5': orientation === EditorEngineOrientation.Horizontal,
                    'h-0.5 w-full': orientation === EditorEngineOrientation.Vertical,
                })}
            />
        </div>
    );
};
