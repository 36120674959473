import { NAME, TESTIMONIAL_CROP_ASPECT_SIZE } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { change, Field, getFormValues, reduxForm } from 'redux-form';

import {
    deserializeTestimonial,
    getTestimonialImageUrl,
    serializeTestimonial,
} from '@/app/editor/blocks/components/TestimonialSlider/helper';
import { useFrameSubmit } from '@/app/editor/blocks/hooks/useFrameSubmit';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getActiveIndexBySliderId } from '@/app/editor/blocks/models/slider';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import CropButton from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/CropButton';
import FrameToggle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/FrameToggle';
import IconToggleButton from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/IconToggleButton';
import Slides from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Slides';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import { getThemeColor } from '@/app/editor/themes/helpers';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import { setSelectedSize } from '../../models/mediaSize';

import type { BlockResource, DefaultEditFormProps, Testimonial } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({ blockId, handleSubmit, activeTheme }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(NAME);
    const formValues = useAppSelector((state) => getFormValues(blockId)(state)) as BlockResource;
    const activeImageIndex = useAppSelector((state) => getActiveIndexBySliderId(state, blockId));
    const { isInColumn } = useLayoutInfo(blockId);

    useEffect(() => {
        dispatch(setSelectedSize(TESTIMONIAL_CROP_ASPECT_SIZE));
    }, [dispatch]);

    const handleCropSave = (src: string) => {
        dispatch(
            change(blockId, `attributes.content.testimonials[${activeImageIndex}].image`, src),
        );
        setTimeout(handleSubmit);
    };

    const onFrameSubmit = useFrameSubmit(blockId, handleSubmit);

    const createTestimonial = (image: string): Testimonial => ({
        image: image,
        headline: t('default-testimonial-headline'),
        subHeadline: t('default-testimonial-subheadline'),
        mode: 'light',
    });

    return (
        <>
            <div className="grid w-full grid-cols-3 gap-x-2">
                <CropButton
                    src={formValues?.attributes?.content?.testimonials[activeImageIndex]?.image}
                    onSave={handleCropSave}
                    size={TESTIMONIAL_CROP_ASPECT_SIZE}
                />

                <Field
                    name={`attributes.content.testimonials[${activeImageIndex}].mode`}
                    component={IconToggleButton}
                    submit={onFrameSubmit}
                    icon="darkMode"
                    tooltip="Dark mode"
                    toggleType="darkMode"
                />

                {!isInColumn && (
                    <>
                        <Field
                            name="attributes.content.framed"
                            component={FrameToggle}
                            submit={onFrameSubmit}
                        />

                        <Field
                            name="attributes.content.box.backgroundColor"
                            component={Color}
                            expand="right"
                            submit={handleSubmit}
                            themeColor={getThemeColor(activeTheme, 'backgroundColor')}
                            tooltip={t('background-color')}
                        />
                    </>
                )}
            </div>

            <Subheading text={t('slides')} />

            <Field
                name="attributes.content.testimonials"
                component={Slides}
                blockId={blockId}
                submit={handleSubmit}
                serializeItem={serializeTestimonial}
                deserializeItem={deserializeTestimonial}
                getImageUrl={getTestimonialImageUrl}
                createNewItem={createTestimonial}
            />
        </>
    );
};

Form.displayName = 'TestimonialSliderEditForm';

export default reduxForm({})(Form);
