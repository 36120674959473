import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getCampaignQueryString, getCampaignWithPagination } from '@/app/campaigns/helpers';
import {
    getIsGlobalSearch,
    getSearch,
    setFilter,
    setPagination,
} from '@/app/campaigns/models/overview';
import { CampaignFilter } from '@/app/campaigns/types';
import { fetchHeaderAndFooter } from '@/app/editor/blocks/models/blocks';
import { fetchTrackingProperties } from '@/app/editor/blocks/models/personalization';
import { fetchPageMapping } from '@/app/editor/pages/models/pageMapping';
import { fetchPages, preloadFirstPage } from '@/app/editor/pages/models/pages';
import { fetchResultMapping } from '@/app/editor/pages/models/resultMapping';
import { setActiveThemeAndFetch } from '@/app/editor/themes/models/themes';
import { getIsCrmUser } from '@/app/user/helper';
import { getUser } from '@/app/user/models/user';
import { getActiveWorkspaceId, getWorkspaces } from '@/app/workspaces/models/workspaces';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse, resourceArrayToObject } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import { NAME } from '../constants';

import type { CampaignObj, CampaignResource } from '@/app/campaigns/types';
import type { Pagination, ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosResponse } from 'axios';
import type { Maybe } from 'types/generic';

interface CampaignOptions {
    crmCampaignsOnly?: boolean;
    workspaceIds?: string;
}

interface State {
    campaigns: CampaignObj;
    fetchingList: boolean;
    searching: boolean;
    didFetchCampaigns: boolean;
    preloadedCampaigns: string[];
}

const initialState: State = {
    campaigns: EMPTY_OBJECT,
    fetchingList: false,
    searching: false,
    didFetchCampaigns: false,
    preloadedCampaigns: EMPTY_ARRAY,
};

export const campaignsSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setCampaigns(state, action: PayloadAction<CampaignObj>) {
            state.campaigns = action.payload;
        },
        addSetCampaigns(state, action: PayloadAction<CampaignObj>) {
            state.campaigns = {
                ...state.campaigns,
                ...action.payload,
            };
        },
        setFetchingList(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                fetchingList: action.payload,
            };
        },
        setDidFetchCampaigns(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                didFetchCampaigns: action.payload,
            };
        },
        setPreloadedCampaigns(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                preloadedCampaigns: [...state.preloadedCampaigns, ...action.payload],
            };
        },
        setSearching(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                searching: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setCampaigns,
    addSetCampaigns,
    setFetchingList,
    setDidFetchCampaigns,
    setPreloadedCampaigns,
    setSearching,
    reset,
} = campaignsSlice.actions;

// === Selectors ======

export const getCampaigns = (state: AppState): CampaignObj =>
    state[NAME]?.campaignsReducer?.campaigns || EMPTY_OBJECT;

export const getCampaignsAsArray = createSelector([getCampaigns], (campaigns) => {
    return Object.keys(campaigns).map((campaignId: string) => campaigns[campaignId]);
});

export const getFetchingList = (state: AppState): boolean =>
    state[NAME]?.campaignsReducer?.fetchingList;

export const getDidFetchCampaigns = (state: AppState): boolean =>
    state[NAME]?.campaignsReducer?.didFetchCampaigns;

export const getCampaignById = (state: AppState, campaignId: string): Maybe<CampaignResource> =>
    state[NAME]?.campaignsReducer?.campaigns[campaignId];

export const getActiveCampaign = (state: AppState) => {
    const campaignId = getCampaignIdFromRouter();

    // This happens a lot when navigating away from any funnel route and the state is reset, causing this selector for fire although the route has no campaignId anymore
    // Sentry issue: https://perspective-software.sentry.io/issues/4979868450/?project=4504762775830528&query=is:unresolved&statsPeriod=30d&stream_index=1
    if (!campaignId) {
        return undefined;
    }

    return getCampaignById(state, campaignId);
};

export const getPreloadedCampaigns = (state: AppState): string[] =>
    state[NAME]?.campaignsReducer?.preloadedCampaigns;

export const getSearching = (state: AppState): boolean => state[NAME]?.campaignsReducer?.searching;

// === Thunks ======

export const getCampaignRequest =
    ({
        workspaceIds,
        nextPageUrl,
        crmCampaignsOnly,
        queryOptions,
    }: {
        workspaceIds?: string;
        nextPageUrl?: string;
        crmCampaignsOnly?: boolean;
        queryOptions?: {
            search?: string;
            filter?: CampaignFilter;
        };
    } = {}): AppThunk<
        Promise<AxiosResponse<ResponseData<CampaignResource[], null, Pagination>> | void>
    > =>
    async (dispatch, getState) => {
        try {
            // We currently use this function to fetch funnels/campaigns (Search, Settings/Funnels, Funnels page, etc.) in multiple different contexts which causes the loading spinner to flicker
            // dispatch(setFetchingList(true));

            const url = `/campaigns/filter?${getCampaignQueryString(
                { workspaceIds, queryOptions, crmCampaignsOnly },
                getState(),
            )}`;

            const response = await apiGet<ResponseData<CampaignResource[], null, Pagination>>(
                nextPageUrl ?? url,
            );

            return response;
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching campaigns failed:' });
        } finally {
            // dispatch(setFetchingList(false));
        }

        return;
    };

export const dataFetchCampaigns =
    ({ crmCampaignsOnly, workspaceIds }: CampaignOptions = {}): AppThunk =>
    async (dispatch, getState) => {
        const response = await dispatch(
            getCampaignRequest({
                workspaceIds: workspaceIds ?? getActiveWorkspaceId(getState()),
                crmCampaignsOnly,
            }),
        );

        if (!response) {
            return;
        }

        const { pagination, campaigns } = getCampaignWithPagination(response);

        if (pagination) {
            dispatch(setPagination(pagination));
        }

        if (campaigns) {
            dispatch(setCampaigns(resourceArrayToObject(campaigns)));
        }
    };

// Fetch list of campaigns
export const fetchCampaigns =
    (options: CampaignOptions = {}): AppThunk =>
    async (dispatch) => {
        try {
            await dispatch(dataFetchCampaigns(options));

            // used to check if campaigns has been initially fetched or not
            // e.g. in duplicate page to funnel modal
            dispatch(setDidFetchCampaigns(true));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching campaign list failed:' });
        }
    };

// Search
export const searchCampaigns =
    (options?: { crmCampaignsOnly?: boolean }): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const searchTerm = getSearch(state);
        const isGlobalSearch = getIsGlobalSearch(state);

        const activeWorkspaceId = getActiveWorkspaceId(state);
        const allWorkspaceIds = Object.keys(getWorkspaces(state));

        const workspaceIds = isGlobalSearch ? allWorkspaceIds.join(',') : activeWorkspaceId;

        if (!workspaceIds?.length) {
            return;
        }

        if (searchTerm) {
            dispatch(setSearching(true));
            await dispatch(setFilter(CampaignFilter.search));
        }

        await dispatch(dataFetchCampaigns({ ...options, workspaceIds: workspaceIds }));

        dispatch(setSearching(false));
    };

// Plain search that just adds search results to campaigns
export const searchAndAddCampaigns =
    (query: string): AppThunk =>
    async (dispatch) => {
        if (!query) {
            return;
        }

        dispatch(setSearching(true));

        try {
            const response = await apiGet<ResponseData<CampaignResource[]>>(
                `/campaigns/filter?search=${query}`,
            );
            const asObject = resourceArrayToObject(getDataFromResponse(response));

            dispatch(addSetCampaigns(asObject));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching campaigns failed:' });
        } finally {
            dispatch(setSearching(false));
        }
    };

// Fetch single campaign
export const fetchCampaign =
    (campaignId: string): AppThunk<Promise<CampaignResource | undefined>> =>
    async (dispatch, getState) => {
        if (!campaignId) {
            return Promise.reject('No campaignId provided');
        }

        const user = getUser(getState());
        const isCrmUser = getIsCrmUser(user);

        try {
            const response = await apiGet<ResponseData<CampaignResource>>(
                `/campaigns/${campaignId}`,
            );

            // set campaign in redux store
            const campaign: CampaignResource = getDataFromResponse(response);
            dispatch(addSetCampaigns({ [campaign.id]: campaign }));

            if (!isCrmUser) {
                // set resultMapping in redux store
                const resultMappingId = campaign.relationships?.resultMapping?.data?.id;
                dispatch(fetchResultMapping(resultMappingId));

                // set pageMapping in redux store
                dispatch(fetchPageMapping(campaignId));

                // fetch personalization tracking properties
                dispatch(fetchTrackingProperties(campaignId));
            }

            return campaign;
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: `fetching campaign ${campaignId} failed::`,
            });
        }
    };

export const lazyFetchCampaign =
    (campaignId: string): AppThunk<Promise<CampaignResource | undefined>> =>
    async (dispatch, getState) => {
        if (!campaignId) {
            return Promise.reject('No campaignId provided');
        }

        try {
            const state = getState();
            let campaign = getCampaignById(state, campaignId);

            if (!campaign) {
                campaign = await dispatch(fetchCampaign(campaignId));
            } else {
                dispatch(fetchCampaign(campaignId));
            }

            const themeId = campaign?.relationships?.theme?.data?.id;

            await dispatch(setActiveThemeAndFetch(themeId));

            return campaign;
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching active campaign failed:' });
        }
    };

// Preload campaign pages/components/mapping on hover
export const preloadCampaign =
    (campaignId: string): AppThunk =>
    (dispatch) => {
        try {
            dispatch(preloadFirstPage(campaignId));
            dispatch(fetchPages(campaignId));
            dispatch(fetchPageMapping(campaignId));
            dispatch(fetchHeaderAndFooter());

            dispatch(setPreloadedCampaigns([campaignId]));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'preloading campaign failed:' });
        }
    };

export default campaignsSlice.reducer;
