import { FIXED_FOLDER_IDS } from '@/app/imageLibrary/constants';

import type { State } from '@/app/imageLibrary/store/index';
import type { StateCreator } from 'zustand';

export interface FolderSlice {
    activeFolderId: string;

    setActiveFolderId: (activeFolderId: string) => void;
}

export const createFolderSlice: StateCreator<
    State,
    [['zustand/devtools', never]],
    [],
    FolderSlice
> = (set) => ({
    activeFolderId: FIXED_FOLDER_IDS.all,

    setActiveFolderId: (activeFolderId) => set({ activeFolderId }),
});
