import type { LOLI_FEATURE_FLAG_NAME } from '@/core/loli-feature-flags/constants';

import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import {
    getStoredFeatureFlagOverwrites,
    syncStoreChangesToIntercom,
    syncStoreChangesToLocalStorage,
} from '@/core/loli-feature-flags/utils';
import { isProductionEnv } from '@/utils/environments';

import type {
    AllFeatureFlags,
    FeatureFlagValue,
    LoliFeatureFlagsStore,
} from '@/core/loli-feature-flags/types';

export const useLoliFeatureFlagsStore = create<LoliFeatureFlagsStore>((set) => ({
    allFeatureFlagsWithoutOverwrites: {},
    featureFlagOverwrites: getStoredFeatureFlagOverwrites(),

    setFeatureFlagOverwrite: (featureFlagName, overwriteValue) => {
        set((old) => ({
            featureFlagOverwrites: {
                ...old.featureFlagOverwrites,
                [featureFlagName]: overwriteValue,
            },
        }));
    },
    deleteFeatureFlagOverwrite: (featureFlagName) => {
        set((old) => {
            const updatedOverwrites = { ...old.featureFlagOverwrites };
            delete updatedOverwrites[featureFlagName];

            return { featureFlagOverwrites: updatedOverwrites };
        });
    },
}));

// Store any changes in localStorage and Intercom
syncStoreChangesToLocalStorage();
syncStoreChangesToIntercom();

export const useAllLoliFeatureFlags = () => {
    const { featureFlagOverwrites, allFeatureFlagsWithoutOverwrites } = useLoliFeatureFlagsStore(
        useShallow((state) => ({
            featureFlagOverwrites: state.featureFlagOverwrites,
            allFeatureFlagsWithoutOverwrites: state.allFeatureFlagsWithoutOverwrites,
        })),
    );

    if (isProductionEnv()) {
        return allFeatureFlagsWithoutOverwrites;
    }

    return { ...allFeatureFlagsWithoutOverwrites, ...featureFlagOverwrites };
};

export const useLoliFeatureFlag = (featureFlagName: LOLI_FEATURE_FLAG_NAME): FeatureFlagValue => {
    return useAllLoliFeatureFlags()[featureFlagName];
};

export const getAllLoliFeatureFlags = (): AllFeatureFlags => {
    const { featureFlagOverwrites, allFeatureFlagsWithoutOverwrites } =
        useLoliFeatureFlagsStore.getState();

    if (isProductionEnv()) {
        return allFeatureFlagsWithoutOverwrites;
    }

    return { ...allFeatureFlagsWithoutOverwrites, ...featureFlagOverwrites };
};

export const getLoliFeatureFlag = (featureFlagName: LOLI_FEATURE_FLAG_NAME): FeatureFlagValue => {
    return getAllLoliFeatureFlags()[featureFlagName];
};
