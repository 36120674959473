import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { guid } from '@/app/editor/engine/utils/guid';

/**
 * This hook is used to manage a queue of "items" that must be processed.
 * It's important that:
 *
 * - no more than one item is processed at a time
 * - the processing is strictly sequential (the next item cannot be processed
 * before the previous one has finished completely)
 * - the same item must not be processed more than once
 * The complexity of this hook comes mostly from these rules.
 *
 * It returns state to determine whether a failed item exists, and a function
 * to retry the currently failed item.
 * If there is a failed item, processing will stop.
 */
export const useProcessingQueue = <TItem>({
    identify,
    processor,
}: {
    /**
     * This function is used to identify an item.
     * The queue does not know which properties of the item are unique, so it
     * relies on this function to determine if an item is already in the queue.
     */
    identify: (item: TItem) => string;
    /**
     * This function is called to process an item.
     * It receives the item to process and a function to call if processing fails.
     * When calling the fail function, the item will be considered failed and
     * all processing will stop, until the item is retried.
     */
    processor: (input: { item: TItem; onFail: (error: Error) => void }) => Promise<void>;
}) => {
    const [queue, setQueue] = useState<{ id: string; item: TItem }[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [failedItem, setFailedItem] = useState<{ id: string; item: TItem } | null>(null);
    const currentProcessingItemId = useRef<string | null>(null);
    const processedItemsIds = useRef<string[]>([]);
    const failedItemRef = useRef<string>(null);

    const addItemToQueue = useCallback(
        (update: TItem) => {
            setQueue((prevQueue) => [...prevQueue, { id: guid(), item: update }]);
        },
        [setQueue],
    );

    const isInQueue = useCallback(
        (identifier: string) => {
            return queue.some((queueItem) => identify(queueItem.item) === identifier);
        },
        [queue, identify],
    );

    const onFail = useCallback(() => {
        setFailedItem(queue[0]);
        failedItemRef.current = queue[0].id;
    }, [queue]);

    const clearFailedItem = useCallback(() => {
        setFailedItem(null);
        failedItemRef.current = null;
    }, []);

    const processItem = useCallback(
        async (item: { item: TItem; id: string }) => {
            try {
                clearFailedItem();
                await processor({
                    item: item.item,
                    onFail,
                });
            } catch (error) {
                onFail();
            } finally {
                if (!failedItemRef.current) {
                    setQueue((prevQueue) => {
                        return prevQueue.filter((queueItem) => {
                            return queueItem.id !== item.id;
                        });
                    });
                }

                setIsProcessing(false);
                currentProcessingItemId.current = null;
            }
        },
        [clearFailedItem, processor, onFail],
    );

    const retryFailedItem = useCallback(async () => {
        if (failedItem !== null) {
            await processItem(failedItem);
        }
    }, [failedItem, processItem]);

    useEffect(() => {
        const process = async () => {
            const item = queue[0];

            if (
                failedItem !== null ||
                isProcessing ||
                !item ||
                currentProcessingItemId.current ||
                processedItemsIds.current.includes(item.id)
            ) {
                return;
            }

            processedItemsIds.current.push(item.id);

            currentProcessingItemId.current = item.id;
            setIsProcessing(true);

            await processItem(item);
        };

        void process();
    }, [
        onFail,
        failedItem,
        processor,
        queue,
        isProcessing,
        setQueue,
        setIsProcessing,
        processItem,
    ]);

    return useMemo(() => {
        return {
            /**
             * The queue of items to process.
             */
            queue,
            /**
             * The function to update the queue.
             */
            setQueue,
            /**
             * Whether an item is currently being processed.
             */
            isProcessing,
            /**
             * Set whether an item is currently being processed.
             */
            setIsProcessing,
            /**
             * Add an item to the queue.
             */
            addItemToQueue,
            /**
             * Check if an item is in the queue.
             */
            isInQueue,
            /**
             * The currently failed item.
             */
            failedItem,
            /**
             * Set the currently failed item.
             */
            setFailedItem,
            /**
             * Retry the currently failed item.
             */
            retryFailedItem,
            /**
             * Whether a failed item is present and can be retried.
             */
            canRetryFailedItem: failedItem !== null,
        };
    }, [queue, isProcessing, failedItem, addItemToQueue, isInQueue, retryFailedItem]);
};
