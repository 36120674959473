import { isDropNoOp } from '@/app/editor/engine/core/utils/dragAndDrop/isDropNoOp';

import type { EditorEngineDefaultTypeInput } from '../../types';
import type { EditorEngineHistory } from '../../types';
import type { EditorEngineDropCandidate } from '@/app/editor/engine/core/types';
import type { DndContextProps } from '@dnd-kit/core';
import type { Dispatch, SetStateAction } from 'react';

/**
 * Returns a function that handles the end of a drag operation.
 */
export const getOnDragEnd = <TEditorEngineTypeInput extends EditorEngineDefaultTypeInput>({
    setDraggedNodeId,
    dropCandidate,
    setDropCandidate,
    history,
    actions,
    nodeManager,
}: {
    /**
     * A function for setting the ID of the node that is being dragged.
     */
    setDraggedNodeId: (nodeId: string) => void;
    /**
     * The drop candidate.
     */
    dropCandidate: EditorEngineDropCandidate<TEditorEngineTypeInput> | null;
    /**
     * A function for setting the drop candidate.
     */
    setDropCandidate: Dispatch<
        SetStateAction<EditorEngineDropCandidate<TEditorEngineTypeInput> | null>
    >;
    /**
     * The history that contains the user's actions.
     */
    history: EditorEngineHistory;
    /**
     * The actions that the editor engine can perform.
     */
    actions: TEditorEngineTypeInput['Actions'];
    /**
     * The node manager.
     */
    nodeManager: TEditorEngineTypeInput['NodeManager'];
}) => {
    return (() => {
        if (
            dropCandidate &&
            !isDropNoOp({
                preview: dropCandidate.preview,
                nodeManager,
            })
        ) {
            dropCandidate.executionConfiguration.handler({
                history,
                actions,
            });
        }

        setDropCandidate(null);
        setDraggedNodeId(null);
    }) satisfies DndContextProps['onDragEnd'];
};
