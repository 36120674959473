import escape from 'lodash/escape';
import unescape from 'lodash/unescape';

import { inputTypeOptions } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/InputType/inputTypeOptions';

import googleMaps from './assets/google-maps.svg';
import kununuStars from './assets/kununu-stars.svg';
import provenexpertStars from './assets/provenexpert-stars.svg';
import trustpilotStars from './assets/trustpilot-stars.svg';

import type { TrackingProperty } from '@/app/editor/blocks/types';
import type { InputOption } from '@/app/editor/editor/types';

export const normalizeSnippet = (snippet: string) => {
    let normalizedSnippet = snippet;

    normalizedSnippet = normalizedSnippet.replaceAll('“', '"');
    normalizedSnippet = normalizedSnippet.replaceAll('”', '"');

    normalizedSnippet = escape(normalizedSnippet);

    return normalizedSnippet;
};

export const formatSnippet = (snippet: string) => {
    let formattedSnippet = unescape(snippet);

    formattedSnippet = formattedSnippet.replaceAll('“', '"');
    formattedSnippet = formattedSnippet.replaceAll('”', '"');

    return formattedSnippet;
};

export const removeTextNodes = (container: HTMLElement) => {
    container.childNodes.forEach((node) => {
        if (node.nodeType === 3) {
            node.remove();
        }
    });
};

export const providerLogoMap = {
    kununu: kununuStars,
    provenexpert: provenexpertStars,
    trustpilot: trustpilotStars,
    'google-maps': googleMaps,
};

export const MISSING_SCRIPT = 'missing-script';
export const INVALID_SCRIPT = 'invalid-script';
export const MALFORMED_SCRIPT = 'malformed-script';
export const STICKY_SCRIPT = 'sticky-script';
export const UNKNOWN_ERROR = 'unknown-error';

// This removes the default width/height settings of google-maps embed snippets
// and adds a generic 4/3 aspect ratio for the embed
export const sanitizeGoogleMapsSnippet = (snippet: string) => {
    let sanitizedSnippet = snippet;

    sanitizedSnippet = sanitizedSnippet.replace('width="400"', 'width="100%"');
    sanitizedSnippet = sanitizedSnippet.replace('height="300"', '');

    sanitizedSnippet = sanitizedSnippet.replace('width="600"', 'width="100%"');
    sanitizedSnippet = sanitizedSnippet.replace('height="450"', '');

    sanitizedSnippet = sanitizedSnippet.replace('width="800"', 'width="100%"');
    sanitizedSnippet = sanitizedSnippet.replace('height="600"', '');

    sanitizedSnippet = sanitizedSnippet.replace('border:0;', 'border:0;aspect-ratio:4/3;');

    return sanitizedSnippet;
};

export const createUniqueCrossOriginIframeSandboxUrl = (uniqueIdentifier: string): URL => {
    return new URL(
        (process.env.NEXT_PUBLIC_IFRAME_SANDBOX_HTML_BASE_URL ?? '').replace(
            /^(\w+:\/\/)/,
            `$1${uniqueIdentifier}`,
        ),
    );
};

const HTML_BLOCK_SUPPORTED_UTM_PARAMETERS_WITH_NAME: Map<string, string> = new Map([
    ['utm_source', 'UTM Source'],
    ['utm_medium', 'UTM Medium'],
    ['utm_campaign', 'UTM Campaign'],
    ['utm_term', 'UTM Term'],
    ['utm_content', 'UTM Content'],
]);

export const interpolateScriptVariables = (
    script: string,
    trackingProperties: TrackingProperty[],
    locale?: string,
): string => {
    const localeOptions: InputOption[] = inputTypeOptions[locale ?? 'de'] ?? inputTypeOptions['de'];

    let interpolatedScript = script;

    // First we replace all variables by the localized inputTypeOptions.
    for (const { field: variableName, name: variableValue } of localeOptions) {
        if (variableName === 'custom') {
            continue;
        }

        interpolatedScript = interpolatedScript.replace(
            new RegExp(`\\{\\{\\s*${variableName}\\s*\\}\\}`, 'gi'),
            variableValue,
        );
    }

    // No we also replace all custom variables or URL placeholders (the ones starting with "ps_").
    for (const { fieldName: variableName, name: variableValue } of trackingProperties) {
        interpolatedScript = interpolatedScript.replace(
            new RegExp(`\\{\\{\\s*${variableName}\\s*\\}\\}`, 'gi'),
            variableValue,
        );
    }

    // Now we replace all UTM parameters
    for (const [parameter, name] of HTML_BLOCK_SUPPORTED_UTM_PARAMETERS_WITH_NAME.entries()) {
        interpolatedScript = interpolatedScript.replace(
            new RegExp(`\\{\\{\\s*${parameter}\\s*\\}\\}`, 'gi'),
            name,
        );
    }

    return interpolatedScript;
};
