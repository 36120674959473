import { NAME } from '@/app/editor/blocks/constants';

import { FaceSmileIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { change, Field, FormSection, getFormValues } from 'redux-form';

import { getNextHigherParentByType } from '@/app/editor/blocks/models/blocks';
import CropButton from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/CropButton';
import EmojiPicker from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/EmojiPicker';
import GenericSize from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/GenericSize';
import IconToggle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/IconToggleButton';
import ImageSrc from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/ImageSrc';
import MediaSrcTypeSelect from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/MediaSrcTypeSelect';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import type { BlockResource, DefaultEditFormProps, MediaSrcType } from '@/app/editor/blocks/types';

export interface Props
    extends Pick<DefaultEditFormProps, 'form' | 'handleSubmit' | 'initialValues'> {}

const srcTypeOptions = [
    {
        name: 'emoji',
        value: 'emoji',
        render: () => <FaceSmileIcon className="size-5" />,
    },
    {
        name: 'image',
        value: 'image',
        render: () => <PhotoIcon className="size-5" />,
    },
];

export const mediaSizeMap = {
    S: { value: 'small' },
    M: { value: 'medium' },
    L: { value: 'large' },
    XL: { value: 'xLarge' },
};

const ListItemForm = ({ handleSubmit, form, initialValues }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const formValues = useAppSelector((state) => getFormValues(form)(state)) as BlockResource;
    const srcType: MediaSrcType = formValues?.attributes?.content?.srcType;
    const listBlock = useAppSelector((state) => getNextHigherParentByType(state, form, 'list'));

    const handleCropSave = (src: string) => {
        dispatch(change(form, 'attributes.content.src', src));

        setTimeout(handleSubmit);
    };

    return (
        <>
            <FormSection name="additionalBlocks">
                <FormSection name={listBlock.id}>
                    <Field
                        name="attributes.content.visualSize"
                        component={GenericSize}
                        submit={handleSubmit}
                        valueMap={mediaSizeMap}
                    />
                </FormSection>
            </FormSection>

            <Field
                name="attributes.content.srcType"
                component={MediaSrcTypeSelect}
                submit={handleSubmit}
                srcTypeOptions={srcTypeOptions}
                src={formValues?.attributes?.content?.src}
            />

            {srcType === 'image' && (
                <div className="mt-2 grid grid-cols-3 gap-2">
                    <CropButton
                        src={initialValues?.attributes?.content?.src}
                        onSave={handleCropSave}
                        size={initialValues?.attributes?.content?.size}
                    />

                    <FormSection name="additionalBlocks">
                        <FormSection name={listBlock.id}>
                            <Field
                                name="attributes.content.borderRadius"
                                component={IconToggle}
                                submit={handleSubmit}
                                icon="borderRadius"
                                tooltip={t('round-images')}
                                toggleType="borderRadius"
                            />
                        </FormSection>
                    </FormSection>
                </div>
            )}

            <div className="mt-4">
                {srcType === 'emoji' && (
                    <Field
                        name="attributes.content.emoji"
                        component={EmojiPicker}
                        submit={handleSubmit}
                        label="Emojis"
                    />
                )}

                {srcType === 'image' && (
                    <Field name="attributes.content.src" component={ImageSrc} />
                )}
            </div>
        </>
    );
};

export default ListItemForm;
