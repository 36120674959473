/**
 * Types related to events and the editor engine event emitter.
 */

import type { useEditorEngineEventEmitter } from '@/app/editor/engine/core/hooks/events/useEditorEngineEventEmitter';
import type { EditorEngineAction } from '@/app/editor/engine/core/types/action';
import type {
    EditorEngineDefaultTypeInput,
    EditorEngineError,
} from '@/app/editor/engine/core/types/engine';
import type {
    EditorEngineHistoryEntryRecordedStep,
    EditorEngineHistoryExecutionError,
    EditorEngineLifecycleHandlerTimingType,
} from '@/app/editor/engine/core/types/history';

/**
 * The type of the editor engine event emitter.
 */
export type EditorEngineEventEmitter = ReturnType<typeof useEditorEngineEventEmitter>;

/**
 * The types of events that the editor engine can emit.
 */
export enum EditorEngineEventType {
    /**
     * An action has been enqueued.
     */
    ActionEnqueued = 'ActionEnqueued',
    /**
     * An action has started.
     */
    ActionStarted = 'ActionStarted',
    /**
     * An action has finished.
     */
    ActionFinished = 'ActionFinished',
    /**
     * A step has started.
     */
    StepStarted = 'StepStarted',
    /**
     * A step has finished.
     */
    StepFinished = 'StepFinished',
    /**
     * A step has failed.
     */
    StepFailed = 'StepFailed',
    /**
     * An action has failed.
     */
    ActionFailed = 'ActionFailed',
    /**
     * A pre or post action hook has failed.
     */
    PrePostError = 'PrePostError',
    /**
     * An action has been skipped.
     */
    ActionSkipped = 'ActionSkipped',
    /**
     * An error has occurred while going forward or backward in the history.
     * For example, when an error happens during undo or redo.
     */
    HistoryError = 'HistoryError',
    /**
     * A critical error has occurred, or an assertion needed for the proper
     * functioning of the Editor Engine has failed.
     */
    CriticalError = 'CriticalError',
    /**
     * An uncaught error has occurred.
     */
    UnknownError = 'UnknownError',
}

export type GenericEditorEngineEvent<
    TType extends EditorEngineEventType,
    TPayload extends Record<string, unknown>,
> = {
    type: TType;
} & Omit<TPayload, 'type'>;

export type EditorEngineActionEnqueuedEvent<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = GenericEditorEngineEvent<
    EditorEngineEventType.ActionEnqueued,
    {
        action: EditorEngineAction<TEditorEngineTypeInput>;
    }
>;

export type EditorEngineActionStartedEvent<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = GenericEditorEngineEvent<
    EditorEngineEventType.ActionStarted,
    {
        action: EditorEngineAction<TEditorEngineTypeInput>;
    }
>;

export type EditorEngineActionFinishedEvent<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = GenericEditorEngineEvent<
    EditorEngineEventType.ActionFinished,
    {
        action: EditorEngineAction<TEditorEngineTypeInput>;
    }
>;

export type EditorEngineStepStartedEvent<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = GenericEditorEngineEvent<
    EditorEngineEventType.StepStarted,
    {
        step: EditorEngineHistoryEntryRecordedStep<TEditorEngineTypeInput['ExtraContext']>;
    }
>;

export type EditorEngineStepFinishedEvent<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = GenericEditorEngineEvent<
    EditorEngineEventType.StepFinished,
    {
        step: EditorEngineHistoryEntryRecordedStep<TEditorEngineTypeInput['ExtraContext']>;
    }
>;

export type EditorEngineStepFailedEvent<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = GenericEditorEngineEvent<
    EditorEngineEventType.StepFailed,
    {
        step: EditorEngineHistoryEntryRecordedStep<TEditorEngineTypeInput['ExtraContext']>;
    }
>;

export type EditorEngineActionFailedEvent<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = GenericEditorEngineEvent<
    EditorEngineEventType.ActionFailed,
    {
        action: EditorEngineAction<TEditorEngineTypeInput>;
    }
>;

export type EditorEnginePrePostErrorEvent<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = GenericEditorEngineEvent<
    EditorEngineEventType.PrePostError,
    {
        timing: EditorEngineLifecycleHandlerTimingType;
        action: EditorEngineAction<TEditorEngineTypeInput>;
    }
>;

export type EditorEngineActionSkippedEvent<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = GenericEditorEngineEvent<
    EditorEngineEventType.ActionSkipped,
    {
        action: EditorEngineAction<TEditorEngineTypeInput>;
    }
>;

export type EditorEngineHistoryErrorEvent<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = GenericEditorEngineEvent<
    EditorEngineEventType.HistoryError,
    {
        error: EditorEngineHistoryExecutionError<TEditorEngineTypeInput>;
    }
>;

export type EditorEngineCriticalErrorEvent = GenericEditorEngineEvent<
    EditorEngineEventType.CriticalError,
    {
        error: EditorEngineError;
    }
>;

export type EditorEngineUnknownErrorEvent = GenericEditorEngineEvent<
    EditorEngineEventType.UnknownError,
    {
        error: EditorEngineError;
    }
>;

export type EditorEngineEvent<TEditorEngineTypeInput extends EditorEngineDefaultTypeInput> =
    | EditorEngineActionEnqueuedEvent<TEditorEngineTypeInput>
    | EditorEngineActionStartedEvent<TEditorEngineTypeInput>
    | EditorEngineActionFinishedEvent<TEditorEngineTypeInput>
    | EditorEngineStepStartedEvent<TEditorEngineTypeInput>
    | EditorEngineStepFinishedEvent<TEditorEngineTypeInput>
    | EditorEngineStepFailedEvent<TEditorEngineTypeInput>
    | EditorEngineActionFailedEvent<TEditorEngineTypeInput>
    | EditorEnginePrePostErrorEvent<TEditorEngineTypeInput>
    | EditorEngineActionSkippedEvent<TEditorEngineTypeInput>
    | EditorEngineHistoryErrorEvent<TEditorEngineTypeInput>
    | EditorEngineCriticalErrorEvent
    | EditorEngineUnknownErrorEvent;

/**
 * Given an event type, this type will infer the payload for that event.
 */
export type EditorEngineGetEventPayload<TEventTypes extends EditorEngineEventType> =
    Extract<
        EditorEngineEvent<EditorEngineDefaultTypeInput>,
        { type: TEventTypes }
    > extends infer TEvent
        ? TEvent extends EditorEngineEvent<EditorEngineDefaultTypeInput>
            ? TEvent
            : never
        : never;

/**
 * The type for a listener of an editor engine event.
 */
export type EditorEngineEventListener<
    TEventType extends EditorEngineEventType,
    TPayload = EditorEngineGetEventPayload<TEventType>,
> = (payload: TPayload) => void;

/**
 * The type for a map of event listeners.
 */
export type EditorEngineEventListenerMap = Partial<{
    [K in EditorEngineEventType]: EditorEngineEventListener<K>[];
}>;
