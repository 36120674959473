import { rectSortingStrategy } from '@dnd-kit/sortable';

import { getPreviewIdToShow } from '@/app/editor/blocks/helpers';
import { getBgImageStyles } from '@/app/editor/blocks/helpers/getBgImageStyles';
import { SectionPreview } from '@/app/editor/editor/components/ArtBoard/SectionPreview';
import { AddBlockButton } from '@/app/editor/editor/components/ArtBoard/SortableBlockList/AddBlockButton';
import { useArtboardSize } from '@/app/editor/editor/hooks/useArtboardSize';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { PerspectiveEditorEnginePreviewAwareSequenceRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareSequenceRenderer';
import {
    EditorEngineDropPosition,
    EditorEngineNodeDragStateType,
} from '@/app/editor/engine/core/types';
import { useBlockOrPreview } from '@/app/editor/engine/hooks/useBlockOrPreview';
import { getActiveSectionPreviewId, getPreviewBlockId } from '@/app/editor/sections/models/insert';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    verticalAlign: 'start' | 'center' | 'end';
    framed: boolean;
}

interface EmptyColumnProps {
    sectionPreviewId: string;
    blockId: string;
    isHighlighted: boolean;
}

const EmptyColumn: FC<EmptyColumnProps> = ({ sectionPreviewId, blockId, isHighlighted }) => (
    <>
        {sectionPreviewId ? (
            <SectionPreview sectionPreviewId={sectionPreviewId} />
        ) : (
            <div
                className={cn('opacity-0 transition-opacity group-hover/column:opacity-100', {
                    'opacity-100': isHighlighted,
                })}
            >
                <AddBlockButton columnBlockId={blockId} />
            </div>
        )}
    </>
);

const Component: FC<Props> = ({ blockId, box, verticalAlign, framed, activeTheme, dragInfo }) => {
    const artboardSize = useArtboardSize();
    const borderRadiusClass = useBorderRadius('blockFrame');
    const activeSectionPreviewId = useAppSelector(getActiveSectionPreviewId);
    const previewBlockId = useAppSelector(getPreviewBlockId);
    const sectionPreviewId = getPreviewIdToShow({
        blockId,
        previewBlockId,
        sectionPreviewId: activeSectionPreviewId,
    });
    const block = useBlockOrPreview(blockId);
    const noChildren = !block.relationships.components.data.length;
    const isDraggingToEmptyColumn =
        dragInfo?.type === EditorEngineNodeDragStateType.Target &&
        dragInfo?.position === EditorEngineDropPosition.Within;

    const { backgroundColor, bgImage } = box || {};
    const { visible, src } = bgImage || {};
    const wrapperClass = cn('flex h-full flex-col transition-all', borderRadiusClass, {
        'justify-start': verticalAlign === 'start',
        'justify-center': verticalAlign === 'center',
        'justify-end': verticalAlign === 'end',
        'p-4':
            (artboardSize === ArtBoardSize.TABLET && !framed) ||
            (artboardSize === ArtBoardSize.MOBILE && framed),
        'p-6':
            (artboardSize === ArtBoardSize.TABLET && framed) ||
            (artboardSize === ArtBoardSize.DESKTOP && !framed),
        'p-2': artboardSize === ArtBoardSize.MOBILE && !framed,
        'p-10': artboardSize === ArtBoardSize.DESKTOP && framed,
        'min-h-[375px]': framed && visible,
    });

    return (
        <div
            className={cn('group/column relative h-full', borderRadiusClass)}
            style={{
                background: framed
                    ? getThemedOrCustomColor(backgroundColor, 'backgroundColor', activeTheme)
                    : 'transparent',
            }}
        >
            {/* Background Image */}
            {visible && src && framed && (
                <div
                    className={cn(
                        'pointer-events-none absolute inset-0 bg-cover bg-no-repeat',
                        borderRadiusClass,
                    )}
                    style={getBgImageStyles(bgImage)}
                />
            )}

            {/* Content */}
            <div className={wrapperClass}>
                {noChildren ? (
                    <EmptyColumn
                        sectionPreviewId={sectionPreviewId}
                        blockId={blockId}
                        isHighlighted={isDraggingToEmptyColumn}
                    />
                ) : (
                    <PerspectiveEditorEnginePreviewAwareSequenceRenderer
                        parentId={blockId}
                        sortingStrategy={rectSortingStrategy}
                        className="flex-col"
                        propsFromParent={{
                            wrapperClass: '',
                        }}
                    />
                )}
            </div>
        </div>
    );
};

Component.displayName = 'GridColumn';

export default Component;
