import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_STRING } from '@/utils/empty';

import { NAME } from '../constants';
import { CampaignFilter, CampaignOrder, OverviewDisplayMode } from '../types';

import type { Pagination } from '@/core/api/types';
import type { AppState } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    displayMode: OverviewDisplayMode;
    filter: CampaignFilter;
    order: string;
    search: string;
    isGlobalSearch: boolean;
    pagination?: Pagination;
}

const initialState: State = {
    displayMode: OverviewDisplayMode.grid,
    filter: CampaignFilter.all,
    order: CampaignOrder.isFavReverse,
    search: EMPTY_STRING,
    isGlobalSearch: false,
    pagination: undefined,
};

export const overviewSlice = createSlice({
    name: `${NAME}/overview`,
    initialState,
    reducers: {
        setDisplayMode(state, action: PayloadAction<OverviewDisplayMode>) {
            return {
                ...state,
                displayMode: action.payload,
            };
        },
        setFilter(state, action: PayloadAction<CampaignFilter>) {
            return {
                ...state,
                pagination: initialState.pagination,
                filter: action.payload,
            };
        },
        setOrder(state, action: PayloadAction<CampaignOrder>) {
            state.order = action.payload;
        },
        setSearch(state, action: PayloadAction<string>) {
            return {
                ...state,
                search: action.payload,
            };
        },
        setIsGlobalSearch(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isGlobalSearch: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination>) {
            state.pagination = action.payload;
        },
        reset: (state) => ({
            ...initialState,
            displayMode: state.displayMode,
        }),
    },
});

// === Actions ======

export const {
    setDisplayMode,
    setFilter,
    setSearch,
    setIsGlobalSearch,
    reset,
    setPagination,
    setOrder,
} = overviewSlice.actions;

// === Selectors ======

export const getDisplayMode = (state: AppState) => state[NAME]?.overviewReducer?.displayMode;

export const getFilter = (state: AppState) => state[NAME]?.overviewReducer?.filter;

export const getOrder = (state: AppState) => state[NAME]?.overviewReducer?.order;

export const getSearch = (state: AppState) => state[NAME]?.overviewReducer?.search;

export const getIsGlobalSearch = (state: AppState) => state[NAME]?.overviewReducer?.isGlobalSearch;

export const getCampaignPagination = (state: AppState) => state[NAME]?.overviewReducer?.pagination;

export default overviewSlice.reducer;
