import type { BlockResource } from '@/app/editor/blocks/types';

/**
 * Creates a new block structure with the same properties as the original block,
 * but with new data for properties that might change because of the
 * duplication.
 */
export const duplicateBlockStructure = ({
    block,
    id,
    pageId,
    funnelId,
}: {
    /**
     * The block to duplicate.
     */
    block: BlockResource;
    /**
     * The new ID for the duplicated block.
     */
    id: string;
    /**
     * The ID of the page that the duplicated block will belong to.
     */
    pageId: string;
    /**
     * The ID of the funnel that the duplicated block will belong to.
     */
    funnelId: string;
}) => {
    // todo(editorengine): to be fixed in https://linear.app/perspective/issue/FUN-951/tracking-properties
    // When duplicating a block structure, we must set the tracking ID to an
    // momentary value that indicates that the real tracking ID will be created
    // by the backend.
    return {
        ...block,
        id,
        relationships: {
            ...block.relationships,
            components: {
                data: [],
            },
            page: {
                data: {
                    type: 'page',
                    id: pageId,
                },
            },
            campaign: {
                data: {
                    type: 'campaign',
                    id: funnelId,
                },
            },
        },
    };
};
