import { DragOverlay, useDndContext } from '@dnd-kit/core';

import { EditorEngineNodeDragStateType } from '@/app/editor/engine/core/types';

import type {
    EditorEngineComponent,
    EditorEngineNodeData,
    EditorEngineNode,
    EditorEngineDocument,
} from '@/app/editor/engine/core/types';

interface Props<TDocument extends EditorEngineDocument, TNodeData extends EditorEngineNodeData> {
    /**
     * The component to render the dragged node.
     */
    Component: EditorEngineComponent<TDocument, TNodeData>;
    /**
     * The document that the editor engine is working with.
     */
    document: TDocument;
    /**
     * A function that identifies a node.
     */
    identify: (node: EditorEngineNode<TNodeData>) => string;
    /**
     * The node that is being dragged.
     */
    draggedNode: EditorEngineNode<TNodeData>;
}

/**
 * A drag overlay that renders the dragged node.
 * This node will follow the cursor while dragging.
 */
export const EditorEngineDragOverlay = <
    TDocument extends EditorEngineDocument,
    TNodeData extends EditorEngineNodeData,
>({
    Component,
    document,
    identify,
    draggedNode,
}: Props<TDocument, TNodeData>) => {
    const dragContext = useDndContext();

    return (
        <DragOverlay dropAnimation={null}>
            {draggedNode && (
                <Component
                    document={document}
                    node={draggedNode}
                    dragInfo={{
                        type: EditorEngineNodeDragStateType.Overlay,
                        context: dragContext,
                        isDragged: dragContext.active?.id === identify(draggedNode),
                        isDragOperationInProgress: Boolean(dragContext.active),
                    }}
                    childIndex={0}
                />
            )}
        </DragOverlay>
    );
};
