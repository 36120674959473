import { apiDelete, apiGet, apiPatch, apiPost } from '@/core/api';
import { EMPTY_ARRAY } from '@/utils/empty';

import type {
    DomainAvailabilityResponseData,
    NewAccountData,
    WorkspaceResource,
    WorkspaceInvitationResource,
    InvitationResource,
} from '../types';
import type { UserResource } from '@/app/user/types';
import type { CheckEmailResponse, ResponseData } from '@/core/api/types';

// REQUESTS

export const createWorkspaceRequest = (data: FormData) => {
    return apiPost<ResponseData<WorkspaceResource>>('/workspaces', data);
};

export const getWorkspacesRequest = () => {
    return apiGet<ResponseData<WorkspaceResource[]>>('/workspaces');
};

export const getWorkspaceByIdRequest = (id: string) => {
    return apiGet(`/workspaces/${id}`);
};

export const domainAvailabilityRequest = (domain: string) => {
    return apiPost<ResponseData<DomainAvailabilityResponseData>>('/workspaces/domain-available', {
        data: { perspectiveDomain: domain },
    });
};

export const sendInvitesRequest = (workspaceId: string, emails: string[]) => {
    const invitationsPromises = emails.map((email) =>
        apiPost('/events/userInvitation', {
            data: {
                workspaceId,
                email,
            },
        }),
    );

    return Promise.all(invitationsPromises);
};

export const checkEmailRequests = (workspaceId: string, email: string) => {
    return apiPost<ResponseData<CheckEmailResponse>>('/events/userInvitation/check-email', {
        data: {
            workspaceId,
            email,
        },
    });
};

export const getWorkspaceInvitationsRequest = (workspaceId: string) => {
    return apiGet<ResponseData<WorkspaceInvitationResource[]>>(
        `/workspaces/${workspaceId}/invitations`,
    );
};

export const cancelInviteRequest = (eventId: string) => {
    return apiPost(`/events/userInvitation/${eventId}/cancel`, {});
};

export const addFunnelsToWorkspaceRequest = (workspaceId: string, funnelIds: string[]) => {
    return apiPost<ResponseData<WorkspaceResource>>('/workspaces/move-campaigns', {
        data: {
            workspaceId,
            campaignIds: funnelIds,
        },
    });
};

export const createAccountRequest = async (account: NewAccountData, invitationId: string) => {
    const [firstName, ...lastName] = account?.name?.split(' ') || EMPTY_ARRAY;

    return apiPost(`/events/userInvitation/${invitationId}/use`, {
        data: {
            firstName,
            lastName: lastName?.length ? lastName.join(' ') : undefined,
            password: account?.password,
        },
    });
};

export const resetPasswordRequest = async (email: string) => {
    return apiPost('/users/password/reset', {
        data: {
            email,
        },
    });
};

export const validateInvitationRequest = async (invitationId: string) => {
    return apiGet<ResponseData<InvitationResource>>(`/events/userInvitation/${invitationId}`);
};

export const getWorkspaceByDomainRequest = async (domainName: string) => {
    return apiGet<ResponseData<WorkspaceResource>>(`/workspaces/domain/${domainName}`);
};

export const getWorkspaceUsersRequest = async (workspaceId: string) => {
    return apiGet<ResponseData<UserResource[]>>(`/workspaces/${workspaceId}/users`);
};

export const deleteWorkspaceUserRequest = async (workspaceId: string, userId: string) => {
    return apiDelete(`/workspaces/${workspaceId}/users/${userId}`);
};

export const updateWorkspaceOrder = async (workspaceIds: string[]) => {
    return apiPatch('users/me/workspaces/order', {
        data: {
            workspaces: workspaceIds,
        },
    });
};
