import { isDropAllowed } from '@/app/editor/engine/core/utils/dragAndDrop/isDropAllowed';
import { isValidCollision } from '@/app/editor/engine/core/utils/dragAndDrop/isValidCollision';
import { isValidDraggableConfiguration } from '@/app/editor/engine/core/utils/dragAndDrop/isValidDraggableConfiguration';
import { isValidOver } from '@/app/editor/engine/core/utils/dragAndDrop/isValidOver';

import type {
    EditorEngineDropCandidate,
    EditorEngineDefaultTypeInput,
} from '@/app/editor/engine/core/types';
import type { DndContextProps } from '@dnd-kit/core';
import type { Dispatch, SetStateAction } from 'react';

/**
 * Returns a function that handles the movement of a dragged item.
 */
export const getOnDragMove = <TEditorEngineTypeInput extends EditorEngineDefaultTypeInput>({
    documentManager,
    nodeManager,
    setDropCandidate,
}: {
    /**
     * The document manager.
     */
    documentManager: TEditorEngineTypeInput['DocumentManager'];
    /**
     * The node manager.
     */
    nodeManager: TEditorEngineTypeInput['NodeManager'];
    /**
     * A function for setting the drop candidate.
     */
    setDropCandidate: Dispatch<
        SetStateAction<EditorEngineDropCandidate<TEditorEngineTypeInput> | null>
    >;
}) => {
    return (({ active, over, collisions }) => {
        const activeDraggableConfiguration = active.data.current?.draggableConfiguration;

        if (!isValidOver(over) || !isValidDraggableConfiguration(activeDraggableConfiguration)) {
            setDropCandidate(null);

            return;
        }

        for (const collision of collisions) {
            if (!isValidCollision<TEditorEngineTypeInput>(collision)) {
                continue;
            }

            const original = nodeManager.getNode(active.id.toString());
            const target = nodeManager.getNode(collision.id.toString());

            const { draggableConfiguration } = collision.data.droppableContainer.data.current;
            const preview = {
                original,
                target,
                futurePosition: collision.data.position,
            };
            const canTarget = activeDraggableConfiguration.canTarget({
                documentManager,
                nodeManager,
                preview,
            });
            const canBeTargeted = draggableConfiguration.canBeTargeted({
                documentManager,
                nodeManager,
                preview,
            });

            if (
                canTarget &&
                canBeTargeted &&
                isDropAllowed({
                    nodeManager,
                    preview,
                })
            ) {
                setDropCandidate({
                    executionConfiguration: draggableConfiguration.executeDrop({
                        preview,
                        documentManager,
                        nodeManager,
                    }),
                    preview,
                });

                return;
            }
        }

        setDropCandidate(null);
    }) satisfies DndContextProps['onDragMove'];
};
