import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { cn } from '@/utils/cn';

import Box from '../_wrapper/Box';

import type { DefaultBlockComponentProps } from '../../types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    dashed: boolean;
    hidden: boolean;
    color: string;
}

const Component: FC<Props> = ({
    artBoardIndex,
    box,
    dashed,
    hidden,
    color,
    activeTheme,
    isDragged,
}) => {
    const borderClassName = cn({
        'border-t': !hidden,
        'border-t border-dashed': dashed,
    });

    return (
        <Box box={box} isDragged={isDragged} artBoardIndex={artBoardIndex}>
            <div className="h-0 select-none py-5">
                <div
                    className={borderClassName}
                    style={{
                        borderColor: getThemedOrCustomColor(color, 'fontColor', activeTheme),
                    }}
                />
            </div>
        </Box>
    );
};

Component.displayName = 'Divider';

export default Component;
