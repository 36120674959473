import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { reduxForm } from 'redux-form';

import { Button } from '@/ui/components/Button';

import type { DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { InjectedFormProps } from 'redux-form';

type Props = DefaultEditFormProps & InjectedFormProps;

const Form = ({ handleSubmit }: Props) => {
    // TODO The payment form config will be added later
    const { t } = useTranslation(NAME);

    return (
        <>
            <Button color="primary" onClick={handleSubmit}>
                {t('payment.stripe.connect')}
            </Button>
        </>
    );
};

export const PaymentFormForm = reduxForm({})(Form);
