import { useTranslation } from 'react-i18next';
import { Field, FormSection, change, reduxForm } from 'redux-form';

import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import CropButton from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/CropButton';
import ImageSrc from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/ImageSrc';
import MediaSrcTypeSelect from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/MediaSrcTypeSelect';
import IconLibrary from '@/app/editor/iconLibrary/components/Library/Library.controller';
import { downloadIcon } from '@/app/editor/iconLibrary/models/icons';
import { getColorByLightness, getThemeColor } from '@/app/editor/themes/helpers';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import { NAME } from '../../constants';
import { getBlockById, getBlockChildComponentByType } from '../../models/blocks';

import type { DefaultEditFormProps, MediaSrcType } from '@/app/editor/blocks/types';
import type { Icon } from '@/app/editor/iconLibrary/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {
    fieldName: string;
}

const Form: FC<Props & InjectedFormProps> = ({ activeTheme, blockId, handleSubmit }) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();

    const block = useAppSelector((state) => getBlockById(state, blockId));
    const mediaChild = useAppSelector((state) =>
        getBlockChildComponentByType(state, blockId, 'media'),
    );
    const blockIconId = block?.attributes?.content?.icon;
    const srcType: MediaSrcType = mediaChild?.attributes?.content?.srcType;
    const bgColor = mediaChild?.attributes?.content?.box?.backgroundColor;

    // color by lightness if backgroundColor === themeColor
    const themedIconColor = getColorByLightness(
        getThemeColor(activeTheme, 'buttonBackgroundColor'),
        bgColor,
    );

    const handleIconSelect = (icon: Icon) => {
        dispatch(downloadIcon(icon, mediaChild?.id));
    };

    const handleCropSave = async (src: string) => {
        await dispatch(
            change(blockId, `additionalBlocks[${mediaChild?.id}].attributes.content.src`, src),
        );

        setTimeout(handleSubmit);
    };

    return (
        <FormSection name="additionalBlocks">
            <FormSection name={mediaChild?.id}>
                <Field
                    name="attributes.content.srcType"
                    component={MediaSrcTypeSelect}
                    submit={handleSubmit}
                    src={mediaChild?.attributes?.content?.src}
                />

                <div className="mt-2 grid grid-cols-3 gap-2">
                    <Field
                        name="attributes.content.box.backgroundColor"
                        component={Color}
                        expand="right"
                        submit={handleSubmit}
                        themeColor={getThemeColor(activeTheme, 'formFieldBackgroundColor')}
                        tooltip={t('background-color')}
                    />

                    {srcType === 'icon' && (
                        <Field
                            name="attributes.content.color"
                            component={Color}
                            expand="center"
                            submit={handleSubmit}
                            themeColor={themedIconColor}
                            tooltip={t('icon-color')}
                        />
                    )}

                    {srcType === 'image' && (
                        <CropButton
                            src={mediaChild?.attributes?.content?.src}
                            onSave={handleCropSave}
                            size="answer"
                        />
                    )}
                </div>

                <div className="mt-4">
                    {srcType === 'icon' && (
                        <IconLibrary
                            initialPlatform={mediaChild?.attributes?.content?.platform}
                            blockIconId={blockIconId}
                            onIconSelect={handleIconSelect}
                        />
                    )}

                    {srcType === 'image' && (
                        <Field
                            name="attributes.content.src"
                            component={ImageSrc}
                            submit={handleSubmit}
                        />
                    )}
                </div>
            </FormSection>
        </FormSection>
    );
};

Form.displayName = 'QuestionMediaAnswerEditForm';

export default reduxForm({})(Form);
