import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { useEntry } from '@/core/contentful/hooks/queries/useEntry';
import { track } from '@/core/tracking';
import { Popover } from '@/ui/components/Popover';
import { cn } from '@/utils/cn';

import { HelpPopover } from './HelpPopover';

import type { HelpTooltipContentDescriptor } from '@/app/helpTooltips/types';
import type { Placement } from '@floating-ui/react';

export interface Props extends HelpTooltipContentDescriptor {
    className?: string;
    placement?: Placement;
}

export const HelpIcon = ({ className, contentId, slug, placement }: Props) => {
    const { isLoading } = useEntry(contentId);

    const handleOpenChange = (open: boolean) => {
        if (open) {
            track(TRACKING_EVENTS.helpTooltip.show, { contentId, slug });
        }
    };

    return (
        <div
            className={cn(
                'inline-block cursor-pointer text-gray-400 transition hover:text-gray-600',
                className,
            )}
        >
            <Popover
                render={() => <HelpPopover contentId={contentId} />}
                placement={placement}
                fallbackPlacements={['right', 'left', 'top']}
                showArrow
                onOpenChange={handleOpenChange}
                disabled={isLoading}
            >
                <InformationCircleIcon className="size-5 aria-[expanded=true]:text-blue-500" />
            </Popover>
        </div>
    );
};
