import {
    DEFAULT_FAILURE_RATE,
    DEFAULT_NETWORK_HEALTH,
    DEFAULT_RESPONSE_TIME,
} from '@/app/editor/engine/core/constants';

import * as Slider from '@radix-ui/react-slider';
import { useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';

import { EditorEngineDebugPanel } from '@/app/editor/engine/core/components/utils/EditorEngineDebugPanel';
import { Input } from '@/app/editor/engine/core/components/utils/Input';
import { simulateNetworkDelay } from '@/app/editor/engine/core/functions/utils/simulateNetworkDelay';
import { waitForSeconds } from '@/app/editor/engine/core/utils/debug/waitForSeconds';
import { Button } from '@/ui/components/Button';

import type { EditorEngineDefaultTypeInput } from '@/app/editor/engine/core/types';
import type { EditorEngineActionEnhancement } from '@/app/editor/engine/core/types';

const Section = ({
    title,
    description,
    defaultValue,
    value,
    setValue,
}: {
    title: string;
    description: string;
    defaultValue: number;
    value: number;
    setValue: (value: number) => void;
}) => (
    <div className="mt-3 flex flex-col gap-2">
        <div className="font-bold">{title}</div>
        <div className="text-pretty text-sm">{description}</div>
        <div className="flex items-center gap-1">
            <Input
                value={value.toString()}
                setValue={(value) => setValue(parseFloat(value) || 0)}
                type="number"
                min={0}
                max={1}
                step={0.01}
                label="Network Health"
            />
            <Button
                size="thin"
                onClick={() => {
                    setValue(defaultValue);
                }}
            >
                Reset
            </Button>
        </div>
        <Slider.Root
            min={0}
            max={1}
            className="relative flex h-5 w-full items-center"
            onValueChange={(value) => setValue(value[0])}
            value={[value]}
            step={0.01}
        >
            <Slider.Track className="relative h-0.5 grow rounded-sm bg-gray-300">
                <Slider.Range className="absolute h-full rounded-full bg-gray-300" />
            </Slider.Track>

            <Slider.Thumb
                className="block size-3 cursor-pointer rounded-full bg-gray-400 hover:bg-gray-600 focus:outline-none focus:ring-0"
                aria-label="Box size"
            />
        </Slider.Root>
    </div>
);

/**
 * Provides enhancements to the editor engine that simulate network conditions.
 */
export const useBaseEditorEngineEnhancements = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>() => {
    const [simulateNetwork, setSimulateNetwork] = useLocalStorage(
        'EditorEngineVisualizerSimulateNetwork',
        false,
    );
    const [responseTime, setResponseTime] = useLocalStorage(
        'EditorEngineVisualizerResponseTime',
        DEFAULT_RESPONSE_TIME,
    );
    const [networkHealth, setNetworkHealth] = useLocalStorage(
        'EditorEngineVisualizerNetworkHealth',
        DEFAULT_NETWORK_HEALTH,
    );
    const [failureRate, setFailureRate] = useLocalStorage(
        'EditorEngineVisualizerFailureRate',
        DEFAULT_FAILURE_RATE,
    );
    const simulateNetworkRef = useRef(simulateNetwork);
    const responseTimeRef = useRef(responseTime);
    const networkHealthRef = useRef(networkHealth);
    const failureRateRef = useRef(failureRate);

    useEffect(() => {
        simulateNetworkRef.current = simulateNetwork;
    }, [simulateNetwork]);

    useEffect(() => {
        responseTimeRef.current = responseTime;
    }, [responseTime]);

    useEffect(() => {
        networkHealthRef.current = networkHealth;
    }, [networkHealth]);

    useEffect(() => {
        failureRateRef.current = failureRate;
    }, [failureRate]);

    const toggleSimulateNetwork = () => {
        setSimulateNetwork(!simulateNetwork);
    };

    const waitAndMaybeFail = async () => {
        await waitForSeconds(
            simulateNetworkDelay({
                basePercentile: responseTimeRef.current,
                networkHealth: networkHealthRef.current,
            }),
        );

        if (Math.random() < failureRateRef.current) {
            console.error(`Action failed because of rate ${failureRateRef.current}`);
            throw new Error(`Action failed because of rate ${failureRateRef.current}`);
        }
    };

    return {
        debugNodes: (
            <EditorEngineDebugPanel
                title={`Network (${simulateNetwork ? 'simulating' : 'not simulating'})`}
            >
                <div className="flex flex-col gap-2">
                    <div>
                        <Button
                            onClick={toggleSimulateNetwork}
                            variant={simulateNetwork ? 'primary' : 'default'}
                        >
                            {simulateNetwork ? 'Simulating Network' : 'Not Simulating Network'}
                        </Button>
                    </div>
                    <Section
                        title="Response Time"
                        description="The higher this value, the slower the network will be. The default is 0.27, which is the actual 99% percentile of the response time of our API (relevant endpoints)."
                        defaultValue={DEFAULT_RESPONSE_TIME}
                        value={responseTime}
                        setValue={setResponseTime}
                    />
                    <Section
                        title="Network Health"
                        description="A value of 1 means the network is healthy. The lower this value, the more likely the network will be slow by a bigger amount."
                        defaultValue={DEFAULT_NETWORK_HEALTH}
                        value={networkHealth}
                        setValue={setNetworkHealth}
                    />
                    <Section
                        title="Failure Rate"
                        description="The percentage of persisted updates that will fail. A value of 0 means no failures, while a value of 1 means all actions will fail."
                        defaultValue={DEFAULT_FAILURE_RATE}
                        value={failureRate}
                        setValue={setFailureRate}
                    />
                </div>
            </EditorEngineDebugPanel>
        ),
        debugMenuNodes: null,
        actionEnhancement: ((actionCreator) => {
            return ((arg) => {
                const action = actionCreator(arg);

                return {
                    ...action,
                    execute: async () => {
                        if (!simulateNetworkRef.current) {
                            return await action.execute();
                        }

                        await waitAndMaybeFail();

                        if ('execute' in action) {
                            return await action.execute();
                        }
                    },
                    undo: async () => {
                        if (!simulateNetworkRef.current) {
                            return await action.undo();
                        }

                        await waitAndMaybeFail();

                        if ('undo' in action) {
                            return await action.undo();
                        }
                    },
                };
            }) as typeof actionCreator;
        }) satisfies EditorEngineActionEnhancement<TEditorEngineTypeInput>,
    };
};
