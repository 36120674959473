import { useMemo, useState } from 'react';

import type {
    EditorEngineDefaultTypeInput,
    EditorEngineDropCandidate,
} from '@/app/editor/engine/core/types';

/**
 * Holds the state for the drag and drop operations.
 * A drop candidate refers to:
 * - The node that is being dragged
 * - The node that is being targeted
 * - The position where the node will be dropped relative to the target node
 * - The handler which will be executed when the drop is completed
 */
export const useEditorEngineDragAndDropState = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>() => {
    const [dropCandidate, setDropCandidate] =
        useState<EditorEngineDropCandidate<TEditorEngineTypeInput> | null>(null);

    return useMemo(() => {
        return {
            dropCandidateState: {
                dropCandidate,
                setDropCandidate,
            },
        };
    }, [dropCandidate, setDropCandidate]);
};
