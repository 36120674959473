import { Listbox } from '@headlessui/react';

import { cn } from '@/utils/cn';

import type { DropdownOption } from '@/ui/types';

export interface Props {
    option: DropdownOption;
    sprite: string;
    offsetMap: {};
}

const Option = ({ option, sprite, offsetMap }: Props) => {
    return (
        <Listbox.Option
            key={option.value}
            className={({ active }) =>
                cn('relative cursor-pointer select-none rounded-md p-2 text-sm text-gray-400', {
                    'bg-blue-50 text-blue-500': active,
                })
            }
            value={option.value}
        >
            {({ selected }) => {
                const iconStyle = {
                    background: `url(${sprite}) no-repeat`,
                    backgroundSize: 'auto 100%',
                    backgroundPositionX: `-${offsetMap[option.value]}px`,
                };

                return (
                    <div className="flex items-center">
                        <div className="mr-2 size-5 overflow-hidden" style={iconStyle} />
                        <span
                            className={cn(
                                'block truncate',
                                selected ? 'text-blue-500' : 'text-gray-400',
                            )}
                        >
                            {option.key}
                        </span>
                    </div>
                );
            }}
        </Listbox.Option>
    );
};

export default Option;
