import { useSortable } from '@dnd-kit/sortable';

import { EditorEngineDropPreview } from '@/app/editor/engine/core/components/dragAndDrop/EditorEngineDropPreview';
import { EditorEngineNodeView } from '@/app/editor/engine/core/components/view/EditorEngineNodeView';
import {
    type EditorEngineDefaultTypeInput,
    EditorEngineDropPosition,
} from '@/app/editor/engine/core/types';
import { EditorEngineOrientation } from '@/app/editor/engine/core/types/util';
import { cn } from '@/utils/cn';

import type { createDraggableConfigurationContext } from '@/app/editor/engine/core/context/createDraggableConfigurationContext';
import type { useEditorEngineDragAndDropState } from '@/app/editor/engine/core/hooks/dragAndDrop/useEditorEngineDragAndDropState';
import type {
    EditorEngineComponent,
    EditorEngineNode,
    EditorEngineDraggableConfiguration,
} from '@/app/editor/engine/core/types';

export interface EditorEngineNodeWrapperProps<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> {
    /**
     * The component to use to render the node.
     */
    Component: EditorEngineComponent<
        TEditorEngineTypeInput['Document'],
        TEditorEngineTypeInput['Data']
    >;
    /**
     * The document manager instance.
     */
    documentManager: TEditorEngineTypeInput['DocumentManager'];
    /**
     * The node manager instance.
     */
    nodeManager: TEditorEngineTypeInput['NodeManager'];
    /**
     * The node to be rendered.
     */
    node: EditorEngineNode<TEditorEngineTypeInput['Data']>;
    /**
     * The drag and drop state.
     */
    dragAndDropState: ReturnType<typeof useEditorEngineDragAndDropState<TEditorEngineTypeInput>>;
    /**
     * The hook which will provide the draggable configuration.
     */
    useDraggableConfiguration: ReturnType<
        typeof createDraggableConfigurationContext<TEditorEngineTypeInput>
    >['useDraggableConfiguration'];
    /**
     * The index of the node among its siblings.
     */
    childIndex: number;
    /**
     * The options to pass to the sortable hook.
     */
    sortableOptions?: Partial<Parameters<typeof useSortable>[0]>;
    /**
     * The classes to apply to the wrapper.
     */
    wrapperClass?: string;
    /**
     * Override the draggable configuration for this node only.
     */
    draggableConfigurationOverride?: EditorEngineDraggableConfiguration<TEditorEngineTypeInput>;
}

/**
 * A wrapper for a node to be rendered.
 */
export const EditorEngineNodeWrapper = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>({
    Component,
    node,
    documentManager,
    nodeManager,
    dragAndDropState: {
        dropCandidateState: { dropCandidate },
    },
    useDraggableConfiguration,
    childIndex,
    sortableOptions = {},
    wrapperClass = '',
    draggableConfigurationOverride,
}: EditorEngineNodeWrapperProps<TEditorEngineTypeInput>) => {
    const draggableConfiguration = useDraggableConfiguration();
    const canBeDragged = draggableConfiguration.canBeDragged({
        node,
        documentManager,
        nodeManager,
    });

    const { setNodeRef, ...rest } = useSortable({
        id: nodeManager.identify(node),
        disabled: !canBeDragged,
        data: {
            draggableConfiguration: draggableConfigurationOverride ?? draggableConfiguration,
        },
        ...sortableOptions,
    });

    const isTargetedByDrop =
        nodeManager.identify(dropCandidate?.preview.target) === nodeManager.identify(node);
    const dropPosition = dropCandidate?.preview.futurePosition;
    const dropOrientation = [
        EditorEngineDropPosition.Top,
        EditorEngineDropPosition.Bottom,
    ].includes(dropPosition)
        ? EditorEngineOrientation.Vertical
        : EditorEngineOrientation.Horizontal;
    const isBefore =
        isTargetedByDrop &&
        [EditorEngineDropPosition.Left, EditorEngineDropPosition.Top].includes(dropPosition);
    const isAfter =
        isTargetedByDrop &&
        [EditorEngineDropPosition.Right, EditorEngineDropPosition.Bottom].includes(dropPosition);

    return (
        <div
            className={cn('relative', wrapperClass)}
            ref={setNodeRef}
            key={nodeManager.identify(node)}
        >
            <EditorEngineDropPreview
                orientation={dropOrientation}
                position={dropPosition}
                visible={isBefore}
            />
            <EditorEngineNodeView
                id={nodeManager.identify(node)}
                Component={Component}
                document={documentManager.document}
                node={node}
                dragContext={{
                    setNodeRef,
                    ...rest,
                }}
                childIndex={childIndex}
                isPreview={false}
                targetInformation={
                    isTargetedByDrop
                        ? {
                              position: dropPosition,
                          }
                        : undefined
                }
                isDraggingDisabled={!canBeDragged}
            />
            <EditorEngineDropPreview
                orientation={dropOrientation}
                position={dropPosition}
                visible={isAfter}
            />
        </div>
    );
};
