import Navbar from '@/app/navigation/components/Navbar';
import ErrorScreen from '@/ui/components/ErrorScreen';

import RootLayout from './RootLayout';

export interface Props {
    title: string;
    headline: string;
    description: string;
    buttonText: string;
    onButtonClick: () => void;
}

const ErrorLayout = ({ title, headline, description, buttonText, onButtonClick }: Props) => {
    return (
        <RootLayout>
            <Navbar borderless />

            <div data-cy="404-page" className="mx-auto mt-12 max-w-screen-lg px-4 pb-24 md:px-8">
                <ErrorScreen
                    title={title}
                    headline={headline}
                    description={description}
                    buttonText={buttonText}
                    onButtonClick={onButtonClick}
                />
            </div>
        </RootLayout>
    );
};

export default ErrorLayout;
