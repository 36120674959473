import dynamic from 'next/dynamic';

import type { FieldInputProps } from 'react-final-form';
import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps | FieldInputProps<string>;
    submit: () => void;
}

const SidebarImageLibrary = dynamic(
    () => import('@/app/imageLibrary/components/SidebarImageLibrary'),
);

const ImageSrc = ({ input, submit }: Props) => {
    const { value, onChange } = input;

    const handleChange = (value: string) => {
        onChange(value);

        setTimeout(submit);
    };

    return <SidebarImageLibrary currentSrc={value} onChange={handleChange} />;
};

export default ImageSrc;
