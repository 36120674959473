import type { DndContextProps } from '@dnd-kit/core';

/**
 * Returns a function that handles the start of a drag operation.
 */
export const getOnDragStart = ({
    setDraggedNodeId,
}: {
    /**
     * A function for setting the ID of the node that is being dragged.
     */
    setDraggedNodeId: (nodeId: string) => void;
}) => {
    return (({ active }) => {
        setDraggedNodeId(active.id.toString());
    }) satisfies DndContextProps['onDragStart'];
};
