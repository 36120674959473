import { NAME, PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS } from '@/app/crm/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import {
    autoUpdate,
    flip,
    offset,
    useFloating,
    FloatingPortal,
    useDismiss,
    useInteractions,
} from '@floating-ui/react';
import { useTransition, animated } from '@react-spring/web';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Calendar } from 'react-date-range';

import { useDate } from '@/app/crm/hooks/useDate';
import { PropertyActionType } from '@/app/crm/types';
import { track } from '@/core/tracking';
import Navigation from '@/ui/components/DatePicker/Navigation';
import { cn } from '@/utils/cn';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';
import { smoothConfig } from '@/utils/spring/configs';

import { CopyButton } from './CopyButton';
import { InputSettings } from './TextInput/InputSettings';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import type { FieldRenderProps } from 'react-final-form';

export interface Props extends FieldRenderProps<string> {
    onSubmit: () => void;
}

const nowTimestamp = Date.now();

export const DateInput = ({ input, owner, fieldType, onSubmit, inline }: Props) => {
    const { t } = useTranslation(NAME);
    const campaignId = getCampaignIdFromRouter();
    const [open, setOpen] = useState(false);
    const transitions = useTransition(open, {
        from: { opacity: 0, y: -4 },
        enter: { opacity: 1, y: 0 },
        leave: { opacity: 0, y: -4 },
        config: smoothConfig,
        reverse: open,
    });

    const { DateString, formattedTime } = useDate({
        timestamp: input.value,
        tooltipFormatOptions: {
            weekday: inline ? 'short' : 'long',
            day: 'numeric',
            month: inline ? 'short' : 'long',
            year: 'numeric',
        },
    });

    const handleOpen = (event) => {
        event.stopPropagation();
        setOpen(!open);
    };

    const handleDateChange = (date: Date) => {
        input.onChange(date.toISOString());
        onSubmit();

        setOpen(false);
    };

    const handleCopy = () => {
        // Track 'Copy' click
        track(TRACKING_EVENTS.crm.profile.action, {
            campaign_id: campaignId,
            property_action_type: PropertyActionType.copy,
            crm_property_owner: owner,
            crm_property_data_type: fieldType,
        });
    };

    const { x, y, strategy, context, refs } = useFloating({
        open,
        onOpenChange: setOpen,
        middleware: [
            offset(4),
            flip({
                fallbackPlacements: ['top-end'],
                padding: 10,
            }),
        ],
        placement: 'bottom-end',
        whileElementsMounted: autoUpdate,
    });

    const dismiss = useDismiss(context, {
        bubbles: false,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    return (
        <div className="relative">
            <button
                className={cn(
                    'group relative flex w-full items-center overflow-hidden rounded-md p-2 text-left text-sm text-gray-600 ring-1 transition-all focus:bg-gray-50 hover:focus:bg-gray-50',
                    PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS,
                    {
                        'ring-blue-500': open,
                        'ring-transparent': !open,
                        'hover:bg-gray-200': inline,
                        'hover:bg-gray-100': !inline,
                    },
                )}
                onClick={handleOpen}
                ref={refs.setReference}
                {...getReferenceProps()}
            >
                {input.value ? (
                    <>
                        <div className="max-w-full flex-1 group-focus-within:pr-7 group-hover:pr-7">
                            <DateString
                                tooltipHidden
                                className={cn({
                                    truncate: inline,
                                })}
                            />
                        </div>

                        <InputSettings>
                            <CopyButton value={formattedTime} onCopy={handleCopy} />
                        </InputSettings>
                    </>
                ) : (
                    <span className="text-gray-400">{t('select-a-date')}</span>
                )}
            </button>

            {transitions((style, item) => {
                if (item) {
                    return (
                        <FloatingPortal>
                            <animated.div
                                ref={refs.setFloating}
                                style={{
                                    ...style,
                                    position: strategy,
                                    top: y ?? 0,
                                    left: x ?? 0,
                                }}
                                className="z-50 overflow-hidden rounded-lg border bg-white shadow-lg"
                                {...getFloatingProps()}
                            >
                                <Calendar
                                    date={input.value ? Date.parse(input.value) : nowTimestamp}
                                    onChange={handleDateChange}
                                    navigatorRenderer={(
                                        currentFocusedDate: Date,
                                        changeShownDate: Function,
                                    ) => (
                                        <Navigation
                                            className="mb-2 flex items-center px-4"
                                            currentFocusedDate={currentFocusedDate}
                                            changeShownDate={changeShownDate}
                                        />
                                    )}
                                    className={cn('p-0', PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS)}
                                />
                            </animated.div>
                        </FloatingPortal>
                    );
                }

                return null;
            })}
        </div>
    );
};
