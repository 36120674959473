import { cn } from '@/utils/cn';

import Logo from './Logo';

import type { ReactNode } from 'react';

export interface Props {
    borderless?: boolean;
    transparent?: boolean;
    homeLinkDisabled?: boolean;
    isWorkspaceSubpage?: boolean;
    children?: ReactNode;
    onLogoClick?: () => void;
}

const Navbar = ({
    children,
    borderless,
    transparent,
    homeLinkDisabled,
    isWorkspaceSubpage,
    onLogoClick,
}: Props) => {
    const navClasses = cn('flex h-16 items-center px-3', {
        'border-b': !borderless,
        'bg-white': !transparent,
    });

    return (
        <div className="relative z-50">
            <nav className={navClasses}>
                <Logo
                    isWorkspaceSubpage={isWorkspaceSubpage}
                    homeLinkDisabled={homeLinkDisabled}
                    transparent={transparent}
                    allowXmasLogo
                    onClick={onLogoClick}
                />
                <div className="flex flex-1 items-center justify-end">{children}</div>
            </nav>
        </div>
    );
};

export default Navbar;
