import { rectSortingStrategy, verticalListSortingStrategy } from '@dnd-kit/sortable';

import Box from '@/app/editor/blocks/components/_wrapper/Box';
import Text from '@/app/editor/blocks/components/Text/Component';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { BlockComponentType } from '@/app/editor/blocks/types';
import { PerspectiveEditorEngineNodeTypeRenderer } from '@/app/editor/engine/components/PerspectiveEditorEngineNodeTypeRenderer';
import { PerspectiveEditorEnginePreviewAwareSequenceRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareSequenceRenderer';
import { DraggableConfigurationHelper } from '@/app/editor/engine/core/utils/dragAndDrop/configuration';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';

import type { Alignment, DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { RawDraftContentState } from 'draft-js';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    align: Alignment;
    color: string;
    fieldName: string;
    wysiwyg: RawDraftContentState;
}

const Component: FC<Props> = ({
    artBoardIndex,
    box,
    blockId,
    wysiwyg,
    align,
    color,
    activeTheme,
    isPreview,
    isDragged,
}) => {
    const { isVerticalOnly, isInColumn } = useLayoutInfo(blockId, isPreview);
    const wrapperClass = isVerticalOnly ? 'flex flex-col space-y-4' : 'grid grid-cols-2 gap-4';

    return (
        <Box
            box={getBoxAttributes({ box, isInColumn })}
            isDragged={isDragged}
            artBoardIndex={artBoardIndex}
        >
            <div className="mb-6">
                <Text
                    align={align}
                    wysiwyg={wysiwyg}
                    color={getThemedOrCustomColor(color, 'fontColor', activeTheme)}
                    blockId={blockId}
                />
            </div>

            <PerspectiveEditorEnginePreviewAwareSequenceRenderer
                parentId={blockId}
                className={wrapperClass}
                filter={({ block }) => block.attributes.componentType !== BlockComponentType.BUTTON}
                propsFromParent={{ isMultipleChoiceAnswer: true }}
                sortingStrategy={isVerticalOnly ? verticalListSortingStrategy : rectSortingStrategy}
            />

            <div className="mt-4">
                <PerspectiveEditorEngineNodeTypeRenderer
                    parentId={blockId}
                    componentType={BlockComponentType.BUTTON}
                    draggableConfiguration={DraggableConfigurationHelper.alwaysDisable}
                />
            </div>
        </Box>
    );
};

Component.displayName = 'QuestionMultipleChoice';

export default Component;
