import { PerspectiveEditorEnginePreviewAwareNodeRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareNodeRenderer';
import { PropsFromParentOverrideProvider } from '@/app/editor/engine/core/components/view/PropsFromParentOverrideProvider';
import { DraggableConfigurationHelper } from '@/app/editor/engine/core/utils/dragAndDrop/configuration';
import { cn } from '@/utils/cn';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {}

const NewComponent: FC<Props> = ({
    componentType,
    align,
    borderRadius,
    visualSize,
    globalSize,
    blockId,
}) => {
    const imageProps = {
        align,
        wrapperClass: 'w-full',
        borderRadius,
        visualSize,
    };

    return (
        <>
            <PerspectiveEditorEnginePreviewAwareNodeRenderer
                parentId={blockId}
                draggableConfiguration={DraggableConfigurationHelper.alwaysDisable}
                renderer={({ nodes, renderNode }) => {
                    return align === 'left' ? (
                        <div className="flex items-start p-1">
                            <div
                                className={cn(
                                    'flex-shrink-0 transition-all duration-200 ease-out',
                                    {
                                        'w-12': visualSize === 'small' || !visualSize,
                                        'w-16': visualSize === 'medium',
                                        'w-20': visualSize === 'large',
                                        'w-24': visualSize === 'xLarge',
                                    },
                                )}
                            >
                                <PropsFromParentOverrideProvider propsFromParent={imageProps}>
                                    {renderNode({
                                        node: nodes[0],
                                    })}
                                </PropsFromParentOverrideProvider>
                            </div>
                            <div className="ml-6 min-w-0 self-center">
                                <PropsFromParentOverrideProvider
                                    propsFromParent={{
                                        fixedAlign: 'left',
                                        parentComponentType: componentType,
                                        globalSize,
                                    }}
                                >
                                    {renderNode({
                                        node: nodes[1],
                                    })}
                                </PropsFromParentOverrideProvider>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center justify-center p-1">
                            <div
                                className={cn('transition-all duration-200 ease-out', {
                                    'w-12': visualSize === 'small' || !visualSize,
                                    'w-[120px]': visualSize === 'medium',
                                    'w-48': visualSize === 'large',
                                    'w-[264px]': visualSize === 'xLarge',
                                })}
                            >
                                <PropsFromParentOverrideProvider propsFromParent={imageProps}>
                                    {renderNode({
                                        node: nodes[0],
                                    })}
                                </PropsFromParentOverrideProvider>
                            </div>
                            <div className="mt-4 min-w-0">
                                <PropsFromParentOverrideProvider
                                    propsFromParent={{
                                        fixedAlign: 'center',
                                        parentComponentType: componentType,
                                        globalSize,
                                    }}
                                >
                                    {renderNode({
                                        node: nodes[1],
                                    })}
                                </PropsFromParentOverrideProvider>
                            </div>
                        </div>
                    );
                }}
            />
        </>
    );
};

NewComponent.displayName = 'ListItem';

export default NewComponent;
