import { NAME } from '@/app/crm/constants';

import { createSelector, createSlice } from '@reduxjs/toolkit';

import { updateContactValue } from '@/app/crm/models/contacts';
import { handleRuntimeError } from '@/core/api';
import { EMPTY_ARRAY } from '@/utils/empty';

import type { Uploader } from './Uploader';
import type { UploadState } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

export type FileUpload = {
    contactId: string;
    fieldName: string;
    state: UploadState;
};

interface State {
    uploads: FileUpload[];
}

const initialState: State = {
    uploads: [],
};

export const fileUploadsSlice = createSlice({
    name: `${NAME}/fileUploads`,
    initialState,
    reducers: {
        updateUploadState(state, action: PayloadAction<FileUpload>) {
            const newUploads = [...state.uploads];

            const stateToUpdateIndex = newUploads.findIndex(
                ({ contactId, fieldName }) =>
                    contactId === action.payload.contactId &&
                    fieldName === action.payload.fieldName,
            );

            // Replaces state if already present
            if (stateToUpdateIndex !== -1) {
                newUploads[stateToUpdateIndex] = action.payload;
            } else {
                // Otherwise it adds the state as a new state
                newUploads.push(action.payload);
            }

            return {
                ...state,
                uploads: newUploads,
            };
        },
        removeUploadState(
            state,
            action: PayloadAction<Pick<FileUpload, 'contactId' | 'fieldName'>>,
        ) {
            return {
                ...state,
                uploads: state.uploads.filter(
                    ({ contactId, fieldName }) =>
                        !(
                            contactId === action.payload.contactId &&
                            fieldName === action.payload.fieldName
                        ),
                ),
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { updateUploadState, removeUploadState, reset } = fileUploadsSlice.actions;

// === Selectors ======

export const getUploads = (state: AppState) =>
    state[NAME]?.fileUploadsReducer.uploads || EMPTY_ARRAY;

export const getUploadState = (contactId: string, fieldName: string) =>
    createSelector(getUploads, (uploads) => {
        return uploads.find(
            (upload) => upload.contactId === contactId && upload.fieldName === fieldName,
        );
    });

// === Thunks ======

type UploadFileParams = {
    file: File;
    campaignId: string;
    contactId: string;
    fieldName: string;
};

// upload image and add to folder
export const uploadFile =
    (uploader: Uploader, { file, campaignId, contactId, fieldName }: UploadFileParams): AppThunk =>
    async (dispatch) => {
        dispatch(
            updateUploadState({
                contactId,
                fieldName,
                state: {
                    status: 'uploading',
                    filename: file.name,
                    filesize: file.size,
                },
            }),
        );

        try {
            const uploadedFileUrl = await uploader.upload(file);

            dispatch(
                updateContactValue({
                    contactId,
                    fieldName,
                    newValue: uploadedFileUrl,
                    campaignId,
                }),
            );

            dispatch(
                removeUploadState({
                    contactId,
                    fieldName,
                }),
            );
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'uploading image failed:' });
        }
    };

export default fileUploadsSlice.reducer;
