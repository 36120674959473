import {
    type EditorEngineDefaultTypeInput,
    EditorEngineHistoryEntryRecordedActionStatusType,
    EditorEngineHistoryEntryRecordedStepStatusType,
} from '@/app/editor/engine/core/types';
import { guid } from '@/app/editor/engine/utils/guid';

import type { EditorEngineAction, EditorEngineHistoryEntry } from '@/app/editor/engine/core/types';

/**
 * Given an action that has been enqueued, create a new history entry.
 *
 * This will set up the records for both optimistic and persisted updates,
 * and create a unique ID for the entry and all of the records.
 */
export const makeNewEntry = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
    TTransactionPreparationResult extends object = object,
>({
    action,
    documentManager,
    nodeManager,
    extraContext,
    transactionPreparationResult,
}: {
    /**
     * The action that has been enqueued.
     */
    action: EditorEngineAction<TEditorEngineTypeInput, TTransactionPreparationResult>;
    /**
     * The document manager.
     */
    documentManager: TEditorEngineTypeInput['DocumentManager'];
    /**
     * The node manager.
     */
    nodeManager: TEditorEngineTypeInput['NodeManager'];
    /**
     * Extra context to pass to step getters.
     */
    extraContext: TEditorEngineTypeInput['ExtraContext'];
    /**
     * The result of the transaction preparation to be passed to the
     * `getTransaction` method of the action.
     */
    transactionPreparationResult: TTransactionPreparationResult;
}) => {
    return {
        id: guid(),
        action,
        recordedAction: {
            id: guid(),
            action,
            executionResult: {
                type: EditorEngineHistoryEntryRecordedActionStatusType.NotExecuted,
            } as const,
        },
        recordedSteps: action.getTransaction(transactionPreparationResult).map((stepGetter) => {
            const step = stepGetter({
                documentManager,
                nodeManager,
                extraContext,
            });

            return {
                id: guid(),
                transactionStep: step,
                executionResult: {
                    type: EditorEngineHistoryEntryRecordedStepStatusType.NotExecuted,
                } as const,
            };
        }),
    } satisfies EditorEngineHistoryEntry<TEditorEngineTypeInput>;
};
