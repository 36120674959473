import { NAME } from '@/app/editor/blocks/constants';
import { HELP_TOOLTIP_CONTENT } from '@/app/helpTooltips/constants';

import { useTranslation } from 'next-i18next';
import { Field, getFormValues, reduxForm } from 'redux-form';

import BoxSettings from '@/app/editor/blocks/components/_helper/BoxSettings';
import { getWebinarPreviewByBlockId, setWebinarPreview } from '@/app/editor/blocks/models/webinar';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import { ColorPickerHistoryProvider } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color/ColorPickerHistoryProvider';
import DurationInput from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/DurationInput';
import Linking from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Linking';
import VideoUrl from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/VideoUrl';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import {
    getColorByLightness,
    getThemeColor,
    getThemedOrCustomColor,
} from '@/app/editor/themes/helpers';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import ToggleButton from '@/ui/components/_BlockEditFields/ToggleButton';

import type { DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { BlockResource } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({
    handleSubmit,
    activeTheme,
    initialValues,
    pageId,
    blockId,
    campaignId,
}) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const previewActive = useAppSelector((state) => getWebinarPreviewByBlockId(state, blockId));
    const formValues = useAppSelector((state) => getFormValues(blockId)(state) as BlockResource);

    const backgroundColor = getThemedOrCustomColor(
        formValues?.attributes?.content?.backgroundColor,
        'buttonBackgroundColor',
        activeTheme,
    );

    const calculatedTextColor = getColorByLightness(
        formValues?.attributes?.content?.color,
        backgroundColor,
    );

    const handlePreviewToggle = (checked: boolean) => {
        dispatch(setWebinarPreview({ blockId, visible: checked }));
    };

    return (
        <>
            <Field
                name="attributes.content.video"
                placeholder="https://youtube.com/..."
                component={VideoUrl}
                submit={handleSubmit}
                allowedServices={['youtube', 'vimeo', 'youtu']}
            />

            <div className="mt-2">
                <Field
                    name="attributes.content.duration"
                    component={DurationInput}
                    submit={handleSubmit}
                    videoUrl={initialValues.attributes.content.video}
                />
            </div>

            <div className="grid grid-cols-3 gap-2">
                <Field
                    name="attributes.content.color"
                    component={Color}
                    expand="right"
                    submit={handleSubmit}
                    themeColor={calculatedTextColor}
                    tooltip={t('font-color')}
                />

                <ColorPickerHistoryProvider>
                    <Field
                        name="attributes.content.backgroundColor"
                        component={Color}
                        expand="center"
                        submit={handleSubmit}
                        themeColor={getThemeColor(activeTheme, 'buttonBackgroundColor')}
                        tooltip={t('button-color')}
                        hasGradient
                    />
                </ColorPickerHistoryProvider>

                <ToggleButton
                    checked={previewActive}
                    onToggle={handlePreviewToggle}
                    icon="eye"
                    tooltip={t('show-preview')}
                />
            </div>

            <BoxSettings blockId={blockId} activeTheme={activeTheme} handleSubmit={handleSubmit} />

            <Subheading text={t('linking')} helpTooltip={HELP_TOOLTIP_CONTENT.EDIT_FORM_LINKING} />

            <div className="mt-4">
                <Field
                    name="attributes.content.linking"
                    component={Linking}
                    pageId={pageId}
                    campaignId={campaignId}
                    submit={handleSubmit}
                    blockId={blockId}
                />
            </div>
        </>
    );
};

Form.displayName = 'WebinarEditForm';

export default reduxForm({})(Form);
