import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { cn } from '@/utils/cn';
import { stopPropagation } from '@/utils/common';

import { modalMap } from '../../map';

export interface Props {
    type: string;
    props: any;
    visible: boolean;
    className?: string;
    overlayClassName?: string;
    flexClassName?: string;
    shouldCloseOnOverlayClick?: boolean;
    onClose?: (event: PointerEvent | KeyboardEvent) => void;
}

export const BaseModalView = ({
    type,
    props,
    visible,
    className,
    overlayClassName,
    flexClassName,
    shouldCloseOnOverlayClick = true,
    onClose,
}: Props) => {
    const Modal = modalMap[type];
    const isOpen = visible && !!Modal;

    const ref = useDetectClickOutside({
        onTriggered: onClose,
        disableClick: !shouldCloseOnOverlayClick,
    });

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <div className="relative z-modal">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className={cn('fixed inset-0 bg-gray-800/50', overlayClassName)} />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-200"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className={cn(
                                'flex h-screen items-center justify-center',
                                flexClassName,
                            )}
                        >
                            <div
                                className={cn('w-full sm:p-4', className)}
                                ref={ref}
                                onClick={stopPropagation}
                            >
                                {Modal ? <Modal {...props} onClose={onClose} /> : null}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </div>
        </Transition>
    );
};
