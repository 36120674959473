import { NAME, PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS } from '@/app/crm/constants';

import { FloatingPortal } from '@floating-ui/react';
import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { animated } from '@react-spring/web';
import isArray from 'lodash/isArray';
import { useTranslation } from 'next-i18next';

import { useFloatingDropdown } from '@/app/crm/hooks/useFloatingDropdown';
import { cn } from '@/utils/cn';

import { AnswerPill } from './AnswerPill';
import { AnswerPills } from './AnswerPills';

import type { SelectOption, PropertyFieldProps } from '@/app/crm/types';
import type { MouseEvent } from 'react';

export interface Props extends PropertyFieldProps {
    options: SelectOption[];
    selected: string[] | string;
    open: boolean;
    isMultiSelect?: boolean;
    handleDeselectOption?: (index: number) => (event: MouseEvent) => void;
}

export const ListBoxDropdown = ({
    options,
    selected,
    open,
    isMultiSelect,
    handleDeselectOption,
    inline,
}: Props) => {
    const { t } = useTranslation(NAME);

    const { x, y, strategy, refs, transitions } = useFloatingDropdown(open);

    return (
        <>
            <Listbox.Button
                className={cn('group flex w-full rounded-lg p-2 text-sm ring-1 transition-colors', {
                    'bg-gray-100 ring-blue-500': open,
                    'ring-transparent': !open,
                    'text-gray-600 hover:bg-gray-200 hover:text-gray-900': inline,
                    'text-gray-700 hover:bg-gray-100 hover:text-gray-900': !inline,
                })}
                ref={refs.setReference}
            >
                {selected.length > 0 ? (
                    isMultiSelect && isArray(selected) ? (
                        <AnswerPills
                            inline={inline}
                            selected={selected}
                            handleDeselectOption={handleDeselectOption}
                        />
                    ) : (
                        <AnswerPill inline={inline}>{selected as string}</AnswerPill>
                    )
                ) : (
                    <span className="text-gray-400">{t('select-an-option')}</span>
                )}
            </Listbox.Button>

            {transitions((style, visible) => {
                if (visible) {
                    return (
                        <FloatingPortal>
                            <animated.div
                                ref={refs.setFloating}
                                className="z-50"
                                style={{
                                    ...style,
                                    position: strategy,
                                    top: y ?? 0,
                                    left: x ?? 0,
                                    width: 'max-content',
                                }}
                            >
                                <Listbox.Options
                                    className={cn(
                                        'max-h-80 w-full overflow-auto rounded-lg border bg-white p-2 text-sm shadow-lg',
                                        PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS,
                                    )}
                                >
                                    {options.map((option, index) => {
                                        const isSelected = selected.includes(option.value);

                                        return (
                                            <Listbox.Option
                                                key={`${option.value}-${index}`}
                                                value={option.value}
                                                className={cn(
                                                    'flex cursor-pointer items-center rounded-lg px-3 py-2 text-gray-500 hover:bg-gray-100',
                                                    PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS,
                                                    {
                                                        'text-gray-900': isSelected,
                                                    },
                                                )}
                                            >
                                                <CheckIcon
                                                    className={cn('mr-1 size-4 text-blue-500', {
                                                        invisible: !isSelected,
                                                    })}
                                                />
                                                <span>{option.value}</span>
                                            </Listbox.Option>
                                        );
                                    })}
                                </Listbox.Options>
                            </animated.div>
                        </FloatingPortal>
                    );
                }
            })}
        </>
    );
};
