import { createSlice } from '@reduxjs/toolkit';

import { getCampaignName } from '@/app/campaigns/helpers';
import { addSetCampaigns, fetchCampaigns } from '@/app/campaigns/models/campaigns';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import { NAME } from '../constants';

import type { CampaignResource } from '@/app/campaigns/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState } from '@/core/redux/types';
import type { AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = {
    loading: false,
};

export const restoreSlice = createSlice({
    name: `${NAME}/restore`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = restoreSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.restoreReducer?.loading;

// === Thunks ======

// Restore a funnel
export const restoreCampaign =
    (campaignId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiPost<ResponseData<CampaignResource>>(
                `/campaigns/${campaignId}/restore`,
                {},
            );
            const restoredCampaign = getDataFromResponse(response);

            dispatch(addSetCampaigns({ [restoredCampaign.id]: restoredCampaign }));

            showToast({
                type: 'success',
                message: `${NAME}:restore-success-message`,
                description: getCampaignName(restoredCampaign),
            });

            await dispatch(fetchCampaigns());
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'restoring campaign failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default restoreSlice.reducer;
