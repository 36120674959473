export const NAME = 'workspaces';
export const NUMBER_OF_SETUP_STEPS = 3;
export const DEFAULT_AVATAR_LETTER = 'A';
export const WORKSPACE_DOMAIN_HOST = process.env.NEXT_PUBLIC_WORKSPACE_DOMAIN;
export const FUNNEL_DEBOUNCE_TIMEOUT = 200;
export const HAS_CLOSED_WORKSPACE_SETTINGS_BADGE = 'has-closed-workspace-settings-badge';
export const MAX_INVITES_COUNT = 10;
export const CREATE_WORKSPACE_PATH = '/workspaces/new';
export const MAX_WORKSPACE_NAME_LENGTH = 40;
export const REPEATED_HYPHEN_REGEX = /--/;
export const WS_LOGO_MAX_RENDERED_SIZE = 200;
export const WS_LOGO_MAX_UPLOAD_SIZE = 3000;
export const LS_LAST_ACTIVE_WORKSPACE_ID_KEY = 'lastActiveWorkspaceId';
export const THREE_SECONDS_IN_MS = 3000;

export const ENDPOINTS = {
    GET: {
        workspacesWithKpis: '/workspaces?kpis=true',
    },
} as const;

export const QUERY_KEYS = {
    workspacesWithKpis: ['workspaces', 'kpis'],
} as const;
