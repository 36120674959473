import { COLUMN_TEMPLATE_NAME } from '@/app/editor/engine/constants';

import { useCallback, useMemo } from 'react';
import { useStore } from 'react-redux';
import { initialize } from 'redux-form';

import { getActiveCampaign } from '@/app/campaigns/models/campaigns';
import { getCompanyId } from '@/app/company/models/company';
import {
    getActiveBlock,
    getBlockParent,
    setActiveBlockId,
} from '@/app/editor/blocks/models/blocks';
import { getClipboardBlockId } from '@/app/editor/blocks/models/duplicate';
import { setPreviousContent } from '@/app/editor/blocks/models/tiptap';
import { setActiveView as reduxSetActiveView } from '@/app/editor/editor/models/sidebar';
import { useDocumentStateManager } from '@/app/editor/engine/core/hooks/manager/useDocumentStateManager';
import { getGetPreviewBlockPayloadsRecursively } from '@/app/editor/engine/hooks/usePerspectiveDocumentManager/getGetPreviewBlockPayloadsRecursively';
import { getMakeBlockFromPreview } from '@/app/editor/engine/hooks/usePerspectiveDocumentManager/getMakeBlockFromPreview';
import { getMakeColumn } from '@/app/editor/engine/hooks/usePerspectiveDocumentManager/getMakeColumn';
import { getMakeLayout } from '@/app/editor/engine/hooks/usePerspectiveDocumentManager/getMakeLayout';
import { getActivePage } from '@/app/editor/pages/models/pages';
import { setActivePage as reduxSetActivePage } from '@/app/editor/pages/models/pages';
import {
    getActiveSectionPreviewId,
    getInsertIndex,
    getPreviewBlockId,
    getUsingSection,
    setUsingSection as reduxSetUsingSection,
    reset,
    getActiveSectionId,
    getInsertingInBlankColumnId,
} from '@/app/editor/sections/models/insert';
import {
    getPreviewById as reduxGetPreviewById,
    getSectionByName,
} from '@/app/editor/sections/models/sections';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { SidebarViewId } from '@/app/editor/editor/types';
import type {
    EditorEngineDocumentManagerHook,
    InsertNodeAtPayload,
} from '@/app/editor/engine/core/types';
import type {
    PerspectiveEditorEngineNestedInsertNodeAtPayload,
    PerspectiveEditorEngineDocument,
    PerspectiveEditorEngineDocumentManagerProps,
    PerspectiveEditorEngineNodeData,
    PerspectiveEditorEngineExtraContext,
} from '@/app/editor/engine/types';
import type { AppState } from '@/core/redux/types';
import type { JSONContent } from '@tiptap/core';

/**
 * Returns the Perspective Editor document manager.
 *
 * At the moment this is a monolithic hook that does a lot of things. Since the
 * perspective editor is still coupled to redux, I have decided to keep all
 * selectors and dispatch calls in this hook. Ideally, all of the APIs here
 * would move to more suited hooks once their are a bit more stable.
 *
 * Once the Perspective Editor is fully functioning in production, we can slowly
 * start moving these APIs out or switching them for less complex alternatives.
 *
 * Additionally, some things are done by hand, like the creation of columns and
 * layouts. In the old Editor, columns and new layouts aren't shown until a
 * backend response exists. Layouts do appear, but only because we receive
 * preview blocks from the backend. Strictly spieaking, we do not have the
 * structure of a "generic layout" to be used in any situation, and that's why
 * I have copied the structure of a layout into this file for the times in which
 * we need to create one.
 */
export const usePerspectiveDocumentManager = (() => {
    const originalDocumentManager = useDocumentStateManager<PerspectiveEditorEngineDocument>({
        initialDocument: {},
    });
    const dispatch = useAppDispatch();
    const store = useStore<AppState>();
    const companyId = useAppSelector(getCompanyId);
    const activeFunnel = useAppSelector(getActiveCampaign);
    const activePage = useAppSelector(getActivePage);
    const activeSectionPreviewId = useAppSelector(getActiveSectionPreviewId);
    const columnSection = useAppSelector((state) => getSectionByName(state, COLUMN_TEMPLATE_NAME));
    const previewIndex = useAppSelector(getInsertIndex);
    const previewBlockId = useAppSelector(getPreviewBlockId);
    const previewParent = useAppSelector((state) => getBlockParent(state, previewBlockId));
    const activeBlock = useAppSelector(getActiveBlock);
    const clipboardBlockId = useAppSelector(getClipboardBlockId);
    const activeSectionId = useAppSelector(getActiveSectionId);
    const insertingInEmptyColumnId = useAppSelector(getInsertingInBlankColumnId);

    const isUsingSection = useCallback(() => {
        return getUsingSection(store.getState());
    }, [store]);

    const setUsingSection = useCallback(
        (usingSection: boolean) => {
            dispatch(reduxSetUsingSection(usingSection));
        },
        [dispatch],
    );

    const clearPreview = useCallback(() => {
        dispatch(reset());
        dispatch(setActiveBlockId(''));
        dispatch(reduxSetActiveView('pages'));
    }, [dispatch]);

    const setTipTapPreviousContent = useCallback(
        ({ id, wysiwyg }: { id: string; wysiwyg: JSONContent }) => {
            dispatch(
                setPreviousContent({
                    blockId: id,
                    content: wysiwyg,
                }),
            );
        },
        [dispatch],
    );

    const initializeForm = useCallback(
        ({ id, values }: { id: string; values: BlockResource }) => {
            dispatch(initialize(id, values));
        },
        [dispatch],
    );

    const makeLayout = useMemo(() => {
        return getMakeLayout({
            activePageId: activePage?.id,
            activeFunnelId: activeFunnel?.id,
            companyId,
        });
    }, [activePage, activeFunnel, companyId]);

    const makeColumn = useMemo(() => {
        return getMakeColumn({
            activePageId: activePage?.id,
            activeFunnelId: activeFunnel?.id,
            companyId,
        });
    }, [activePage, activeFunnel, companyId]);

    const getPreviewById = useCallback(
        (sectionId: string) => {
            return reduxGetPreviewById(store.getState(), sectionId);
        },
        [store],
    );

    const getActivePreview = useCallback(() => {
        return getPreviewById(activeSectionPreviewId);
    }, [activeSectionPreviewId, getPreviewById]);

    const getPreviewInfo = useCallback(() => {
        return {
            activePreview: getActivePreview(),
            previewParent,
            previewIndex,
            activeSectionId,
        };
    }, [getActivePreview, previewParent, previewIndex, activeSectionId]);

    const makeBlockFromPreview = useMemo(() => {
        return getMakeBlockFromPreview({
            activeFunnelId: activeFunnel?.id,
            activePageId: activePage?.id,
            companyId,
            getPreviewById,
        });
    }, [activeFunnel, activePage, companyId, getPreviewById]);

    const getPreviewBlockPayloadsRecursively = useMemo(() => {
        return getGetPreviewBlockPayloadsRecursively({
            getPreviewById,
            makeBlockFromPreview,
        });
    }, [getPreviewById, makeBlockFromPreview]);

    const makeNewNodePayloadsRecursivelyFromPreview = useCallback(() => {
        const previewParentId = previewParent?.id || 'root';
        const parentId = insertingInEmptyColumnId ? previewBlockId : previewParentId;
        const index = insertingInEmptyColumnId ? 0 : previewIndex;

        const tree = getPreviewBlockPayloadsRecursively({
            previewId: activeSectionPreviewId,
            parentId,
            index,
        });

        const payloads: InsertNodeAtPayload<PerspectiveEditorEngineNodeData>[] = [];

        const flatten = (block: PerspectiveEditorEngineNestedInsertNodeAtPayload) => {
            payloads.push(block.payload);
            block.children.forEach(flatten);
        };

        flatten(tree);

        return {
            tree,
            payloads,
        };
    }, [activeSectionPreviewId, previewParent, previewIndex, getPreviewBlockPayloadsRecursively]);

    const setActiveView = useCallback(
        (viewId: SidebarViewId) => {
            dispatch(reduxSetActiveView(viewId));
        },
        [dispatch],
    );

    const setActivePage = useCallback(
        (pageId: string) => {
            dispatch(reduxSetActivePage(activePage?.relationships?.campaign?.data?.id, pageId));
        },
        [dispatch, activePage],
    );

    const scrollToBlock = useCallback((blockId: string) => {
        setTimeout(() => {
            const blockElement = document.getElementById(blockId);

            if (blockElement) {
                blockElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
            }
        });
    }, []);

    const setActiveBlock = useCallback(
        (blockId: string) => {
            dispatch(setActiveBlockId(blockId));
        },
        [dispatch],
    );

    return {
        ...originalDocumentManager,
        activeFunnel,
        activePage,
        columnSection,
        activeBlock,
        isUsingSection,
        setUsingSection,
        initializeForm,
        setTipTapPreviousContent,
        clearPreview,
        makeLayout,
        makeColumn,
        getPreviewById,
        getActivePreview,
        getPreviewInfo,
        getPreviewBlockPayloadsRecursively,
        makeBlockFromPreview,
        makeNewNodePayloadsRecursivelyFromPreview,
        setActiveView,
        setActivePage,
        scrollToBlock,
        clipboardBlockId,
        setActiveBlock,
    };
}) satisfies EditorEngineDocumentManagerHook<
    PerspectiveEditorEngineDocument,
    PerspectiveEditorEngineExtraContext,
    PerspectiveEditorEngineDocumentManagerProps
>;
