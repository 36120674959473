import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getUserEmail } from '@/app/user/models/user';
import {
    cancelInviteRequest,
    checkEmailRequests,
    deleteWorkspaceUserRequest,
    getWorkspaceInvitationsRequest,
    getWorkspaceUsersRequest,
    sendInvitesRequest,
} from '@/app/workspaces/helpers/apis';
import { RequestState } from '@/app/workspaces/types';
import { handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY } from '@/utils/empty';

import { getActiveWorkspaceId } from './workspaces';
import { NAME } from '../constants';

import type { UserResource } from '@/app/user/types';
import type { WorkspaceInvitationResource } from '@/app/workspaces/types';
import type { CheckEmailResponse } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

type RequestType =
    | 'sendInvites'
    | 'cancelInvite'
    | 'checkEmail'
    | 'getInvites'
    | 'getUsers'
    | 'deleteUser';

interface State {
    users: UserResource[];
    invites: WorkspaceInvitationResource[];
    requestState: Partial<Record<RequestType, RequestState>>;
}

const initialState: State = {
    users: EMPTY_ARRAY,
    invites: EMPTY_ARRAY,
    requestState: {
        checkEmail: RequestState.Idle,
        sendInvites: RequestState.Idle,
        cancelInvite: RequestState.Idle,
        getInvites: RequestState.Idle,
        getUsers: RequestState.Idle,
        deleteUser: RequestState.Idle,
    },
};

export const setupSlice = createSlice({
    name: `${NAME}/users`,
    initialState,
    reducers: {
        setUsers(state, action: PayloadAction<State['users']>) {
            state.users = action.payload;
        },
        setInvites(state, action: PayloadAction<State['invites']>) {
            state.invites = action.payload;
        },
        setRequestState(state, action: PayloadAction<State['requestState']>) {
            state.requestState = {
                ...state.requestState,
                ...action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setUsers, setInvites, setRequestState, reset } = setupSlice.actions;

// === Selectors ======

export const getIsSendingInvites = (state: AppState) =>
    state.workspaces.usersReducer.requestState.sendInvites === RequestState.InProgress;

export const getIsCheckingEmail = (state: AppState) =>
    state.workspaces.usersReducer.requestState.checkEmail === RequestState.InProgress;

export const getIsFetchingInvites = (state: AppState) =>
    state.workspaces.usersReducer.requestState.getInvites === RequestState.InProgress;

export const getLoadingInvites = (state: AppState) => {
    return (
        getIsFetchingInvites(state) &&
        state.workspaces.usersReducer.requestState.sendInvites === RequestState.Idle &&
        state.workspaces.usersReducer.requestState.cancelInvite === RequestState.Idle
    );
};

export const getIsFetchingUsers = (state: AppState) =>
    state.workspaces.usersReducer.requestState.getUsers === RequestState.InProgress;

export const getLoadingUsers = (state: AppState) => {
    return (
        getIsFetchingUsers(state) &&
        state.workspaces.usersReducer.requestState.deleteUser === RequestState.Idle
    );
};

export const getInvites = (state: AppState): WorkspaceInvitationResource[] => {
    return state.workspaces.usersReducer?.invites ?? EMPTY_ARRAY;
};

export const getWorkspaceUsers = (state: AppState): State['users'] => {
    const email = getUserEmail(state);

    return (
        state.workspaces.usersReducer?.users?.filter(
            ({ attributes }) => email !== attributes.email,
        ) ?? EMPTY_ARRAY
    );
};

export const getWorkspaceUserByEmail = (email: string) =>
    createSelector(getWorkspaceUsers, (users) => {
        return users.find((user) => user.attributes.email === email);
    });

export const getActiveInvites = createSelector(getInvites, (invites) => {
    return invites.filter((invite) => invite.attributes.status !== 'cancelled');
});

export const geAllUsersCount = createSelector(getInvites, getWorkspaceUsers, (invites, users) => {
    const inviteCount = invites?.length ?? 0;
    const userCount = users?.length ?? 0;

    return inviteCount + userCount;
});

// === Thunk ======

export const fetchWorkspaceUsers =
    (workspaceId: string): AppThunk =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                getUsers: RequestState.InProgress,
            }),
        );

        try {
            const data = getDataFromResponse(await getWorkspaceUsersRequest(workspaceId));

            dispatch(setUsers(data));
            dispatch(
                setRequestState({
                    getUsers: RequestState.Success,
                }),
            );
        } catch (error) {
            handleRuntimeError(error as AxiosError<{ message: string }>, {
                debugMessage: 'error getting workspace users',
            });

            dispatch(
                setRequestState({
                    getUsers: RequestState.Error,
                }),
            );
        }
    };

export const deleteWorkspaceUser =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        const workspaceId = getActiveWorkspaceId(getState());

        dispatch(
            setRequestState({
                deleteUser: RequestState.InProgress,
            }),
        );

        try {
            await deleteWorkspaceUserRequest(workspaceId, id);
            await dispatch(fetchWorkspaceUsers(workspaceId));

            dispatch(
                setRequestState({
                    deleteUser: RequestState.Success,
                }),
            );
        } catch (err) {
            handleRuntimeError(err as AxiosError<{ message: string }>, {
                debugMessage: 'error deleting workspace user',
            });

            dispatch(
                setRequestState({
                    deleteUser: RequestState.Error,
                }),
            );
        }
    };

export const fetchInvites =
    (workspaceId: string): AppThunk =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                getInvites: RequestState.InProgress,
            }),
        );

        try {
            const response = getDataFromResponse(await getWorkspaceInvitationsRequest(workspaceId));

            dispatch(setInvites(response));
            dispatch(
                setRequestState({
                    getInvites: RequestState.Success,
                }),
            );
        } catch (error) {
            handleRuntimeError(error, { debugMessage: 'fetching invites failed:' });

            dispatch(
                setRequestState({
                    getInvites: RequestState.Error,
                }),
            );
        }
    };

export const checkEmail =
    (email: string): AppThunk<Promise<Partial<CheckEmailResponse>>> =>
    async (dispatch, getState) => {
        const workspaceId = getActiveWorkspaceId(getState());

        dispatch(
            setRequestState({
                checkEmail: RequestState.InProgress,
            }),
        );

        try {
            const result = getDataFromResponse(await checkEmailRequests(workspaceId, email));

            dispatch(
                setRequestState({
                    checkEmail: RequestState.Success,
                }),
            );

            return result;
        } catch (error) {
            dispatch(
                setRequestState({
                    checkEmail: RequestState.Error,
                }),
            );
        }

        return {};
    };

export const sendInvites =
    (emails: string[]): AppThunk<Promise<boolean | void>> =>
    async (dispatch, getState) => {
        if (!emails?.length) {
            return;
        }

        const workspaceId = getActiveWorkspaceId(getState());

        dispatch(
            setRequestState({
                sendInvites: RequestState.InProgress,
            }),
        );

        try {
            await sendInvitesRequest(workspaceId, emails);
            await dispatch(fetchInvites(workspaceId));
            await dispatch(fetchWorkspaceUsers(workspaceId));

            dispatch(
                setRequestState({
                    sendInvites: RequestState.Success,
                }),
            );
        } catch (err) {
            handleRuntimeError(err as AxiosError<{ message: string }>, {
                debugMessage: 'error sending user invitation',
            });

            dispatch(
                setRequestState({
                    sendInvites: RequestState.Error,
                }),
            );

            return false;
        }

        return true;
    };

export const cancelInvite =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        const workspaceId = getActiveWorkspaceId(getState());
        dispatch(
            setRequestState({
                cancelInvite: RequestState.InProgress,
            }),
        );

        try {
            await cancelInviteRequest(id);
            await dispatch(fetchInvites(workspaceId));

            dispatch(
                setRequestState({
                    cancelInvite: RequestState.Success,
                }),
            );
        } catch (err) {
            handleRuntimeError(err as AxiosError<{ message: string }>, {
                debugMessage: 'error cancelling invitation',
            });

            dispatch(
                setRequestState({
                    cancelInvite: RequestState.Error,
                }),
            );
        }
    };

export default setupSlice.reducer;
