import type { HelpTooltipContentDescriptor } from '@/app/helpTooltips/types';

export const HELP_TOOLTIP_CONTENT = {
    // Publishing
    PUBLISH_FUNNEL_CHECK: {
        contentId: '70N2mQxXI3MWRSUQpskD1M',
        slug: 'publish-funnel-check',
    },
    PUBLISH_EDIT_LAUNCH_URL: {
        contentId: '4xACt4hu8qYKkEFuA0dmnW',
        slug: 'edit-launch-url',
    },

    // Insights
    INSIGHTS_ANALYTICS: {
        contentId: 'zPpXw9hFagZBkLuMsw4jF',
        slug: 'insights-analytics',
    },
    INSIGHTS_SUMMARY: {
        contentId: '7MBIWkfW7X1XU5ZbRVuPT3',
        slug: 'insights-summary',
    },
    INSIGHTS_SESSIONS: {
        contentId: '3ajzvnfRdheReUocGT0NGt',
        slug: 'insights-sessions',
    },
    INSIGHTS_CONTACTS: {
        contentId: '3gXmZcy6ZJbckgJR98Bz9y',
        slug: 'insights-contacts',
    },

    // Theming
    THEMING_NEW_THEME: {
        contentId: '62Fbsq7qWAK03CPOO5oGWC',
        slug: 'new-theme',
    },

    // Template Store
    TEMPLATE_STORE_INTRO: {
        contentId: '4fKl5W4YFwWAjbDL4btFrv',
        slug: 'template-store-intro',
    },

    // Page list
    PAGE_LIST_INTERACTIONS: {
        contentId: '4mtfF00JGI6Yyu8YnyWqXd',
        slug: 'page-list-interactions',
    },
    PAGE_LIST_RESULTS: {
        contentId: '1djKSkhc6OAOmbxvmBCeG0',
        slug: 'page-list-results',
    },

    // Message list
    MESSAGE_LIST_INTERACTIONS: {
        contentId: '5lOXLN9czKdb2Yfs4q2mCZ',
        slug: 'message-list-interactions',
    },

    // Component Edit Form
    EDIT_FORM_LINKING: {
        contentId: '2ZIHwBqfwZnY2stBPThWd3',
        slug: 'edit-form-linking',
    },

    // Components
    COMPONENT_INPUT_CHOICES: {
        contentId: 'BU5ELamvKE4egOVbuDUWh',
        slug: 'component-input-choices',
    },

    // Domains
    DOMAINS_OVERVIEW: {
        contentId: 'YUMSI12lprYgNwhT2XHcM',
        slug: 'domains-overview',
    },
    DOMAIN_STATUS_PENDING: {
        contentId: '4RvjKi3vkLsREruh0FEpQa',
        slug: 'domain-status-pending',
    },
    DOMAIN_STATUS_VERIFIED: {
        contentId: '2L37W7XKKt1wrz6GcLLXaN',
        slug: 'domain-status-verified',
    },
    DOMAIN_DEFAULT: {
        contentId: '3YbpdJRZ9BsZKjyvIXz4W5',
        slug: 'domain-default',
    },

    // Lead Notifications
    LEAD_NOTIFICATIONS: {
        contentId: '24sH6LQ5RslzjgeIE5u7Hk',
        slug: 'lead-notifications',
    },

    // DASHBOARD
    PERFORMANCE_DASHBOARD: {
        contentId: '66rJUzaOr1Oopw5FqeREZG',
        slug: 'performance-dashboard',
    },

    // SELF-SERVE
    INDIVIDUAL_CONTINGENT: {
        contentId: '1gBBtrJB0DVakZz5Qcijm6',
        slug: 'individual-contingent',
    },

    INDIVIDUAL_TIMEFRAME: {
        contentId: '3nJepg98bUsA0CX0c3TMUN',
        slug: 'individual-timeframe',
    },

    // REFERRAL DASHBOARD
    REFERRAL_DASHBOARD_LINK_CLICKS: {
        contentId: '7jp5t1JR30O5vCqD1KdULV',
        slug: 'link-clicks',
    },

    REFERRAL_DASHBOARD_TOTAL_CUSTOMERS: {
        contentId: '5idp0LRCNpM8vc1i4ILnfk',
        slug: 'total-signups',
    },

    REFERRAL_DASHBOARD_ACTIVE_CUSTOMERS: {
        contentId: '7D4HOFoiGeHuZFJTNVTVVN',
        slug: 'active-signups',
    },

    REFERRAL_DASHBOARD_COMMISSION_PAID_OUT: {
        contentId: 'qwuxaIV6Q50NUg1rpyhQr',
        slug: 'commission-paid-out',
    },
} as const satisfies { [key: string]: HelpTooltipContentDescriptor };
