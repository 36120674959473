import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { Field, reduxForm } from 'redux-form';

import BoxSettings from '@/app/editor/blocks/components/_helper/BoxSettings';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import GenericSize from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/GenericSize';
import ListStyle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/ListStyle';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';

import { mediaSizeMap } from '../Media/forms/ListItemForm';
import { textSizeMap } from '../Text/forms/ListItemForm';

import type { DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({ blockId, handleSubmit, activeTheme }) => {
    const { t } = useTranslation(NAME);
    const { isInColumn } = useLayoutInfo(blockId);

    return (
        <div>
            <Field name="attributes.content.align" component={ListStyle} submit={handleSubmit} />

            <Subheading text={t('text-size')} className="mb-2 mt-4" />

            <Field
                name="attributes.content.textSize"
                component={GenericSize}
                submit={handleSubmit}
                valueMap={textSizeMap}
            />

            <Subheading text={t('visual-size')} className="mb-2 mt-4" />

            <Field
                name="attributes.content.visualSize"
                component={GenericSize}
                submit={handleSubmit}
                valueMap={mediaSizeMap}
            />

            <BoxSettings
                blockId={blockId}
                activeTheme={activeTheme}
                handleSubmit={handleSubmit}
                hiddenColor={isInColumn}
            />
        </div>
    );
};

Form.displayName = 'ListEditForm';

export default reduxForm({})(Form);
