import { useEffect, useMemo, type Context, type Provider, type ReactNode } from 'react';

import { EditorEngineDragAndDropContext } from '@/app/editor/engine/core/components/dragAndDrop/EditorEngineDragAndDropContext';
import { EditorEngineDebugLoader } from '@/app/editor/engine/core/components/utils/EditorEngineDebug';
import { EditorEngineErrorBoundary } from '@/app/editor/engine/core/components/utils/EditorEngineErrorBoundary';
import { useEditorEngineDragAndDropState } from '@/app/editor/engine/core/hooks/dragAndDrop/useEditorEngineDragAndDropState';
import { useEditorEngineEventEmitter } from '@/app/editor/engine/core/hooks/events/useEditorEngineEventEmitter';
import { useEditorEngineHistory } from '@/app/editor/engine/core/hooks/history/useEditorEngineHistory';
import { useDebugLogs } from '@/app/editor/engine/core/hooks/log/useDebugLogs';
import { useBaseEditorEngineEnhancements } from '@/app/editor/engine/core/hooks/utils/useBaseEditorEngineEnhancements';
import {
    EditorEngineLogLevel,
    type EditorEngineLogHandler,
} from '@/app/editor/engine/core/types/log';
import { DefaultDraggableConfiguration } from '@/app/editor/engine/core/utils/dragAndDrop/defaultDraggableConfiguration';
import { enhanceActions } from '@/app/editor/engine/core/utils/history/enhanceActions';
import { isProductionEnv } from '@/utils/environments';

import { useEditorEngineLogger } from '../hooks/log/useEditorEngineLogger';
import { useLogErrorEvents } from '../hooks/log/useLogErrorEvents';

import type { createDraggableConfigurationContext } from '@/app/editor/engine/core/context/createDraggableConfigurationContext';
import type {
    EditorEngine,
    EditorEngineActionEnhancement,
    EditorEngineComponent,
    EditorEngineDefaultManagerProps,
    EditorEngineDefaultTypeInput,
    EditorEngineDraggableConfiguration,
} from '@/app/editor/engine/core/types';

/**
 * The props for the editor engine provider returned by
 * `createEditorEngineProvider`.
 */
type Props<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
    TExtraDocumentManagerProps extends object,
    TExtraNodeManagerProps extends object,
    TActionEnhancement extends EditorEngineActionEnhancement<TEditorEngineTypeInput>,
> = {
    /**
     * The children to render.
     */
    children: ReactNode;
    /**
     * The extra context that the developer can provide to the editor engine
     * instance.
     */
    extraContext?: TEditorEngineTypeInput['ExtraContext'];
    /**
     * The initial draggable configuration for the editor engine instance.
     */
    draggableConfiguration?: EditorEngineDraggableConfiguration<TEditorEngineTypeInput>;
    /**
     * Enhances the actions provided by the developer.
     */
    actionEnhancement?: TActionEnhancement;
    /**
     * The props to pass to the document manager hook.
     */
    documentManagerProps?: TExtraDocumentManagerProps;
    /**
     * The props to pass to the node manager hook.
     */
    nodeManagerProps?: TExtraNodeManagerProps;
    /**
     * When debugging this editor engine instance, this node will also be
     * displayed.
     */
    debugNode?: ReactNode | (() => ReactNode);
    /**
     * Extra menu items to show in the dev toolbar under the dropdown item
     * created for this editor engine instance.
     */
    debugMenuNodes?: ReactNode | (() => ReactNode);
    /**
     * The logger function which will receive log records.
     */
    handleLogRecord?: EditorEngineLogHandler;
};

/**
 * Creates an editor engine provider that wraps the original provider.
 */
export const createEditorEngineProvider = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
    TExtraDocumentManagerProps extends object,
    TExtraNodeManagerProps extends object,
    TActionEnhancement extends EditorEngineActionEnhancement<TEditorEngineTypeInput>,
>({
    name,
    context,
    EditorEngineProvider,
    providedActions,
    RenderNodeComponent,
    useDocumentManager,
    useNodeManager,
    draggableConfigurationContext,
}: {
    /**
     * The name of the editor engine instance.
     */
    name: string;
    /**
     * The context that holds the editor engine instance.
     */
    context: Context<EditorEngine<TEditorEngineTypeInput>>;
    /**
     * The original provider which the component returned by this function will
     * wrap.
     */
    EditorEngineProvider: Provider<EditorEngine<TEditorEngineTypeInput>>;
    /**
     * The actions provided by the developer when creating the editor engine
     * instance.
     */
    providedActions: TEditorEngineTypeInput['ProvidedActions'];
    /**
     * The component that renders a node.
     */
    RenderNodeComponent: EditorEngineComponent<
        TEditorEngineTypeInput['Document'],
        TEditorEngineTypeInput['Data']
    >;
    /**
     * A hook that returns the document manager for the editor engine instance.
     */
    useDocumentManager: (
        input: TExtraDocumentManagerProps &
            EditorEngineDefaultManagerProps<TEditorEngineTypeInput['ExtraContext']>,
    ) => TEditorEngineTypeInput['DocumentManager'];
    /**
     * A hook that returns the node manager for the editor engine instance.
     */
    useNodeManager: (
        input: TExtraNodeManagerProps &
            EditorEngineDefaultManagerProps<TEditorEngineTypeInput['ExtraContext']>,
    ) => TEditorEngineTypeInput['NodeManager'];
    /**
     * The context that holds the draggable configuration for the editor engine
     * instance.
     */
    draggableConfigurationContext: ReturnType<
        typeof createDraggableConfigurationContext<TEditorEngineTypeInput>
    >;
}) => {
    /**
     * The editor engine provider that wraps the original provider.
     */
    const EditorEngineInstanceProvider = ({
        children,
        extraContext = {} as TEditorEngineTypeInput['ExtraContext'],
        draggableConfiguration = DefaultDraggableConfiguration,
        actionEnhancement = ((action) => action) as TActionEnhancement,
        documentManagerProps = {} as TExtraDocumentManagerProps,
        nodeManagerProps = {} as TExtraNodeManagerProps,
        debugNode = null,
        debugMenuNodes = null,
        handleLogRecord,
    }: Props<
        TEditorEngineTypeInput,
        TExtraDocumentManagerProps,
        TExtraNodeManagerProps,
        TActionEnhancement
    >) => {
        const debugLogs = useDebugLogs({ name });
        const logHandlers = useMemo(
            () => [
                ...(handleLogRecord ? [handleLogRecord] : []),
                ...(isProductionEnv() ? [] : [debugLogs.handler]),
            ],
            [handleLogRecord, debugLogs.handler],
        );
        const logger = useEditorEngineLogger({
            logHandlers,
        });
        const eventEmitter = useEditorEngineEventEmitter();

        useLogErrorEvents({ eventEmitter, logger });

        useEffect(() => {
            logger.log({
                level: EditorEngineLogLevel.Info,
                message: `Editor Engine instance ${name} has started.`,
            });
        }, [logger]);

        const baseEnhancement = useBaseEditorEngineEnhancements();
        const documentManager = useDocumentManager({
            ...documentManagerProps,
            extraContext,
        }) as TEditorEngineTypeInput['DocumentManager'];
        const nodeManager = useNodeManager({
            ...nodeManagerProps,
            extraContext,
        }) as TEditorEngineTypeInput['NodeManager'];
        const history = useEditorEngineHistory<TEditorEngineTypeInput>({
            documentManager,
            nodeManager,
            extraContext,
            eventEmitter,
        });
        const actions = enhanceActions<TEditorEngineTypeInput, typeof actionEnhancement>({
            history,
            providedActions,
            actionEnhancement,
            documentManager,
            nodeManager,
            extraContext,
            baseEnhancement: baseEnhancement.actionEnhancement,
            logger,
        });

        const dragAndDropState = useEditorEngineDragAndDropState<TEditorEngineTypeInput>();

        const dragAndDrop = {
            ...draggableConfigurationContext,
            ...dragAndDropState,
        };

        return (
            <EditorEngineProvider
                value={{
                    isActive: true,
                    documentManager: documentManager as TEditorEngineTypeInput['DocumentManager'],
                    nodeManager: nodeManager as TEditorEngineTypeInput['NodeManager'],
                    actions,
                    history,
                    extraContext,
                    dragAndDrop,
                    Component: RenderNodeComponent,
                    eventEmitter,
                    logger,
                }}
            >
                <EditorEngineErrorBoundary eventEmitter={eventEmitter}>
                    <EditorEngineDebugLoader
                        name={name}
                        context={context}
                        baseDebugNodes={
                            <>
                                {baseEnhancement.debugNodes}
                                {debugLogs.debugNodes}
                            </>
                        }
                        baseDebugMenuNodes={
                            <>
                                {baseEnhancement.debugMenuNodes}
                                {debugLogs.debugMenuNodes}
                            </>
                        }
                        debugNodes={debugNode}
                        debugMenuNodes={debugMenuNodes}
                    />
                    <EditorEngineDragAndDropContext<TEditorEngineTypeInput>
                        Component={RenderNodeComponent}
                        documentManager={documentManager}
                        nodeManager={nodeManager}
                        dragAndDropState={dragAndDropState}
                        history={history}
                        actions={actions}
                    >
                        <draggableConfigurationContext.Provider value={draggableConfiguration}>
                            {children}
                        </draggableConfigurationContext.Provider>
                    </EditorEngineDragAndDropContext>
                </EditorEngineErrorBoundary>
            </EditorEngineProvider>
        );
    };

    return EditorEngineInstanceProvider;
};
