import { LOLI_FEATURE_FLAG_NAME } from '@/core/loli-feature-flags/constants';

import { getLoliFeatureFlag, useLoliFeatureFlag } from '@/core/loli-feature-flags/store';

export const useDefaultWorkspaceEnabled = () => {
    return useLoliFeatureFlag(LOLI_FEATURE_FLAG_NAME.defaultWorkspace);
};

export const isDefaultWorkspaceEnabled = () => {
    return getLoliFeatureFlag(LOLI_FEATURE_FLAG_NAME.defaultWorkspace);
};
