import { NAME } from '@/app/navigation/constants';

import { createSlice } from '@reduxjs/toolkit';

import type { AppState } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface NavigationState {
    isFastPublishing: boolean;
}

const initialState: NavigationState = {
    isFastPublishing: false,
};

const navigationSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setIsFastPublishing: (state, action: PayloadAction<boolean>) => {
            state.isFastPublishing = action.payload;
        },
    },
});

// === Actions ======

export const { setIsFastPublishing } = navigationSlice.actions;

// === Selectors ======
export const getIsFastPublishing = (state: AppState) =>
    state[NAME]?.navigationReducer.isFastPublishing;

export default navigationSlice.reducer;
