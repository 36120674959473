import { getBlockConfig } from '@/app/editor/blocks/helpers';
import { BorderMenuItem } from '@/app/editor/blocks/types';
import { getHasBorderItem } from '@/app/editor/editor/helper';
import { useBlockDetails } from '@/app/editor/editor/hooks/useBlockDetails';
import { cn } from '@/utils/cn';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { DraggableAttributes } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

export interface Props {
    block: BlockResource;
    dragListeners: SyntheticListenerMap;
    dragAttributes: DraggableAttributes;
    isDragged: boolean;
    isDraggingDisabled?: boolean;
}

export const Label = ({
    block,
    dragListeners,
    dragAttributes,
    isDragged,
    isDraggingDisabled = false,
}: Props) => {
    const { title, icon } = useBlockDetails(block);
    const blockConfig = getBlockConfig(block?.attributes?.componentType);
    const canMove = getHasBorderItem(blockConfig, BorderMenuItem.move);

    const Icon = icon;

    return (
        <div
            className={cn(
                'absolute -top-[26px] left-2 z-20 flex h-6 cursor-default cursor-pointer items-center rounded-t-md bg-blue-500 pl-[5px] pr-2 font-sans text-xs font-medium text-white',
                {
                    'cursor-grab': canMove && !isDraggingDisabled,
                    'cursor-grabbing': isDragged,
                },
            )}
            {...dragListeners}
            {...dragAttributes}
        >
            <Icon className="mr-1 size-[15px]" />
            {title}
        </div>
    );
};
