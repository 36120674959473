import { reset as archiveReset } from './archive';
import { reset as resetCampaigns } from './campaigns';
import { reset as resetDomainEditing } from './domainEditing';
import { reset as resetLaunchEvents } from './launchEvents';
import { reset as overviewReset } from './overview';
import { reset as publishReset } from './publish';
import { reset as restoreReset } from './restore';
import { reset as splitTestReset } from './splitTest';
import { reset as updateReset } from './update';
import { reset as deleteReset } from './update';
import { reset as versionsReset } from './versions';

import type { AppThunk } from '@/core/redux/types';

export const resetCampaignsState = (): AppThunk => (dispatch) => {
    dispatch(resetCampaigns());
    dispatch(resetDomainEditing());
    dispatch(resetLaunchEvents());
    dispatch(archiveReset());
    dispatch(restoreReset());
    dispatch(overviewReset());
    dispatch(publishReset());
    dispatch(splitTestReset());
    dispatch(updateReset());
    dispatch(versionsReset());
    dispatch(deleteReset());
};
