import type {
    DATE_FILTER_TYPES,
    FILTER_TYPES,
    FILTERABLE_FIELD_NAMES,
    TEXT_FILTER_TYPES,
} from './constants';
import type { DropZoneProps } from './hooks/useFileDropZone';
import type { FileUpload } from './models/fileUploads';
import type { Relationship, RelationshipArray, Resource } from '@/core/api/types';
import type { RowData } from '@tanstack/table-core';
import type { MouseEvent, ReactNode, SVGProps } from 'react';

declare module '@tanstack/table-core' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    interface TableMeta<TData extends RowData> {
        updateData: (rowIndex: number, columnId: string, value: string) => void;
    }
}

export const enum ViewType {
    table = 'table',
    kanban = 'kanban',
}

export const enum SortDirection {
    ASC = 1,
    DESC = -1,
}

export interface TagTheme {
    background: string;
    backgroundSolid: string;
    selectorBgColor: string;
    selectorBorderColor: string;
    colorPickerBackground: string;
    hoverOutline: string;
    text: string;
    border: string;
    gradientFrom: string;
    gradientTo: string;
}

export interface Pagination {
    count: number;
    page: number;
    limit: number;
    next?: string | null;
}

export interface Sorting {
    sortField: string;
    sortOrder: SortDirection;
    isInitialSorting?: boolean;
}

// Schema Resource

// According to Tailwind colors: https://tailwindcss.com/docs/customizing-colors#default-color-palette
export type ThemeColor =
    | 'gray'
    | 'red'
    | 'orange'
    | 'amber'
    | 'lime'
    | 'green'
    | 'cyan'
    | 'sky'
    | 'blue'
    | 'purple'
    | 'fuchsia'
    | 'black';

export const enum FieldType {
    text = 'text',
    select = 'select',
    selectMulti = 'select-multi',
    date = 'date',
    boolean = 'boolean',
    url = 'url',
    phone = 'phone',
    email = 'email',
    file = 'file',
    number = 'number',
    rating = 'rating',
}

export const enum UniqueFieldName {
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    phone = 'phone',
    status = 'status',
    name = 'name',
    birthday = 'birthday',
    country = 'country',
    city = 'city',
    street = 'street',
    website = 'website',
    zip = 'zip',
    file = 'file',
}

export const enum SystemFieldName {
    ps_closed = 'ps_closed',
    ps_closed_at = 'ps_closed_at',
    ps_completed = 'ps_completed',
    ps_completed_at = 'ps_completed_at',
    ps_converted = 'ps_converted',
    ps_converted_at = 'ps_converted_at',
    ps_cookies = 'ps_cookies',
    ps_end_slug = 'ps_end_slug',
    ps_first_seen_at = 'ps_first_seen_at',
    ps_last_seen_at = 'ps_last_seen_at',
    ps_result_page = 'ps_result_page',
    ps_start_page = 'ps_start_page',
    ps_start_slug = 'ps_start_slug',
}

// @todo: extend this?
export const enum PropertyOwner {
    system = 'system',
    custom = 'custom',
    funnel = 'funnel',
    user = 'user',
}

export const enum PropertyView {
    table = 'table',
    kanban = 'kanban',
    sidebar = 'sidebar',
}

export type SchemaPropertyOrders = Partial<Record<PropertyView, number>>;
export type SchemaPropertyVisibility = Partial<Record<PropertyView, boolean>>;

export interface SelectOption {
    value: string;
    color?: ThemeColor;
    visible?: boolean;
}

export interface SchemaProperty {
    fieldName: string;
    fieldType: FieldType;
    title: string;
    /**@deprecated */
    visible?: boolean;
    visibility?: SchemaPropertyVisibility;
    owner: PropertyOwner;
    defaultValue?: string;
    options?: SelectOption[];
    orders: SchemaPropertyOrders;
    size?: number;
}

export const enum PropertySetting {
    rename = 'rename',
    hide = 'hide',
    delete = 'delete',
    editOptions = 'editOptions',
}

export interface SchemaAttributes {
    properties: SchemaProperty[];
}

export interface SchemaRelationships {
    company: Relationship;
    workspace: Relationship;
    campaign: Relationship;
}

export type SchemaResource = Resource<SchemaAttributes, SchemaRelationships>;

// Sidebar

export const enum SidebarView {
    profile = 'profile',
    settings = 'settings',
}

export const enum SidebarTab {
    profile = 'profile',
    notes = 'notes',
    activity = 'activity',
    messages = 'messages',
}

export interface PropertyFieldProps {
    icon?: (props: SVGProps<SVGSVGElement>) => ReactNode;
    onIconClick?: (evt: MouseEvent) => void;
    placeholder?: string;
    tooltip?: string;
    hasCopy?: boolean;
    link?: string;
    openLinkInNewTab?: boolean;
    options?: SelectOption[];
    fieldType?: FieldType;
    fieldName?: string;
    owner?: PropertyOwner;
    multiline?: boolean;
    inline?: boolean;
    contactId?: string;
    isTableView?: boolean;
    dropZoneProps?: DropZoneProps;
    fileUpload?: FileUpload;
}

// Contact Resource
export type MessageActivityStatus =
    | 'accepted'
    | 'sent'
    | 'bounced'
    | 'error'
    | 'opened'
    | 'clicked'
    | 'unsubscribed'
    | 'delivered'
    | 'permanent_fail'
    | 'temporary_fail'
    | 'complained';

type MessageActivityError = {
    severity: 'permanent' | 'temporary' | (string & {});
    reason: string;
    description: string;
    attemps: number;
};

type ContactMessageHistoryEntry = {
    clientInfo: {
        clientName: string;
        clientOS: string;
        clientType: string;
        deviceType: 'desktop';
    };
    geoLocation: {
        city: string;
        country: string;
        region: string;
    };
    createdAt: string;
    error?: MessageActivityError;
    status: MessageActivityStatus;
};

type ContactMessage = {
    id: string;
    name: string;
    subject: string;
    type: 'email';
    createdAt: string;
    history: ContactMessageHistoryEntry[];
};

export interface ContactAttributes {
    createdAt: string;
    updatedAt: string;
    notesCount: number;
    fields: Record<string, string>;
    messages?: ContactMessage[];
}

export interface ContactRelationships {
    company: Relationship;
    workspace: Relationship;
    campaigns: RelationshipArray;
    values: RelationshipArray;
}

export type ContactResource = Resource<ContactAttributes, ContactRelationships>;

// ContactValue Resource
export interface ContactValueAttributes {
    fieldName: string;
    fieldType: FieldType;
    title: string;
    value: string;
    createdAt: string;
    localId: string;
}

export interface ContactValueRelationships {
    contact: Relationship;
    company: Relationship;
    workspace: Relationship;
    campaign: Relationship;
}

export type ContactValueResource = Resource<ContactValueAttributes, ContactValueRelationships>;

// Profile Note Resource
export interface ProfileNoteAttributes {
    content: string;
    createdAt: string;
    updatedAt: string;
    version: number;
}

export interface ProfileNoteRelationships {
    campaign: Relationship;
    company: Relationship;
    contact: Relationship;
    user: Relationship;
}

export interface ProfileNoteFormData {
    [key: string]: string;
}

export type ProfileNoteResource = Resource<ProfileNoteAttributes, ProfileNoteRelationships>;

// Acitvity Resource

export const enum ActivityEditor {
    'user' = 'user',
    'contact' = 'contact',
    'system' = 'system',
    'unknown' = 'unknown',
}

export const enum ActivityType {
    'value' = 'value',
    'converted' = 'converted',
    'note' = 'note',
    'status' = 'status',
    'message' = 'message',
}

export type MessageActivityValue = {
    messageName: string;
    subject: string;
    status: MessageActivityStatus;
    error?: MessageActivityError;
    createdAd: Date;
};
export type DefaultActivityValue = {
    [key: string]: string | boolean;
};

export interface ActivityAttributes {
    createdAt: string;
    editor: ActivityEditor;
    type: ActivityType;
    value: DefaultActivityValue | MessageActivityValue;
}

export interface ActivityRelationships {
    campaign: Relationship;
    contact: Relationship;
    user: Relationship;
}

export interface ActivityFormData {
    [key: string]: string;
}

export type ActivityResource = Resource<ActivityAttributes, ActivityRelationships>;

// Kanban

export const enum SortableType {
    column = 'column',
    hiddenColumn = 'hiddenColumn',
    contact = 'contact',
    container = 'container',
}

// Table

export interface Selection {
    isAllSelected: boolean;
    selectedIds: string[];
}

export const enum FixedColumnId {
    person = 'person',
    converted_at = 'ps_converted_at',
}

export const enum BulkDeleteOption {
    include = 'include',
    exclude = 'exclude',
}

// Tracking

export const enum PropertyActionType {
    copy = 'copy',
    mailto = 'mailto',
    tel = 'tel',
    url = 'url',
    download = 'download',
}

export const enum EmptyStateActionType {
    copyFunnelLink = 'copy funnel link',
    publishFunnel = 'publish funnel',
    duplicateFunnel = 'duplicate funnel',
    goToAnalytics = 'go to analytics',
}

// Requests

export type UploadResponse = {
    container: string;
    filename: string;
    handle: string;
    key: string;
    mimetype: string;
    size: number;
    status: string;
    upload_tags: { campaignId: string; companyId: string };
    url: string;
    workflows: Record<string, { jobid: string }>;
    _file: {
        size: number;
        type: string;
    };
};

// Status Templates

export type StatusColor =
    | 'gray'
    | 'red'
    | 'orange'
    | 'amber'
    | 'yellow'
    | 'lime'
    | 'green'
    | 'emerald'
    | 'teal'
    | 'cyan'
    | 'sky'
    | 'blue'
    | 'indigo'
    | 'violet'
    | 'purple'
    | 'fuchsia'
    | 'pink'
    | 'rose';

export type Status =
    | {
          value: string;
          color: StatusColor;
      }
    | SelectOption;

export type StatusTemplate = {
    id: string;
    name: string;
    status: Status[];
};

export type StatusReplacement = {
    oldValue: string;
    newValue: string;
};

export type CreateStatusTemplateData = Pick<StatusTemplate, 'name' | 'status'>;
export type CreateWorkspaceStatusData = Pick<StatusTemplate, 'status'> & { workspaceId: string };
export type CreateCampaignStatusData = Pick<StatusTemplate, 'status'>;
export type BulkUpdateData = {
    templateId: string;
    status: Status[];
    replacements: StatusReplacement[];
};
export type ReplaceStatusData = {
    oldValue: string;
    newValue: string;
};

// Property

export interface AddPropertyListOption {
    type: FieldType;
    label: string;
    icon: (props: SVGProps<SVGSVGElement>) => ReactNode;
    handleClick: () => void;
}

export interface CampaignCRMStatus {
    name: string;
    color: ThemeColor;
    count: number;
}

// File uploads

type UploadStateLoading = {
    status: 'uploading';
    filename: string;
    filesize: number;
};

type UploadStateSuccess = {
    status: 'success';
};

type UploadStateError = {
    status: 'error';
    filename: string;
    error: string;
    message: string;
};

export type UploadState = UploadStateLoading | UploadStateSuccess | UploadStateError | null;

// Filters

export type CRMFieldName = (typeof FILTERABLE_FIELD_NAMES)[number];

export type CRMFilterType = (typeof FILTER_TYPES)[number];
export type CRMTextFilterType = (typeof TEXT_FILTER_TYPES)[number];
export type CRMDateFilterType = (typeof DATE_FILTER_TYPES)[number];

export type CRMFilter = {
    fieldName: CRMFieldName;
    filterType: CRMFilterType;
    values: string[];
};

export type ServerSavedFilter = Record<CRMFieldName, Record<string, string | boolean>>;
export type ServerSavedFilterResource = {
    id: string;
    attributes: {
        name: string;
        icon: string;
        createdAt: string;
        updatedAt: string;
        filter: ServerSavedFilter;
    };
};

export type SavedFilterResource = {
    id: string;
    name: string;
    emoji: string;
    filters: CRMFilter[];
};

export type CreateSavedFilterParams = {
    name: string;
    emoji: string;
    filters: CRMFilter[];
};

export type UpdateSavedFilterParams = {
    id: string;
    name: string;
    emoji: string;
    filters: CRMFilter[];
};
