/**
 * How close to the edge of a block does the mouse have to be in order for a
 * drop operation to be a candidate?
 */
export const DRAGGABLE_DETECTION_DISTANCE = 120;
/**
 * When dragging and dropping a node, the side of another node will be
 * considered a candidate for a drop if the pointer is within this threshold
 * on both ends of the side.
 *
 * If this value is 0.1, that means that the editor engine will measure the
 * distance between the pointer and the segment of the side that is between 10%
 * and 90% of the length of the side.
 */
export const DEFAULT_DRAGGABLE_SIDE_THRESHOLD = 0.1;
/**
 * In the network simulation, this will be the default response time for a
 * network request.
 */
export const DEFAULT_RESPONSE_TIME = 0.27;
/**
 * In the network simulation, this will be the default network health.
 * A network health of 1 means that the network is healthy and no requests
 * will delay significantly beyond the response time.
 * A network health of 0 means that each request will significantly delay
 * beyond the response time.
 */
export const DEFAULT_NETWORK_HEALTH = 1;
/**
 * In the network simulation, this will be the default failure rate for a
 * network request.
 */
export const DEFAULT_FAILURE_RATE = 0.00435;
/**
 * The maximum number of items that the log history will keep.
 *
 * The log history is not active in production at the moment.
 */
export const LOG_HISTORY_MAX_ITEMS = 100;
/**
 * Local storage key for the editor engine debugger visibility.
 */
export const EDITOR_ENGINE_DEBUGGER_VISIBLE_LOCAL_STORAGE_KEY = 'EditorEngineDebuggerVisible';
