import { createSlice } from '@reduxjs/toolkit';

import { getCampaignName } from '@/app/campaigns/helpers';
import { addSetCampaigns, fetchCampaigns } from '@/app/campaigns/models/campaigns';
import { removeArtboardSizeByCampaignIdFromLS } from '@/app/editor/editor/helper';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import { NAME } from '../constants';

import type { CampaignResource } from '@/app/campaigns/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = {
    loading: false,
};

export const archiveSlice = createSlice({
    name: `${NAME}/archive`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = archiveSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.archiveReducer?.loading;

// === Thunks ======

// Archives a funnel
export const archiveCampaign =
    (campaignId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiPost<ResponseData<CampaignResource>>(
                `/campaigns/${campaignId}/archive`,
                {},
            );
            const archivedCampaign = getDataFromResponse(response);

            dispatch(addSetCampaigns({ [archivedCampaign.id]: archivedCampaign }));

            showToast({
                type: 'success',
                message: `${NAME}:archive-success-message`,
                description: getCampaignName(archivedCampaign),
            });

            // Clean up localstorage related to the archived campaign
            removeArtboardSizeByCampaignIdFromLS(campaignId);

            await dispatch(fetchCampaigns());
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'archiving campaign failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default archiveSlice.reducer;
