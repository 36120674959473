import { en } from '@/app/user/constants';
import { LS_LAST_ACTIVE_WORKSPACE_ID_KEY } from '@/app/workspaces/constants';

import Router from 'next/router';

import { validateDunning } from '@/app/billing/models/dunning';
import { fetchSubscription, getSubscription } from '@/app/billing/models/subscription';
import { fetchPendingLaunchEvents } from '@/app/campaigns/models/launchEvents';
import { fetchUsage } from '@/app/company/models/usages';
import { getIsCrmUser } from '@/app/user/helper';
import { getWorkspaceDomainFromUrl } from '@/app/workspaces/helpers/utils';
import { apiGet, handleRuntimeError } from '@/core/api';
import { resourceArrayToObject } from '@/core/api/helper';
import { isDefaultWorkspaceEnabled } from '@/core/loli-feature-flags/features/defaultWorkspace';
import { areNavigationUpdatesEnabled } from '@/core/loli-feature-flags/features/navigationUpdates';
import { useLoliFeatureFlagsStore } from '@/core/loli-feature-flags/store';
import { identifyUser } from '@/core/tracking';
import { EMPTY_ARRAY } from '@/utils/empty';

import { setIsLoggedIn } from './login';
import { setCompany } from '../../company/models/company';
import { setThemesFromArray } from '../../editor/themes/models/themes';
import { setUser } from '../../user/models/user';
import {
    getWorkspaceByDomain,
    setActiveWorkspaceId,
    setWorkspaceOrder,
    setWorkspaces,
} from '../../workspaces/models/workspaces';

import type { BootResource } from '../types';
import type { AllFeatureFlags } from '@/core/loli-feature-flags/types';
import type { AppThunk } from '@/core/redux/types';
import type { Language } from 'types/generic';

// === Helper ======

const getBootItemsByType = (data: BootResource[], type: string): BootResource[] => {
    if (!data) {
        return EMPTY_ARRAY;
    }

    return data.filter((item) => item.data.type === type);
};

// === Thunks ======
const setWorkspaceResources =
    (data: BootResource[]): AppThunk =>
    async (dispatch, getState) => {
        const workspaces = getBootItemsByType(data, 'workspace')?.map(
            (workspace) => workspace.data,
        );

        if (workspaces) {
            const workspacesAsObject = resourceArrayToObject(workspaces);
            dispatch(setWorkspaces(workspacesAsObject));
            dispatch(setWorkspaceOrder(workspaces.map((workspace) => workspace.id)));
        }

        const domain = getWorkspaceDomainFromUrl();
        let activeWorkspaceId = getWorkspaceByDomain(domain)(getState())?.id;

        // As the workspace selector is shown for all "top-level" pages (funnel overview, performance dashboard,
        // academy, and referrals) and as the workspace selector relies on the active workspace to show its
        // icon and name correctly, we need to guarantee that we always have an "active workspace" in the Redux store.
        // Ideally, the active workspace is the last selected one (stored in localStorage) or the default workspace.
        if ((areNavigationUpdatesEnabled() || isDefaultWorkspaceEnabled()) && !activeWorkspaceId) {
            activeWorkspaceId =
                localStorage.getItem(LS_LAST_ACTIVE_WORKSPACE_ID_KEY) ??
                workspaces.find((workspace) => workspace.attributes.default)?.id;
        }

        if (activeWorkspaceId) {
            dispatch(setActiveWorkspaceId(activeWorkspaceId));
        }
    };

const setUserResources =
    (data: BootResource[]): AppThunk =>
    async (dispatch) => {
        const user = getBootItemsByType(data, 'user')[0];

        if (user?.data) {
            dispatch(setUser(user.data));
        }
    };

const setCompanyResources =
    (data: BootResource[]): AppThunk =>
    async (dispatch) => {
        const company = getBootItemsByType(data, 'company')[0];

        if (company?.data) {
            dispatch(setCompany(company.data));
        }
    };

const setThemeResources =
    (data: BootResource[]): AppThunk =>
    async (dispatch) => {
        const themes = getBootItemsByType(data, 'theme').map((theme) => theme.data);

        if (themes) {
            dispatch(setThemesFromArray(themes));
        }
    };

const setBootResources =
    (data: BootResource[]): AppThunk =>
    async (dispatch) => {
        const promises = [
            dispatch(setUserResources(data)),
            dispatch(setCompanyResources(data)),
            dispatch(setWorkspaceResources(data)),
            dispatch(setThemeResources(data)),
        ];

        await Promise.all(promises);
    };

const fetchBillingInformation = (): AppThunk => async (dispatch) => {
    const promises = [
        dispatch(fetchSubscription()),
        dispatch(fetchUsage()),
        dispatch(validateDunning()),
    ];

    await Promise.all(promises);
};

export const fetchBoot = (): AppThunk => async (dispatch, getState) => {
    try {
        const response = await apiGet<BootResource[]>('/boot');

        const user = getBootItemsByType(response.data, 'user')[0];
        const company = getBootItemsByType(response.data, 'company')[0];

        // Loli Feature Flags
        const featureFlagsResource = getBootItemsByType(response.data, 'feature-flags')[0];
        const allFeatureFlags: AllFeatureFlags =
            (featureFlagsResource as any)?.data?.allFeatureFlags ?? {};

        useLoliFeatureFlagsStore.setState({
            allFeatureFlagsWithoutOverwrites: allFeatureFlags,
        });

        const language = (Router.locale ?? en) as Language;

        const isCrmUser = getIsCrmUser(user?.data);

        dispatch(setBootResources(response.data));

        if (!isCrmUser) {
            dispatch(fetchPendingLaunchEvents());
            await dispatch(fetchBillingInformation());
        }

        // === All done! ======

        // Get subscription details
        const subscription = getSubscription(getState());

        // Identify user for tracking
        identifyUser(user?.data, company?.data, subscription, language, allFeatureFlags);

        dispatch(setIsLoggedIn(true));
    } catch (err) {
        handleRuntimeError(err, { debugMessage: 'Boot failed:' });
    }
};
