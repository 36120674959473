import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

import { PerspectiveEditorEnginePreviewAwareNodeRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareNodeRenderer';
import { PropsFromParentOverrideProvider } from '@/app/editor/engine/core/components/view/PropsFromParentOverrideProvider';
import { DraggableConfigurationHelper } from '@/app/editor/engine/core/utils/dragAndDrop/configuration';
import { getColorByLightness } from '@/app/editor/themes/helpers';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { cn } from '@/utils/cn';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';

export interface Props extends DefaultBlockComponentProps {}

export const AccordionItem = ({ componentType, box, blockId }: Props) => {
    const [expanded, setExpanded] = useState(true);
    const borderRadiusClass = useBorderRadius('faqItem');

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <PerspectiveEditorEnginePreviewAwareNodeRenderer
            parentId={blockId}
            draggableConfiguration={DraggableConfigurationHelper.alwaysDisable}
            renderer={({ nodes, renderNode }) => {
                return (
                    <div
                        className={cn('pb-0 transition-all ease-out', borderRadiusClass)}
                        style={{ backgroundColor: box.backgroundColor }}
                    >
                        <div className="flex items-start p-4">
                            <div className="flex-1">
                                <PropsFromParentOverrideProvider
                                    propsFromParent={{
                                        parentComponentType: componentType,
                                    }}
                                >
                                    {renderNode({
                                        node: nodes[0],
                                    })}
                                </PropsFromParentOverrideProvider>
                            </div>
                            <div className="flex-shrink-0 pl-4" onClick={toggleExpand}>
                                <ChevronDownIcon
                                    className={cn(
                                        'size-6 transform-gpu transition-transform duration-500 ease-out',
                                        {
                                            'rotate-180': expanded,
                                        },
                                    )}
                                    style={{
                                        fill: getColorByLightness(null, box.backgroundColor),
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className={cn(
                                'px-4 transition-all duration-500 ease-out',
                                expanded ? 'block' : 'hidden',
                            )}
                        >
                            <div className="pt-1" />
                            <PropsFromParentOverrideProvider
                                propsFromParent={{
                                    noMentions: true,
                                    parentComponentType: componentType,
                                }}
                            >
                                {renderNode({
                                    node: nodes[1],
                                })}
                            </PropsFromParentOverrideProvider>
                            <div className="pt-4" />
                        </div>
                    </div>
                );
            }}
        />
    );
};
