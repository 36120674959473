import { FEATURE_IDS } from '@/app/billing/constants';
import { NAME } from '@/app/editor/blocks/constants';
import { HELP_TOOLTIP_CONTENT } from '@/app/helpTooltips/constants';

import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';

import { navigateToBillingAndPromoteFeature } from '@/app/billing/helpers/navigateToBillingAndPromoteFeature';
import { useFeatureAvailability } from '@/app/company/hooks/useFeatureAvailability';
import { getFeatureAvailability } from '@/app/company/models/company';
import { getIsUniqueInput } from '@/app/editor/blocks/components/Input/helper';
import { getIsTipTapData } from '@/app/editor/blocks/helpers';
import { setUniqueFormInputs } from '@/app/editor/blocks/models/inputTypes';
import CountryDropdown from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/CountryDropdown';
import DateFormat from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/DateFormat';
import EmojiPicker from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/EmojiPicker';
import IconToggleButton from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/IconToggleButton';
import InputType from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/InputType';
import LabeledToggleButton from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/LabeledToggleButton';
import LanguageSwitch from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/LanguageSwitch';
import TextareaList from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextareaList';
import TextStyle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextStyle';
import TrackingIdInput from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TrackingIdInput';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import { PhoneValidationKind } from '@/app/editor/types';
import { HelpIcon } from '@/app/helpTooltips/components/HelpIcon';
import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import ButtonWithTooltip from '@/ui/components/_BlockEditFields/ButtonWithTooltip';

import type { BlockResource, DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';
import type { Language } from 'types/generic';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({ form, handleSubmit, parent, initialValues }) => {
    const { t } = useTranslation(NAME);

    const dispatch = useAppDispatch();
    const { locale } = useRouter();
    const formValues = useAppSelector((state) => getFormValues(form)(state)) as BlockResource;
    const hasNewPhoneValidation = useFeatureAvailability(FEATURE_IDS.phoneFormatValidation);
    const hasLeadNotificationsFeature = useAppSelector(
        getFeatureAvailability(FEATURE_IDS.multipleNotifications),
    );

    const fieldName = formValues?.attributes?.content?.fieldName;
    const isCustomInput = formValues?.attributes?.content?.field === 'custom';
    const isBirthdayInput = formValues?.attributes?.content?.field === 'birthday';
    const isDatePickerInput = formValues?.attributes?.content?.field === 'datePicker';
    const isConsent = formValues?.attributes?.content?.inputType === 'checkbox';
    const isPhoneInput = formValues?.attributes?.content?.inputType === 'phone';
    const isPhoneWithCountryValidation = hasNewPhoneValidation && isPhoneInput;
    const phoneValidationKind = formValues?.attributes?.content?.customOptions?.phoneValidationKind;
    const hasPickedPhoneFormatValidation =
        isPhoneWithCountryValidation && phoneValidationKind === PhoneValidationKind.CountryFormat;
    const isUniqueInput = getIsUniqueInput(formValues);
    const isLeadNotificationField = formValues?.attributes?.content?.conversionTrigger;
    const isCalendlyForm = parent?.attributes?.componentType === 'formURL';
    const isDropdownInput = formValues?.attributes?.content?.inputType === 'dropdown';
    const blockedTrackingNames = isCalendlyForm ? ['name', 'email'] : [];
    const hideInputTypeField = isCalendlyForm && ['name', 'email'].includes(fieldName);
    const isEmojiPickerDisabled = isConsent || hasPickedPhoneFormatValidation;

    const isTipTap = getIsTipTapData(initialValues?.attributes?.content?.misc?.wysiwyg);

    const handleLeadNotificationClick = async () => {
        if (!hasLeadNotificationsFeature) {
            return await navigateToBillingAndPromoteFeature(FEATURE_IDS.multipleNotifications);
        }

        const campaignId = formValues?.relationships?.campaign?.data?.id;

        dispatch(showModal(Modals.LEAD_NOTIFICATIONS, { campaignId }));
    };

    useEffect(() => {
        // set already used unique inputs that should _not_ be selectable anymore
        dispatch(setUniqueFormInputs(parent.id));
    }, [dispatch, parent.id]);

    return (
        <div>
            {!hideInputTypeField && <InputType blockId={form} formBlockId={parent.id} />}
            {isConsent && !isTipTap && (
                <div className="mt-2">
                    <TextStyle textBlockId={form} draftDataPath="misc.wysiwyg" />
                </div>
            )}
            {isLeadNotificationField && (
                <div className="mt-2">
                    <ButtonWithTooltip
                        editText={t('common:manage')}
                        title={t('lead-notifications')}
                        onButtonClick={handleLeadNotificationClick}
                        helpTooltip={HELP_TOOLTIP_CONTENT.LEAD_NOTIFICATIONS}
                    />
                </div>
            )}
            <div className="mt-2 grid grid-cols-3 gap-2">
                {!isConsent && (
                    <Field
                        name="attributes.content.showIcon"
                        component={IconToggleButton}
                        submit={handleSubmit}
                        icon="emoji"
                        tooltip="Emoji"
                        disabled={isEmojiPickerDisabled}
                    />
                )}

                <Field
                    name="attributes.content.required"
                    component={IconToggleButton}
                    submit={handleSubmit}
                    icon="lock"
                    tooltip={t('required')}
                />

                {isPhoneWithCountryValidation && (
                    <Field
                        name="attributes.content.customOptions.phoneValidationKind"
                        component={IconToggleButton}
                        toggleType="phoneValidationKind"
                        submit={handleSubmit}
                        icon="validation"
                        tooltip={t('phone-validation-kind-tooltip')}
                    />
                )}

                {isDropdownInput && (
                    <Field
                        name="attributes.content.customOptions.twoColumn"
                        component={IconToggleButton}
                        submit={handleSubmit}
                        tooltip={t('two-column')}
                        icon="twoColumn"
                    />
                )}

                {isCustomInput && (
                    <Field
                        name="attributes.content.inputType"
                        component={IconToggleButton}
                        submit={handleSubmit}
                        icon="number"
                        toggleType="inputType"
                        tooltip={t('number-input')}
                    />
                )}
            </div>

            {hasPickedPhoneFormatValidation && (
                <>
                    <Subheading text={t('default-country-code')} />
                    <div className="mt-2">
                        <Field
                            name="attributes.content.customOptions.defaultCountry"
                            component={CountryDropdown}
                            language={locale as Language}
                            submit={handleSubmit}
                            icon="emoji"
                            tooltip={t('default-country-code-tooltip')}
                        />
                    </div>
                </>
            )}

            {isDropdownInput && (
                <>
                    <Field
                        name="attributes.content.customOptions.multiSelect"
                        component={LabeledToggleButton}
                        text={t('multiselect-possible')}
                        submit={handleSubmit}
                    />

                    <div className="mt-6 flex items-center justify-between">
                        <Subheading text={t('choices')} className="mt-0" />
                        <HelpIcon {...HELP_TOOLTIP_CONTENT.COMPONENT_INPUT_CHOICES} />
                    </div>
                    <div className="mt-2">
                        <Field
                            name="attributes.content.customOptions.choices"
                            component={TextareaList}
                            submit={handleSubmit}
                        />
                    </div>
                </>
            )}

            {!isEmojiPickerDisabled && (
                <Field
                    name="attributes.content.icon"
                    component={EmojiPicker}
                    submit={handleSubmit}
                />
            )}

            {isBirthdayInput && (
                <>
                    <Subheading text={t('dateFormat')} />

                    <div className="mt-2">
                        <Field
                            name="attributes.content.customOptions.dateFormat"
                            component={DateFormat}
                            initialValue={
                                formValues?.attributes?.content?.customOptions?.dateFormat
                            }
                            submit={handleSubmit}
                        />
                    </div>
                </>
            )}

            {isDatePickerInput && (
                <div className="mt-6">
                    <Field
                        name="attributes.content.customOptions.pastDatesDisabled"
                        component={LabeledToggleButton}
                        text={t('past-dates-selectable')}
                        invertSubmitValue
                        submit={handleSubmit}
                    />

                    <Field
                        name="attributes.content.customOptions.weekendsDisabled"
                        component={LabeledToggleButton}
                        initialValue={
                            formValues?.attributes?.content?.customOptions?.weekendsDisabled
                        }
                        text={t('disable-weekends')}
                        submit={handleSubmit}
                    />
                </div>
            )}

            {(isDatePickerInput || isDropdownInput || hasPickedPhoneFormatValidation) && (
                <>
                    {isDatePickerInput && <Subheading text={t('calendar-language')} />}
                    {isDropdownInput && <Subheading text={t('dropdown-language')} />}
                    {hasPickedPhoneFormatValidation && (
                        <Subheading text={t('telephone-language')} />
                    )}

                    <div className="mt-2">
                        <Field
                            name="attributes.content.customOptions.language"
                            component={LanguageSwitch}
                            initialValue={
                                formValues?.attributes?.content?.customOptions?.language || locale
                            }
                            submit={handleSubmit}
                        />
                    </div>
                </>
            )}

            {!isUniqueInput && (
                <>
                    <Subheading text={t('tracking')} />

                    <div className="mt-2">
                        <Field
                            name="attributes.content.fieldName"
                            component={TrackingIdInput}
                            blockedValues={blockedTrackingNames}
                            submit={handleSubmit}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

Form.displayName = 'InputEditForm';

export default reduxForm({})(Form);
