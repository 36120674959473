import {
    SparklesIcon,
    FaceSmileIcon,
    LockClosedIcon,
    CalculatorIcon,
    EyeIcon,
    EyeSlashIcon,
    ViewfinderCircleIcon,
    MoonIcon,
    PlayIcon,
    ShieldCheckIcon,
    Bars3BottomLeftIcon,
    Bars3Icon,
    LinkIcon,
    PhotoIcon,
    UserCircleIcon,
} from '@heroicons/react/24/outline';
import { ShadowNoneIcon } from '@radix-ui/react-icons';
import { useCallback } from 'react';

import { TwoArrowsPointingInwards } from '@/ui/components/_BlockEditFields/ToggleButton/additionalIcons/TwoArrowsPointingInwards';
import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import FrameIcon from './FrameIcon';
import TwoColumnIcon from '../../Icons/TwoColumnIcon';

const iconMap = {
    animation: <SparklesIcon className="size-5" />,
    emoji: <FaceSmileIcon className="size-5" />,
    lock: <LockClosedIcon className="size-5" />,
    number: <CalculatorIcon className="size-5" />,
    frame: <FrameIcon className="size-4" />,
    eye: <EyeIcon className="size-5" />,
    eyeSlash: <EyeSlashIcon className="size-5" />,
    twoColumn: <TwoColumnIcon className="size-5" />,
    borderRadius: <ViewfinderCircleIcon className="size-5" />,
    darkMode: <MoonIcon className="size-5" />,
    autoplay: <PlayIcon className="size-5" />,
    validation: <ShieldCheckIcon className="size-5" />,
    leftAlign: <Bars3BottomLeftIcon className="size-5" />,
    centerAlign: <Bars3Icon className="size-5" />,
    centerCustomHtmlContent: <TwoArrowsPointingInwards className="size-5" />,
    link: <LinkIcon className="size-5" />,
    image: <PhotoIcon className="size-5" />,
    grayscale: <ShadowNoneIcon className="size-5" />,
    avatars: <UserCircleIcon className="size-5" />,
};

export type ToggleIcon = keyof typeof iconMap;

export interface Props {
    checked: boolean;
    onToggle: (checked: boolean) => void;
    icon: ToggleIcon;
    tooltip?: string;
    disabled?: boolean;
    monochrome?: boolean;
    inverted?: boolean;
}

export const ToggleButton = ({
    checked: rawChecked,
    icon,
    onToggle,
    tooltip,
    disabled,
    monochrome,
    inverted,
}: Props) => {
    const checked = inverted ? !rawChecked : rawChecked;

    const handleClick = useCallback(() => {
        onToggle(!rawChecked);
    }, [onToggle, rawChecked]);

    return (
        <Tooltip content={tooltip}>
            <button
                className={cn(
                    'flex h-12 w-full items-center justify-center rounded-lg bg-gray-100 px-4',
                    {
                        bump: !disabled,
                        'text-blue-500': checked && !disabled && !monochrome,
                        'text-gray-400 hover:text-gray-600': (!checked && !disabled) || monochrome,
                        'text-gray-300': disabled,
                    },
                )}
                type="button"
                onClick={handleClick}
                disabled={disabled}
            >
                {iconMap[icon]}
            </button>
        </Tooltip>
    );
};

export default ToggleButton;
