import EditInput from '@/ui/components/_BlockEditFields/EditInput';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';

import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps;
    label?: string;
    blockedValues: string[];
    submit: () => void;
}

const TrackingIdInput = ({ input, blockedValues, submit }: Props) => {
    const handleSave = (value: string) => {
        const { onChange } = input;
        let newValue = value;

        if (
            input.name &&
            input.name === 'attributes.content.fieldName' &&
            blockedValues.includes(newValue)
        ) {
            newValue = `_${newValue}`;
        }

        onChange(newValue);

        if (submit) {
            setTimeout(submit);
        }
    };

    return (
        <FieldContainer>
            <EditInput onSave={handleSave} value={input.value} />
        </FieldContainer>
    );
};

TrackingIdInput.defaultProps = { blockedValues: [] };

export default TrackingIdInput;
