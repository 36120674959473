import { createSelector, createSlice } from '@reduxjs/toolkit';

import { AccountSetUpScreens, RequestState } from '@/app/workspaces/types';
import { handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';

import { dataFetchWorkspaceByDomain } from './workspaces';
import { NAME } from '../constants';
import { createAccountRequest, validateInvitationRequest } from '../helpers/apis';

import type {
    InvitationData,
    InvitationStatus,
    NewAccountData,
    WorkspaceResource,
} from '@/app/workspaces/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

interface State {
    invitationData: InvitationData;
    currentStep: AccountSetUpScreens;
    workspace?: WorkspaceResource;
    requestState: Partial<
        Record<'createAccount' | 'validateInvitation' | 'initWorkspace', RequestState>
    >;
}

const initialState: State = {
    invitationData: EMPTY_OBJECT as InvitationData,
    currentStep: AccountSetUpScreens.Account,
    workspace: null,
    requestState: {
        createAccount: RequestState.Idle,
        initWorkspace: RequestState.Idle,
        validateInvitation: RequestState.Idle,
    },
};

export const accountSetupSlice = createSlice({
    name: `${NAME}/accountSetup`,
    initialState,
    reducers: {
        setInvitationData(state, action: PayloadAction<InvitationData>) {
            state.invitationData = action.payload;
        },
        setCurrentSignupStep(state, action: PayloadAction<AccountSetUpScreens>) {
            state.currentStep = action.payload;
        },
        setWorkspace(state, action: PayloadAction<WorkspaceResource>) {
            state.workspace = action.payload;
        },
        setRequestState(state, action: PayloadAction<State['requestState']>) {
            state.requestState = {
                ...state.requestState,
                ...action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setInvitationData, setCurrentSignupStep, setWorkspace, setRequestState, reset } =
    accountSetupSlice.actions;

// === Selectors ======

export const getInvitationData = (state: AppState) =>
    state[NAME].accountSetupReducer.invitationData;

export const getCurrentSignupStep = (state: AppState) =>
    state[NAME].accountSetupReducer.currentStep;

export const getWorkspace = (state: AppState) => state[NAME].accountSetupReducer.workspace;

export const getRequestState = (state: AppState) => state[NAME].accountSetupReducer.requestState;

export const getIsWorkspaceInitialized = createSelector(getRequestState, (requestState) => {
    return (
        requestState.initWorkspace === RequestState.Success ||
        requestState.initWorkspace === RequestState.Error
    );
});

// === Thunk ======

export const initWorkspace =
    (domain: string): AppThunk =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                initWorkspace: RequestState.InProgress,
            }),
        );

        try {
            const workspace = await dataFetchWorkspaceByDomain(domain);

            dispatch(setWorkspace(workspace));

            dispatch(
                setRequestState({
                    initWorkspace: RequestState.Success,
                }),
            );
        } catch (err) {
            dispatch(
                setRequestState({
                    initWorkspace: RequestState.Error,
                }),
            );

            handleRuntimeError(err as AxiosError<{ message: string }>, {
                debugMessage: 'init workspace failed:',
            });

            dispatch(setCurrentSignupStep(AccountSetUpScreens.Error));
        }
    };

export const handleCreateAccount =
    (account: NewAccountData, invitationId: string): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                createAccount: RequestState.InProgress,
            }),
        );

        try {
            await createAccountRequest(account, invitationId);

            dispatch(
                setRequestState({
                    createAccount: RequestState.Success,
                }),
            );

            return true;
        } catch (err) {
            handleRuntimeError(err as AxiosError<{ message: string }>, {
                debugMessage: 'creating account failed:',
            });
            dispatch(
                setRequestState({
                    createAccount: RequestState.Error,
                }),
            );
            dispatch(setCurrentSignupStep(AccountSetUpScreens.Error));
        }

        return false;
    };

export const currentStepsStatusHandler =
    (status: string): AppThunk =>
    (dispatch) => {
        switch (status) {
            case 'pending':
                break;
            case 'expired':
                dispatch(setCurrentSignupStep(AccountSetUpScreens.Expired));
                break;
            case 'success':
                dispatch(setCurrentSignupStep(AccountSetUpScreens.Success));
                // dispatch(setCurrentSignupStep(AccountSetUpScreens.Success));
                break;
            case 'error':
                dispatch(setCurrentSignupStep(AccountSetUpScreens.Error));
                break;
        }
    };

export const validateInvitation =
    (invitationId: string): AppThunk<Promise<InvitationStatus>> =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                validateInvitation: RequestState.InProgress,
            }),
        );

        try {
            const data = getDataFromResponse(await validateInvitationRequest(invitationId));
            const invitation = data.attributes;

            dispatch(
                setRequestState({
                    validateInvitation: RequestState.Success,
                }),
            );

            dispatch(setInvitationData(invitation));
            dispatch(currentStepsStatusHandler(invitation.status));

            return invitation.status;
        } catch (err) {
            handleRuntimeError(err as AxiosError<{ message: string }>, {
                debugMessage: 'invitation validation failed:',
            });
            dispatch(
                setRequestState({
                    validateInvitation: RequestState.Error,
                }),
            );
            dispatch(setCurrentSignupStep(AccountSetUpScreens.Error));

            return 'error';
        }
    };

export default accountSetupSlice.reducer;
