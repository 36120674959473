import { createSelector, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

import { handleRuntimeError } from '@/core/api';

import { setActiveView } from '../../editor/models/sidebar';
import { NAME } from '../constants';
import { createMessageBodyBlock } from '../utils/createMessageBodyBlock';

import type { MessageBodyItem, MessageResource, UpdateMessageProps } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { QueryKey, UseMutateAsyncFunction } from '@tanstack/react-query';

export interface State {
    isDuplicating: boolean;
    isDeleting: boolean;
    activeBlockId: string | null;
    addBlockAfterId: string | null;
    bodyItemsMap: Record<string, MessageBodyItem>;
    bodyItemsOrder: string[];
}

const initialState: State = {
    isDuplicating: false,
    isDeleting: false,
    activeBlockId: null,
    addBlockAfterId: null,
    bodyItemsMap: {},
    bodyItemsOrder: [],
};

const messageBodySlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setIsDuplicating(state, actions: PayloadAction<boolean>) {
            return {
                ...state,
                isDuplicating: actions.payload,
            };
        },
        setIsDeleting(state, actions: PayloadAction<boolean>) {
            return {
                ...state,
                isDeleting: actions.payload,
            };
        },
        setActiveBlockId(state, actions: PayloadAction<string | null>) {
            return {
                ...state,
                activeBlockId: actions.payload,
            };
        },
        setAddBlockAfterId(state, actions: PayloadAction<string | null>) {
            return {
                ...state,
                addBlockAfterId: actions.payload,
            };
        },
        setBodyItemsMap(state, actions: PayloadAction<State['bodyItemsMap']>) {
            return {
                ...state,
                bodyItemsMap: actions.payload,
            };
        },
        setBodyItemsOrder(state, actions: PayloadAction<State['bodyItemsOrder']>) {
            return {
                ...state,
                bodyItemsOrder: actions.payload,
            };
        },
        reset() {
            return initialState;
        },
    },
});

// Actions
export const {
    setIsDuplicating,
    setIsDeleting,
    setActiveBlockId,
    setAddBlockAfterId,
    setBodyItemsMap,
    setBodyItemsOrder,
    reset,
} = messageBodySlice.actions;

// Selectors
export const getIsDuplicating = (state: AppState) => state[NAME].messageBodyReducer.isDuplicating;

export const getIsDeleting = (state: AppState) => state[NAME].messageBodyReducer.isDeleting;

export const getIsAsyncActionDisabled = createSelector(
    [getIsDuplicating, getIsDeleting],
    (isDuplicating, isDeleting) => isDuplicating || isDeleting,
);

export const getActiveBlockId = (state: AppState) => state[NAME].messageBodyReducer.activeBlockId;

export const getAddBlockAfterId = (state: AppState) =>
    state[NAME].messageBodyReducer.addBlockAfterId;

export const getBodyItemsMap = (state: AppState) => state[NAME].messageBodyReducer.bodyItemsMap;

export const getBodyItemsOrder = (state: AppState) => state[NAME].messageBodyReducer.bodyItemsOrder;

// Thunks
// @todo(AD): Remove once message body items have IDs in the API
export const initMessageBodyIds =
    (message: MessageResource | null): AppThunk =>
    async (dispatch) => {
        try {
            const bodyItemsMap = {};
            const bodyItemsOrder = [];

            message?.content?.body?.forEach((bodyItem) => {
                const uuid = uuidv4();
                bodyItemsMap[uuid] = bodyItem;
                bodyItemsOrder.push(uuid);
            });

            dispatch(setBodyItemsMap(bodyItemsMap));
            dispatch(setBodyItemsOrder(bodyItemsOrder));
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to init message body' });
        }
    };

type UpdateMessage = UseMutateAsyncFunction<
    MessageResource | undefined,
    Error,
    UpdateMessageProps,
    {
        previousMessages: [QueryKey, unknown][];
    }
>;

export const deleteMessageBodyBlock =
    (campaignId: string, message: MessageResource | null, updateMessage: UpdateMessage): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        dispatch(setIsDeleting(true));

        const bodyItemsMap = getBodyItemsMap(state);
        const bodyItemsOrder = getBodyItemsOrder(state);
        const activeBlockId = getActiveBlockId(state);
        const activeBlockIndex = bodyItemsOrder.findIndex((id) => id === activeBlockId);

        const blockToDelete = message?.content?.body?.[activeBlockIndex];

        if (blockToDelete === null || !blockToDelete) {
            return;
        }

        try {
            const clonedBody = cloneDeep(message?.content?.body);
            clonedBody.splice(activeBlockIndex, 1);

            // Handle Map and Order change
            const updatedBodyItemsOrder = [...bodyItemsOrder];
            const updatedBodyItemsMap = { ...bodyItemsMap };

            const idToDelete = updatedBodyItemsOrder.splice(activeBlockIndex, 1);
            delete updatedBodyItemsMap[idToDelete[0]];

            // Update message via API
            await updateMessage({
                messageId: message.id,
                campaignId,
                updates: {
                    content: {
                        body: clonedBody,
                    },
                },
            });

            dispatch(setBodyItemsMap(updatedBodyItemsMap));
            dispatch(setBodyItemsOrder(updatedBodyItemsOrder));

            dispatch(setActiveBlockId(null));
            dispatch(setActiveView('messages'));
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to delete message body item' });
        } finally {
            dispatch(setIsDeleting(false));
        }
    };

export const duplicateMessageBodyBlock =
    (campaignId: string, message: MessageResource | null, updateMessage: UpdateMessage): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        dispatch(setIsDuplicating(true));

        const bodyItemsMap = getBodyItemsMap(state);
        const bodyItemsOrder = getBodyItemsOrder(state);
        const activeBlockId = getActiveBlockId(state);
        const activeBlockIdx = bodyItemsOrder.findIndex((id) => id === activeBlockId);

        const blockToDuplicate = message?.content?.body?.[activeBlockIdx];

        if (blockToDuplicate === null || !blockToDuplicate) {
            return;
        }

        try {
            const newId = uuidv4();
            const clonedBody = cloneDeep(message.content?.body);
            clonedBody.splice(activeBlockIdx + 1, 0, blockToDuplicate);

            // Handle Map and Order change
            const updatedBodyItemsOrder = [...bodyItemsOrder];
            const updatedBodyItemsMap = { ...bodyItemsMap };

            updatedBodyItemsOrder.splice(activeBlockIdx + 1, 0, newId);
            updatedBodyItemsMap[newId] = blockToDuplicate;

            // Update message via API
            await updateMessage({
                messageId: message.id,
                campaignId,
                updates: {
                    content: {
                        body: clonedBody,
                    },
                },
            });

            dispatch(setBodyItemsMap(updatedBodyItemsMap));
            dispatch(setBodyItemsOrder(updatedBodyItemsOrder));

            dispatch(setActiveBlockId(newId));
            dispatch(setActiveView('editEmailBlock'));
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to duplicate message body item' });
        } finally {
            dispatch(setIsDuplicating(false));
        }
    };

export const addMessageBodyBlock =
    (
        campaignId: string,
        message: MessageResource | null,
        sectionName: string,
        updateMessage: UpdateMessage,
    ): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();

        const bodyItemsMap = getBodyItemsMap(state);
        const bodyItemsOrder = getBodyItemsOrder(state);
        const addBlockAfterId = getAddBlockAfterId(state);
        const addBlockAfterIndex = bodyItemsOrder.findIndex((id) => id === addBlockAfterId);

        try {
            const newId = uuidv4();
            const clonedBody = cloneDeep(message?.content?.body);
            const newBlockData = createMessageBodyBlock(sectionName);
            const newBlockIndex =
                addBlockAfterIndex >= 0 ? addBlockAfterIndex + 1 : clonedBody?.length;

            clonedBody?.splice(newBlockIndex, 0, newBlockData);

            // Handle Map and Order change
            const updatedBodyItemsOrder = [...bodyItemsOrder];
            const updatedBodyItemsMap = { ...bodyItemsMap };

            updatedBodyItemsOrder.splice(newBlockIndex, 0, newId);
            updatedBodyItemsMap[newId] = newBlockData;

            dispatch(setBodyItemsMap(updatedBodyItemsMap));
            dispatch(setBodyItemsOrder(updatedBodyItemsOrder));

            if (message && campaignId) {
                // Update message via API
                await updateMessage({
                    messageId: message.id,
                    campaignId,
                    updates: {
                        content: {
                            body: clonedBody,
                        },
                    },
                });

                dispatch(setActiveBlockId(newId));
                dispatch(setActiveView('editEmailBlock'));
            }
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to add message body item' });
        } finally {
            dispatch(setAddBlockAfterId(null));
        }
    };

export const updateMessageBodyItem =
    ({
        campaignId,
        message,
        bodyItem,
        index,
        updateMessage,
    }: {
        campaignId: string;
        message: MessageResource | null;
        bodyItem: MessageBodyItem;
        index: number;
        updateMessage: UpdateMessage;
    }): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();

        const bodyItemsMap = getBodyItemsMap(state);
        const bodyItemsOrder = getBodyItemsOrder(state);

        try {
            const clonedBody = cloneDeep(message?.content?.body);

            const blockId = bodyItemsOrder[index];

            const updatedBodyItemsMap = { ...bodyItemsMap };
            updatedBodyItemsMap[blockId] = bodyItem;
            clonedBody[index] = bodyItem;

            if (message && campaignId) {
                // Handle Map change
                dispatch(setBodyItemsMap(updatedBodyItemsMap));

                // Update message via API
                updateMessage({
                    messageId: message.id,
                    campaignId,
                    updates: {
                        content: { body: clonedBody },
                    },
                });
            }
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to update message body item' });
        }
    };

export const messageBodyReducer = messageBodySlice.reducer;
