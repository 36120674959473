import { createSelector, createSlice } from '@reduxjs/toolkit';
import find from 'lodash/find';
import Router from 'next/router';

import { getBlockParent } from '@/app/editor/blocks/models/blocks';
import { normalizePreviewBlock } from '@/app/editor/engine/core/functions/utils/normalizePreviewBlock';
import { getPreviewBlockId } from '@/app/editor/sections/models/insert';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse, resourceArrayToObject } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import { ELEMENTS_CATEGORY_ID_DE, ELEMENTS_CATEGORY_ID_EN, NAME } from '../constants';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { SectionResource } from '@/app/editor/sections/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Language } from 'types/generic';

interface State {
    loading: boolean;
    sections: Record<Language, SectionResource[]>;
    initializedLanguages: Language[];
    previews: { [id: string]: BlockResource };
}

const initialState: State = {
    loading: false,
    sections: {
        en: EMPTY_ARRAY,
        de: EMPTY_ARRAY,
    },
    initializedLanguages: EMPTY_ARRAY,
    previews: EMPTY_OBJECT,
};

export const sectionsSlice = createSlice({
    name: `editor/${NAME}/sections`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setSections(state, action: PayloadAction<{ sections: SectionResource[]; lang: Language }>) {
            return {
                ...state,
                sections: {
                    ...state.sections,
                    [action.payload.lang]: action.payload.sections,
                },
            };
        },
        setInitializedLanguage(state, action: PayloadAction<Language>) {
            return {
                ...state,
                initializedLanguages: [...state.initializedLanguages, action.payload],
            };
        },
        setSectionPreviews(state, action: PayloadAction<{ [blockId: string]: BlockResource }>) {
            return {
                ...state,
                previews: {
                    ...state.previews,
                    ...action.payload,
                },
            };
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======

export const { setLoading, setSections, setSectionPreviews, setInitializedLanguage, reset } =
    sectionsSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.sectionsReducer?.loading;

export const getSections = (state: AppState): SectionResource[] => {
    const language = Router.locale;

    return state[NAME]?.sectionsReducer?.sections[language];
};

export const getSectionByName = (state: AppState, sectionName: string) => {
    const sections = getSections(state);

    return find(sections, (section) => section.attributes.name === sectionName);
};

export const getSectionById = (state: AppState, sectionId: string) => {
    const sections = getSections(state);

    return find(sections, { id: sectionId });
};

export const getPreviewById = (state: AppState, previewId: string) =>
    state[NAME]?.sectionsReducer.previews[previewId];

export const getInitializedLanguages = (state: AppState) =>
    state[NAME]?.sectionsReducer?.initializedLanguages;

// === Parent selectors ======

// Get the parent block of a block
export const getPreviewParent = (state: AppState, blockId: string): BlockResource | null => {
    const block = getPreviewById(state, blockId);

    const parentId = block?.relationships?.parent?.data?.id;

    return parentId ? getPreviewById(state, parentId) : null;
};

// Get all parents of a preview
export const getAllPreviewParents = (state: AppState, previewId: string): BlockResource[] => {
    const preview = getPreviewById(state, previewId);

    const parentId = preview?.relationships?.parent?.data?.id;

    if (!parentId) {
        return [];
    }

    const parent = getPreviewById(state, parentId);

    return [parent, ...getAllPreviewParents(state, parentId)];
};

export const getPreviewIsOnlyChild = (state: AppState, previewId: string) => {
    const parent = getPreviewParent(state, previewId);

    if (!parent) {
        return false;
    }

    const children = parent.relationships.components.data;

    return children.length === 1;
};

export const getPreviewIsInParentType = (
    state: AppState,
    previewId: string,
    parentComponentType: string,
) => {
    // The preview is displayed underneath and block that is nested in the parent type
    const previewBlockId = getPreviewBlockId(state);
    const previewBlockParent = getBlockParent(state, previewBlockId);
    const previewBlockIsInParentType =
        previewBlockParent?.attributes?.componentType === parentComponentType;

    // The preview itself can be in a parent type
    const parents = getAllPreviewParents(state, previewId);

    return (
        previewBlockIsInParentType ||
        parents.some(
            (parent) => parent.attributes.componentType === parentComponentType + 'Template',
        )
    );
};

export const getPreviewParentColumn = (
    state: AppState,
    previewId: string,
): BlockResource | null => {
    const isInColumn = getPreviewIsInParentType(state, previewId, 'gridColumn');

    if (!isInColumn) {
        return null;
    }

    const parents = getAllPreviewParents(state, previewId);

    return parents.find((parent) => parent.attributes.componentType === 'gridColumnTemplate');
};

export const getPreviewIsInSingleColumn = (state: AppState, previewId: string): boolean => {
    const parentColumn =
        getPreviewParentColumn(state, previewId) || getBlockParent(state, getPreviewBlockId(state));

    if (!parentColumn) {
        return false;
    }

    return getPreviewIsOnlyChild(state, parentColumn?.id);
};

/**
 * Given a preview ID, this will return the normalized (without "Template" in
 * their `name` and `componentType`) children of the preview block.
 */
export const getPreviewChildren = createSelector(
    (state: AppState, previewId: string) => getPreviewById(state, previewId),
    (state: AppState) => state,
    (preview, state) => {
        return (preview?.relationships?.components?.data ?? []).map((child) => ({
            block: normalizePreviewBlock(getPreviewById(state, child.id)),
        }));
    },
);

// === Thunks ======

export const setSectionsDataAndPreview =
    (
        sectionsData: SectionResource[],
        sectionPreviews: Record<string, BlockResource>,
        lang: Language,
    ): AppThunk =>
    (dispatch) => {
        dispatch(setSections({ sections: sectionsData, lang }));
        dispatch(setSectionPreviews(sectionPreviews));
        dispatch(setInitializedLanguage(lang));
    };

// fetch sections and section previews
export const fetchSectionsAndPreviewByLanguage =
    (language: Language): AppThunk =>
    async (dispatch) => {
        const categoryId = language === 'de' ? ELEMENTS_CATEGORY_ID_DE : ELEMENTS_CATEGORY_ID_EN;

        dispatch(setLoading(true));

        try {
            const sectionResponse = await apiGet(`/categories/section/${categoryId}/templates`);
            const sectionPreviewResponse = await apiGet(
                `/categories/section/${categoryId}/templates/components`,
            );

            const sections: SectionResource[] = getDataFromResponse(sectionResponse);

            const sectionPreviews = resourceArrayToObject(
                getDataFromResponse(sectionPreviewResponse),
            );

            dispatch(setSectionsDataAndPreview(sections, sectionPreviews, language));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching category sections failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default sectionsSlice.reducer;
