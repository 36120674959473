import { useEffect, useState } from 'react';
import { change } from 'redux-form';

import { updateActiveBlock } from '@/app/editor/blocks/models/update';
import { useAppDispatch } from '@/core/redux/hooks';

import type { KeyboardEvent, MutableRefObject } from 'react';
import type { ContentEditableEvent } from 'react-contenteditable';

type ContentEditablePlaceholderHook = (config: {
    ref: MutableRefObject<HTMLElement>;
    blockId: string;
    initialPlaceholder: string;
    dataPath?: string;
    preventLineBreak?: boolean;
}) => {
    updatedPlaceholder: string;
    handlePlaceholderChange: (event: ContentEditableEvent) => void;
    handlePlaceholderBlur: () => void;
    handleKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
};

export const useContentEditablePlaceholder: ContentEditablePlaceholderHook = ({
    ref,
    blockId,
    initialPlaceholder,
    dataPath = 'attributes.content.placeholder',
    preventLineBreak = true,
}) => {
    const dispatch = useAppDispatch();
    const [updatedPlaceholder, setUpdatedPlaceholder] = useState(initialPlaceholder);

    // initialPlaceholder might change, e.g. when changing input type
    useEffect(() => {
        setUpdatedPlaceholder(initialPlaceholder);
    }, [initialPlaceholder]);

    const handlePlaceholderChange = (event: ContentEditableEvent) => {
        setUpdatedPlaceholder(event.target.value);
    };

    const handlePlaceholderBlur = async () => {
        const trimmedPlaceholder = ref.current.textContent;

        await dispatch(change(blockId, dataPath, trimmedPlaceholder));

        if (trimmedPlaceholder) {
            await dispatch(updateActiveBlock());
        }
    };

    // prevent jumping to next input placeholder text with current block still active
    // prevent line breaks
    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.code === 'Tab' || (preventLineBreak && event.code === 'Enter')) {
            event.preventDefault();
        }
    };

    return {
        updatedPlaceholder,
        handlePlaceholderChange,
        handlePlaceholderBlur,
        handleKeyDown,
    };
};
