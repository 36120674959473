import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import addMonths from 'date-fns/addMonths';
import datesAreEqual from 'date-fns/isEqual';
import max from 'date-fns/max';
import startOfMonth from 'date-fns/startOfMonth';
import subMonths from 'date-fns/subMonths';
import { memo, useEffect } from 'react';

import { cn } from '@/utils/cn';
import { useDateFormat } from '@/utils/hooks/useDateFormat';

import type { FC } from 'react';

interface Props {
    currentFocusedDate: Date;
    changeShownDate: Function;
    minDate?: Date;
    maxDate?: Date;
    className?: string;
}

const Navigation: FC<Props> = ({
    currentFocusedDate,
    changeShownDate,
    minDate,
    maxDate,
    className,
}) => {
    const label = useDateFormat(currentFocusedDate, 'MMMM yyyy');

    const disableForward =
        maxDate && datesAreEqual(startOfMonth(currentFocusedDate), startOfMonth(maxDate));
    const disableBackward =
        minDate && datesAreEqual(startOfMonth(currentFocusedDate), startOfMonth(minDate));

    useEffect(() => {
        // Check if currentFocusedDate is outside of funnel start/end date range, change view to start date month if needed
        const startOfMonthCurrent = startOfMonth(addMonths(currentFocusedDate, 1));

        if (minDate) {
            const startOfMonthMin = startOfMonth(minDate);
            const laterDate = max([startOfMonthCurrent, startOfMonthMin]);

            if (datesAreEqual(laterDate, startOfMonthMin)) {
                changeShownDate(minDate);
            }
        }
    }, [changeShownDate, currentFocusedDate, minDate]);

    const handleMonthChange = (direction: 'forward' | 'backward') => {
        const directionMethod = direction === 'forward' ? addMonths : subMonths;

        return () => {
            const newDate = directionMethod(currentFocusedDate, 1);
            changeShownDate(newDate);
        };
    };

    return (
        <div className={className ?? 'mb-2 mt-6 flex items-center px-4'}>
            <ChevronLeftIcon
                onClick={handleMonthChange('backward')}
                className={cn('w-8 cursor-pointer text-gray-400', {
                    'cursor-default text-gray-300': disableBackward,
                    'hover:text-blue-500': !disableBackward,
                })}
            />
            <div className="flex-1 text-center text-lg font-semibold">{label}</div>
            <ChevronRightIcon
                onClick={handleMonthChange('forward')}
                className={cn('w-8 cursor-pointer text-gray-400', {
                    'cursor-default text-gray-300': disableForward,
                    'hover:text-blue-500': !disableForward,
                })}
            />
        </div>
    );
};

Navigation.displayName = 'DatePickerNavigation';

export default memo(Navigation);
