import { createSlice } from '@reduxjs/toolkit';

import { NAME } from '../constants';

import type { AppState } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface State {
    lastMessageIdAdded: string | null;
}

const initialState: State = {
    lastMessageIdAdded: null,
};

const messagesSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setLastMessageIdAdded(state, actions: PayloadAction<string | null>) {
            return {
                ...state,
                lastMessageIdAdded: actions.payload,
            };
        },
        reset() {
            return initialState;
        },
    },
});

// Actions
export const { setLastMessageIdAdded, reset } = messagesSlice.actions;

// Selectors
export const getLastMessageIdAdded = (state: AppState) =>
    state[NAME].messagesReducer.lastMessageIdAdded;

export const messagesReducer = messagesSlice.reducer;
