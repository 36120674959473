import Link from 'next/link';

import { useCampaign } from '@/app/campaigns/hooks/queries/useCampaign';
import WorkspaceButton from '@/app/workspaces/components/WorkspaceSidebar/WorkspaceButton';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { getIsPreview, getWorkspaceById } from '@/app/workspaces/models/workspaces';
import { useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';
import { isDecember } from '@/utils/dates';
import { getIsWorkspaceEnv } from '@/utils/environments';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';
import { useUserCapabilities, CapabilitiesTarget } from '@/utils/hooks/useUserCapabilities';

import PerspectiveLogo from './PerspectiveLogo';

export interface Props {
    allowXmasLogo?: boolean;
    homeLinkDisabled?: boolean;
    isWorkspaceSubpage?: boolean;
    transparent?: boolean;
    onClick?: () => void;
}

const Logo = ({
    allowXmasLogo,
    homeLinkDisabled,
    transparent,
    isWorkspaceSubpage,
    onClick,
}: Props) => {
    const campaignId = getCampaignIdFromRouter();
    const { activeWorkspace } = useWorkspaces();

    const { canCreate } = useUserCapabilities(CapabilitiesTarget.Workspaces);
    const isWorkspaceEnv = getIsWorkspaceEnv();
    const isPreview = useAppSelector(getIsPreview);
    const { data: campaign } = useCampaign(campaignId);
    const campaignWorkspaceID = campaign?.relationships?.workspace.data?.id;
    const campaignWorkspace = useAppSelector((state) =>
        campaignWorkspaceID ? getWorkspaceById(state, campaignWorkspaceID) : null,
    );

    const currentWorkspace = campaignWorkspace ?? activeWorkspace;

    const hasXmasLogo = isDecember() && allowXmasLogo;

    const isCrmUser = !canCreate;
    const isDefaultWorkspace = currentWorkspace?.attributes?.default;

    // Always show Workspace Logo on a Workspace domain and Workspace Subpages
    const showWorkspaceLogo =
        (isCrmUser || isWorkspaceEnv || isWorkspaceSubpage) &&
        !isDefaultWorkspace &&
        currentWorkspace?.id;

    const isLoadingCampaignData = isWorkspaceSubpage && !campaignWorkspace;

    const linkToWorkspace =
        isWorkspaceSubpage && !isDefaultWorkspace && !isWorkspaceEnv && currentWorkspace?.id;
    const homeLinkUrl = linkToWorkspace ? `/workspaces/${currentWorkspace.id}` : '/';

    const wrapperClassName = cn('flex flex-shrink-0 items-center justify-start', {
        'pointer-events-none': homeLinkDisabled,
    });

    const homeLinkClassName = cn(
        'bump group flex items-center justify-center rounded-lg text-zero',
        {
            'hover:bg-gray-100': !transparent,
            'p-1': !showWorkspaceLogo && !isPreview && !hasXmasLogo,
            '-translate-x-0.5 p-0.5': showWorkspaceLogo || isPreview,
        },
    );

    if (isLoadingCampaignData) {
        return <div className={cn(wrapperClassName, 'size-11')} />;
    }

    if (isPreview) {
        return (
            <div className={wrapperClassName}>
                <div className={homeLinkClassName}>
                    {currentWorkspace?.attributes && (
                        <WorkspaceButton
                            id={currentWorkspace.id}
                            {...currentWorkspace.attributes}
                            withHover={false}
                            onClick={onClick}
                        />
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={wrapperClassName}>
            <Link href={homeLinkUrl} className={homeLinkClassName} onClick={onClick}>
                {showWorkspaceLogo ? (
                    currentWorkspace?.attributes && (
                        <WorkspaceButton
                            {...currentWorkspace.attributes}
                            disabled
                            onClick={onClick}
                        />
                    )
                ) : (
                    <PerspectiveLogo allowXmasLogo={allowXmasLogo} />
                )}
            </Link>
        </div>
    );
};

export default Logo;
