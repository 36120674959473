import type { Side } from '@floating-ui/react';

export const smoothConfig = {
    tension: 300,
    friction: 30,
};

export const bouncyConfig = {
    tension: 400,
    friction: 30,
};

export const stiffConfig = {
    tension: 500,
    friction: 40,
};

const animateFromTop = {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: -10 },
};

const animateFromBottom = {
    from: { opacity: 0, y: 10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
};

const animateFromLeft = {
    from: { opacity: 0, x: -10 },
    enter: { opacity: 1, x: 0 },
    leave: { opacity: 0, x: -10 },
};

const animateFromRight = {
    from: { opacity: 0, x: 10 },
    enter: { opacity: 1, x: 0 },
    leave: { opacity: 0, x: 10 },
};

const animateOpacity = {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
};

export const animationDirectionMap: Record<Side | 'none', object> = {
    left: animateFromLeft,
    right: animateFromRight,
    top: animateFromTop,
    bottom: animateFromBottom,
    none: animateOpacity,
};
