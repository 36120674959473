import {
    getBlockOrPreviewIsInColumn,
    getBlockOrPreviewIsInParentType,
    getBlockOrPreviewIsInSingleColumn,
    getBlockOrPreviewParentColumn,
    getColumnCount,
    getIsSingleColumn,
} from '@/app/editor/blocks/models/blocks';
import { useArtboardSize } from '@/app/editor/editor/hooks/useArtboardSize';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { getInsertingInBlankColumnId } from '@/app/editor/sections/models/insert';
import { useAppSelector } from '@/core/redux/hooks';

import type { BlockResource } from '@/app/editor/blocks/types';

const getIsParentColumnFramed = (parentColumn: BlockResource): boolean => {
    if (!parentColumn) {
        return false;
    }

    return parentColumn.attributes.content.framed;
};

export const useLayoutInfo = (blockId: string, isPreview = false) => {
    const blankColumnId = useAppSelector(getInsertingInBlankColumnId);
    const blankColumnIdIsInSingleColumn = useAppSelector((state) =>
        getIsSingleColumn(state, blankColumnId),
    );

    const isInColumn = useAppSelector((state) => getBlockOrPreviewIsInColumn(state, blockId));
    const isInSingleColumn = useAppSelector((state) =>
        getBlockOrPreviewIsInSingleColumn(state, blockId),
    );
    const parentColumn = useAppSelector((state) => getBlockOrPreviewParentColumn(state, blockId));
    const isInMediaAnswer = useAppSelector((state) =>
        getBlockOrPreviewIsInParentType(state, blockId, 'questionMediaAnswer'),
    );
    const isInListItem = useAppSelector((state) =>
        getBlockOrPreviewIsInParentType(state, blockId, 'listItem'),
    );

    const artBoardSize = useArtboardSize();

    const isBlockOrPreviewInSingleColumn =
        isInSingleColumn || (blankColumnIdIsInSingleColumn && isPreview);
    const isBlockOrPreviewInColumn = isInColumn || (!!blankColumnId && isPreview);

    const isVerticalOnly =
        artBoardSize === ArtBoardSize.MOBILE ||
        (isBlockOrPreviewInColumn && !isBlockOrPreviewInSingleColumn);

    const isInMultiColumnLayout = isInColumn && !isInSingleColumn;
    const columnCount = useAppSelector((state) => getColumnCount(state, blockId));

    return {
        artBoardSize,
        parentColumn,
        isInColumn: isBlockOrPreviewInColumn,
        isInSingleColumn: isBlockOrPreviewInSingleColumn,
        isVerticalOnly,
        isInFramedColumn: getIsParentColumnFramed(parentColumn),
        isInMediaAnswer,
        isInListItem,
        isInMultiColumnLayout,
        columnCount,
        isDesktop: artBoardSize === ArtBoardSize.DESKTOP,
        isTablet: artBoardSize === ArtBoardSize.TABLET,
        isMobile: artBoardSize === ArtBoardSize.MOBILE,
    };
};
