import { NAME } from '@/app/crm/constants';

import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import { FileInfo } from './FileInfo/FileInfo';
import { FileInfoErrorActions } from './FileInfo/FileInfoErrorActions';
import { FileInfoLoadingActions } from './FileInfo/FileInfoLoadingActions';
import { FileInfoIcon } from './FileInfoIcon';

import type { DropZoneProps } from '../../../hooks/useFileDropZone';
import type { UploadState } from '@/app/crm/types';

interface Props {
    uploadState: UploadState;
    inline: boolean;
    dropZoneProps: DropZoneProps;
}

export const FileStatus = ({ uploadState, inline, dropZoneProps }: Props) => {
    const { t } = useTranslation(NAME);
    const { onReset, getRootProps, getInputProps, open, isDragActive } = dropZoneProps;

    if (uploadState !== null) {
        switch (uploadState.status) {
            case 'success':
                return null;
            case 'uploading':
                return (
                    <FileInfo
                        filename={uploadState.filename}
                        statusLabel={<span className="text-blue-500">{t('uploading-file')}</span>}
                        actions={<FileInfoLoadingActions />}
                        inline={inline}
                    />
                );
            case 'error':
                return (
                    <FileInfo
                        filename={uploadState.filename}
                        statusLabel={
                            <Tooltip content={uploadState.message}>
                                <span className="text-red-500">{uploadState.error}</span>
                            </Tooltip>
                        }
                        actions={<FileInfoErrorActions onDelete={onReset} onRetry={open} />}
                        inline={inline}
                    />
                );
        }
    }

    return (
        <div className="flex h-full items-center gap-2 text-sm text-gray-500">
            <div
                className={cn(
                    'flex size-full items-center justify-start gap-2 bg-transparent px-2 text-gray-500 transition-colors hover:bg-gray-200',
                    {
                        'h-10 justify-center rounded-md bg-gray-100': !inline,
                        'bg-gray-50': isDragActive,
                    },
                )}
                {...getRootProps()}
            >
                <ArrowUpTrayIcon className="size-4" />
                {isDragActive ? (
                    <p className="text-blue-500">{t('drop-file')}</p>
                ) : (
                    <p>{t('upload-file')}</p>
                )}
                <input {...getInputProps()} />
            </div>
            {!inline && <FileInfoIcon />}
        </div>
    );
};
