import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { createLaunchPayload } from '@/app/campaigns/helpers/createLaunchPayload';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY } from '@/utils/empty';

import { setDomainEditing, setPublishSlug } from './domainEditing';
import {
    addPendingLaunchEvent,
    fetchCampaignAndVersions,
    fetchLaunchEvent,
    fetchLaunchEventEstimate,
    setRetries,
} from './launchEvents';
import { NAME, PUBLISH_HELP_ARTICLE_URL, PUBLISH_HELP_ARTICLE_URL_EN } from '../constants';

import type { CampaignResource } from '../types';
import type { DomainResource } from '@/app/domains/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    error: string;
    publishing: boolean;
    buildingCampaigns: string[];
}

const initialState: State = {
    error: '',
    publishing: false,
    buildingCampaigns: EMPTY_ARRAY,
};

export const publishSlice = createSlice({
    name: `${NAME}/publish`,
    initialState,
    reducers: {
        setPublishing(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                publishing: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>) {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBuildingCampaigns(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                buildingCampaigns: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setPublishing, setError, setBuildingCampaigns, reset } = publishSlice.actions;

// === Selectors ======

export const getBuildingCampaigns = (state: AppState) =>
    state[NAME]?.publishReducer?.buildingCampaigns;

// === Thunks ======

export const addToBuildingCampaigns = (campaignId: string): AppThunk => {
    return (dispatch, getState) => {
        const state = getState();
        const buildingCampaigns = getBuildingCampaigns(state);
        const updatedBuildingCampaigns = buildingCampaigns.slice();

        updatedBuildingCampaigns.push(campaignId);

        dispatch(setBuildingCampaigns(updatedBuildingCampaigns));
    };
};

const dataLaunchCampaign = (campaign: CampaignResource, payload: any) => {
    return async () => {
        return await apiPost(`/campaigns/${campaign.id}/launch`, payload);
    };
};

export const openHelpArticle = () => {
    window.open(
        Router.locale === 'de' ? PUBLISH_HELP_ARTICLE_URL : PUBLISH_HELP_ARTICLE_URL_EN,
        '_ blank',
    );
};

export const uiLaunchFailure =
    (
        error: string,
        errorToastContent: {
            actionText: string;
            message: string;
        },
    ): AppThunk =>
    async (dispatch) => {
        showToast({
            ...errorToastContent,
            onActionClick: openHelpArticle,
            type: 'warning',
        });

        dispatch(setPublishing(false));
        dispatch(setError(error));
    };

type PublishCampaignOptions = {
    campaign: CampaignResource;
    domain: DomainResource;
    slug: string;
    errorToastContent: {
        actionText: string;
        message: string;
    };
};

export const publishCampaign = ({
    campaign,
    domain,
    slug,
    errorToastContent,
}: PublishCampaignOptions): AppThunk<Promise<void>> => {
    return async (dispatch) => {
        // Init publishing
        dispatch(setPublishing(true));
        dispatch(setError(''));
        dispatch(setRetries(0)); // Reset launch event fetch retries

        dispatch(addToBuildingCampaigns(campaign.id));
        const payload = createLaunchPayload(domain?.id, slug);

        try {
            const response = await dispatch(dataLaunchCampaign(campaign, payload));
            const { id: eventId } = getDataFromResponse(response);

            await dispatch(addPendingLaunchEvent(eventId));
            await dispatch(fetchLaunchEvent(eventId));
            await dispatch(fetchLaunchEventEstimate(eventId));
            await dispatch(fetchCampaignAndVersions(campaign.id));

            // Publish success
            dispatch(setPublishing(false));
            dispatch(setError(''));
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: 'publishing campaign failed:',
                silent: true,
            });
            dispatch(uiLaunchFailure(err, errorToastContent));
        }
    };
};

export const removeFromBuildingCampaigns = (campaignId: string): AppThunk => {
    return (dispatch, getState) => {
        const state = getState();
        const buildingCampaigns = getBuildingCampaigns(state);
        const updatedBuildingCampaigns = buildingCampaigns.slice();

        const index = updatedBuildingCampaigns.indexOf(campaignId);

        if (index > -1) {
            updatedBuildingCampaigns.splice(index, 1);
        }

        dispatch(setBuildingCampaigns(updatedBuildingCampaigns));
    };
};

export const saveSlug = (slug: string = '', toastMessage: string, campaignId: string): AppThunk => {
    return (dispatch) => {
        dispatch(setDomainEditing(false));
        dispatch(setPublishSlug({ slug, campaignId }));
        showToast({
            type: 'success',
            message: toastMessage,
        });
    };
};

export default publishSlice.reducer;
