import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import find from 'lodash/find';
import get from 'lodash/get';
import { Fragment, useRef } from 'react';

import { useDropdownDirection } from '@/hooks/useDropdownDirection';
import { cn } from '@/utils/cn';

import Option from './Option';

import type { DropdownOption } from '@/ui/types';

export interface Props {
    value: string;
    onChange: (value: string) => void;
    options: DropdownOption[];
    sprite: string;
    offsetMap: { [icon: string]: number };
}

const PlatformSelection = ({ value, onChange, options, sprite, offsetMap }: Props) => {
    const selectRef = useRef(null);
    const direction = useDropdownDirection(selectRef, 240);
    const selectedOption = find(options, { value });

    return (
        <Listbox value={value} onChange={onChange}>
            <div className="relative" ref={selectRef}>
                <Listbox.Button className="group relative flex w-full cursor-pointer items-center text-left text-sm hover:border-blue-500 focus:outline-none">
                    <span className="text-xl font-medium">{get(selectedOption, 'key', '-')}</span>
                    <span className="pointer-events-none inset-y-0 ml-2 flex items-center">
                        <ChevronUpDownIcon
                            className="size-5 text-gray-400 group-hover:text-gray-600"
                            aria-hidden="true"
                        />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    enter="transition-all duration-100"
                    enterFrom="opacity-0 -translate-y-4"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition-all duration-100"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-4"
                >
                    <Listbox.Options
                        className={cn(
                            'absolute z-20 max-h-60 w-full overflow-auto rounded-md bg-white p-2 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                            direction === 'up' ? 'bottom-12 mb-2' : 'mt-2',
                        )}
                    >
                        {options.map((option, index) => (
                            <Option
                                option={option}
                                key={`${option.key}-${index}`}
                                sprite={sprite}
                                offsetMap={offsetMap}
                            />
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    );
};

export default PlatformSelection;
