import { Children, cloneElement, isValidElement } from 'react';

import SortableList from '@/app/editor/blocks/components/_wrapper/SortableList';
import { getPreviewIdToShow } from '@/app/editor/blocks/helpers';
import { getBgImageStyles } from '@/app/editor/blocks/helpers/getBgImageStyles';
import { SectionPreview } from '@/app/editor/editor/components/ArtBoard/SectionPreview';
import { AddBlockButton } from '@/app/editor/editor/components/ArtBoard/SortableBlockList/AddBlockButton';
import { useArtboardSize } from '@/app/editor/editor/hooks/useArtboardSize';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';
import { getActiveSectionPreviewId, getPreviewBlockId } from '@/app/editor/sections/models/insert';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';

import NewComponent from './NewComponent';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { Props as BlockProps } from '@/app/editor/editor/components/ArtBoard/Block/Block.controller';
import type { FC, ReactElement } from 'react';

export interface Props extends DefaultBlockComponentProps {
    verticalAlign: 'start' | 'center' | 'end';
    framed: boolean;
}

interface EmptyColumnProps {
    sectionPreviewId: string;
    blockId: string;
}

const EmptyColumn: FC<EmptyColumnProps> = ({ sectionPreviewId, blockId }) => (
    <>
        {sectionPreviewId ? (
            <SectionPreview sectionPreviewId={sectionPreviewId} />
        ) : (
            <div className="opacity-0 transition-opacity group-hover/column:opacity-100">
                <AddBlockButton columnBlockId={blockId} />
            </div>
        )}
    </>
);

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({ blockId, nestedLevel, children, box, verticalAlign, isPreview, framed, activeTheme }) => {
        const artboardSize = useArtboardSize();
        const borderRadiusClass = useBorderRadius('blockFrame');
        const activeSectionPreviewId = useAppSelector(getActiveSectionPreviewId);
        const previewBlockId = useAppSelector(getPreviewBlockId);
        const sectionPreviewId = getPreviewIdToShow({
            blockId,
            previewBlockId,
            sectionPreviewId: activeSectionPreviewId,
        });

        const { backgroundColor, bgImage } = box || {};
        const { visible, src } = bgImage || {};
        const wrapperClass = cn('flex h-full flex-col transition-all', borderRadiusClass, {
            'justify-start': verticalAlign === 'start',
            'justify-center': verticalAlign === 'center',
            'justify-end': verticalAlign === 'end',
            'p-4':
                (artboardSize === ArtBoardSize.TABLET && !framed) ||
                (artboardSize === ArtBoardSize.MOBILE && framed),
            'p-6':
                (artboardSize === ArtBoardSize.TABLET && framed) ||
                (artboardSize === ArtBoardSize.DESKTOP && !framed),
            'p-2': artboardSize === ArtBoardSize.MOBILE && !framed,
            'p-10': artboardSize === ArtBoardSize.DESKTOP && framed,
            'min-h-[375px]': framed && visible,
        });

        // Enrich children with inColumn prop
        if (children) {
            children = Children.map(children, (child) => {
                return isValidElement<BlockProps>(child)
                    ? cloneElement(child as ReactElement, {
                          propsFromParent: {
                              isInColumn: true,
                          },
                      })
                    : null;
            });
        }

        return (
            <div
                className={cn('group/column relative h-full', borderRadiusClass)}
                style={{
                    background: framed
                        ? getThemedOrCustomColor(backgroundColor, 'backgroundColor', activeTheme)
                        : 'transparent',
                }}
            >
                {/* Background Image */}
                {visible && src && framed && (
                    <div
                        className={cn(
                            'pointer-events-none absolute inset-0 bg-cover bg-no-repeat',
                            borderRadiusClass,
                        )}
                        style={getBgImageStyles(bgImage)}
                    />
                )}
                {/* Content */}
                <div className={wrapperClass}>
                    {isPreview ? (
                        children
                    ) : children ? (
                        <SortableList
                            parentBlockId={blockId}
                            sortingStrategy="vertical"
                            nestedLevel={nestedLevel}
                            noContext
                        >
                            {children}
                        </SortableList>
                    ) : (
                        // No children -> Empty column case
                        <EmptyColumn sectionPreviewId={sectionPreviewId} blockId={blockId} />
                    )}
                </div>
            </div>
        );
    },
    NewComponent,
);

Component.displayName = 'GridColumn';

export default Component;
