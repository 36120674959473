import { BLOCK_INTROS } from '@/app/editor/blocks/constants';
import { NAME } from '@/app/editor/sections/constants';
import { MODAL_OPTIONS } from '@/app/modals/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import xor from 'lodash/xor';

import { getActiveCampaign } from '@/app/campaigns/models/campaigns';
import {
    getBlockIndexInParent,
    getBlockIndexOnPage,
    getPageBlockIds,
    setPageBlockOrder,
} from '@/app/editor/blocks/models/blockOrder';
import {
    fetchPageBlocks,
    getActiveBlockId,
    getBlockById,
    getBlockParent,
    setActiveBlock,
} from '@/app/editor/blocks/models/blocks';
import { BlockIntro } from '@/app/editor/blocks/types';
import { setActiveView } from '@/app/editor/editor/models/sidebar';
import { fetchSinglePage, getActivePageId } from '@/app/editor/pages/models/pages';
import { getSectionById, getSectionByName } from '@/app/editor/sections/models/sections';
import { showModal } from '@/app/modals/models/modals';
import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse, getRelationship } from '@/core/api/helper';
import { track } from '@/core/tracking';
import { EMPTY_STRING } from '@/utils/empty';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { PageResource } from '@/app/editor/pages/types';
import type { RelationshipObject } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';

export interface State {
    insertIndex: number;
    activeSectionId: string;
    previewBlockId: string;
    usingSection: boolean;
    insertingInBlankColumnId: string;
}

const initialState: State = {
    insertIndex: 0,
    activeSectionId: EMPTY_STRING,
    previewBlockId: EMPTY_STRING,
    usingSection: false,
    insertingInBlankColumnId: EMPTY_STRING,
};

export const insertSlice = createSlice({
    name: `editor/${NAME}/insert`,
    initialState,
    reducers: {
        setActiveSectionId(state, action: PayloadAction<string>) {
            return {
                ...state,
                activeSectionId: action.payload,
            };
        },
        setUsingSection(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                usingSection: action.payload,
            };
        },
        setPreviewBlockId(state, action: PayloadAction<string>) {
            return {
                ...state,
                previewBlockId: action.payload,
            };
        },
        setInsertingInBlankColumnId(state, action: PayloadAction<string>) {
            return {
                ...state,
                insertingInBlankColumnId: action.payload,
            };
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======

export const {
    setActiveSectionId,
    setPreviewBlockId,
    setUsingSection,
    setInsertingInBlankColumnId,
    reset,
} = insertSlice.actions;

// === Selectors ======

export const getPreviewBlockId = (state: AppState) => state[NAME]?.insertReducer?.previewBlockId;

// export const getInsertIndex = (state: AppState) => state[NAME]?.insertReducer?.insertIndex;
export const getInsertIndex = (state: AppState) => {
    const previewBlockId = getPreviewBlockId(state);
    const previewBlockParent = getBlockParent(state, previewBlockId);

    let index: number;

    if (previewBlockParent) {
        index = getBlockIndexInParent(state, previewBlockId);
    } else {
        index = getBlockIndexOnPage(state, previewBlockId);

        // Preview is block that has no index (e.g. Header)
        if (index === -1) {
            return 0;
        }
    }

    return index + 1;
};

export const getActiveSectionId = (state: AppState) => state[NAME]?.insertReducer?.activeSectionId;

export const getUsingSection = (state: AppState) => state[NAME]?.insertReducer?.usingSection;

export const getActiveSectionPreviewId = (state: AppState) => {
    const sectionId = getActiveSectionId(state);
    const section = getSectionById(state, sectionId);

    return get(section, 'relationships.components.data[0].id');
};

export const getInsertingInBlankColumnId = (state: AppState) =>
    state[NAME]?.insertReducer?.insertingInBlankColumnId;

// === Helper ======

const getNewBlockId = (newBlock: BlockResource, parentBlock: BlockResource) => {
    const currentParentBlockIds: string[] = getRelationship(parentBlock, 'components').map(
        (block: RelationshipObject) => block.id,
    );

    const newChildBlockIds: string[] = getRelationship(newBlock, 'components').map(
        (block: RelationshipObject) => block.id,
    );

    const newBlockIds = xor(currentParentBlockIds, newChildBlockIds);

    return newBlockIds[0];
};

export const setActiveBlockFromSection =
    (usedSectionId: string, newBlockId: string): AppThunk =>
    (dispatch, getState) => {
        const state = getState();

        const section = getSectionById(state, usedSectionId);
        const newBlock = getBlockById(state, newBlockId);

        const sectionName = get(section, 'attributes.name');

        // In a 1 column section we want to select the block inside the column
        if (sectionName?.startsWith('1c-')) {
            const column = getBlockById(
                state,
                get(newBlock, 'relationships.components.data[0].id'),
            );
            const columnBlocks = get(column, 'relationships.components.data');

            // If there is only 1 child block in the column, select it
            if (columnBlocks?.length === 1) {
                return dispatch(setActiveBlock(columnBlocks[0].id));
            }
        }

        // otherwise select the new block directly
        dispatch(setActiveBlock(newBlockId));
    };

// Show intro modal for certain sections
const showSectionIntro =
    (sectionId: string): AppThunk =>
    (dispatch, getState) => {
        const section = getSectionById(getState(), sectionId);
        const sectionName = get(section, 'attributes.name');
        let introKey: BlockIntro | undefined = undefined;

        // check if sectionName includes "embed" or "calendly"
        if (sectionName?.includes(BlockIntro.EMBED)) {
            introKey = BlockIntro.EMBED;
        } else if (sectionName?.includes(BlockIntro.CALENDLY)) {
            introKey = BlockIntro.CALENDLY;
        }

        if (!introKey) {
            return;
        }

        const { localStorageKey, modal } = BLOCK_INTROS[introKey];

        const embedIntroSeen = localStorage.getItem(localStorageKey);

        if (!embedIntroSeen) {
            dispatch(showModal(modal, {}, MODAL_OPTIONS.tall));
        }
    };

const handleInsert =
    (
        sectionId: string,
        payload: {
            campaign: string;
            index: number;
            page: string;
            parent?: string;
        },
    ): AppThunk<Promise<PageResource | BlockResource>> =>
    async (dispatch) => {
        const response = await apiPost(`/sections/${sectionId}/use`, {
            data: payload,
        });

        const responseData: PageResource | BlockResource = getDataFromResponse(response);

        let pageId: string;

        if (responseData?.type === 'page') {
            // responseData is a PageResource
            pageId = responseData.id;
        } else {
            // responseData is a BlockResource
            pageId = getRelationship(responseData, 'page')?.id;
        }

        // Re-fetch page for new components relationships
        const page = await dispatch(fetchSinglePage(pageId));

        // fetch blocks of page; includes the new blocks + nested blocks
        await dispatch(fetchPageBlocks(pageId));

        // optimistically add blockId to pages blockOrder
        dispatch(setPageBlockOrder(page));

        return responseData;
    };

const createInsertFunction =
    (
        getSectionId: (state: AppState) => string,
        getPayload: (state: AppState, sectionId: string) => any,
        handleResponse: (
            responseData: PageResource | BlockResource,
            dispatch: ThunkDispatch<any, any, any>,
            state: AppState,
            sectionId: string,
        ) => string,
    ) =>
    (): AppThunk<Promise<string>> =>
    async (dispatch, getState) => {
        const state = getState();

        const sectionId = getSectionId(state);
        const section = getSectionById(state, sectionId);
        const usingSection = getUsingSection(state);

        // Prevents accidentally adding multiple section (double click etc.)
        if (usingSection) {
            return;
        }

        // Tracking
        track(TRACKING_EVENTS.campaign.editor.sections.used, {
            id: sectionId,
            name: get(section, 'attributes.name'),
        });

        try {
            // Set Loading state
            dispatch(setUsingSection(true));

            // Request payload
            const payload = getPayload(state, sectionId);

            const responseData: PageResource | BlockResource = await dispatch(
                handleInsert(sectionId, payload),
            );

            return handleResponse(responseData, dispatch, state, sectionId);
        } catch (err) {
            handleRuntimeError(err, { debugMessage: `using section ${sectionId} failed:` });
            dispatch(setActiveView('pages'));
        } finally {
            // reset insert state
            dispatch(reset());
            dispatch(setUsingSection(false));
        }
    };

// === Thunks ======

export const insertActiveSection = createInsertFunction(
    getActiveSectionId,
    (state) => {
        const activeCampaign = getActiveCampaign(state);
        const insertIndex = getInsertIndex(state);
        const pageId = getActivePageId(state);
        const previewBlockId = getPreviewBlockId(state);
        const previewBlockParent = getBlockParent(state, previewBlockId);
        const isInsertingInParent = !!previewBlockParent;
        const insertingInBlankColumnId = getInsertingInBlankColumnId(state);

        const payload = {
            campaign: activeCampaign.id,
            index: insertIndex,
            page: pageId,
        };

        // depending on the preview block, we need to adjust the payload
        if (isInsertingInParent && !insertingInBlankColumnId) {
            // use section in parent
            payload['parent'] = previewBlockParent.id;
        }

        if (insertingInBlankColumnId) {
            // use section in new column
            payload['parent'] = previewBlockId;
        }

        return payload;
    },
    (responseData, dispatch, state, sectionId) => {
        let page: PageResource;
        let newBlockId: string;

        // When inserting into a parent component the API returns the new parent block with updated relationships;
        // otherwise it returns the new page the block is inserted in
        if (responseData.type === 'component') {
            const newBlockParent = responseData as BlockResource;
            const previewBlockParent = getBlockParent(state, getPreviewBlockId(state));
            const insertingInBlankColumnId = getInsertingInBlankColumnId(state);

            if (insertingInBlankColumnId) {
                newBlockId = getRelationship(newBlockParent, 'components')?.[0].id;
            } else {
                newBlockId = getNewBlockId(newBlockParent, previewBlockParent);
            }
        } else {
            page = responseData as PageResource;

            if (!page) {
                return;
            }

            // set block as active block
            const newPageBlockIds = getRelationship(page, 'components').map(
                (block: RelationshipObject) => block.id,
            );
            const currentPageBlockIds = getPageBlockIds(state, getActivePageId(state));
            const newBlockIds = xor(currentPageBlockIds, newPageBlockIds);

            newBlockId = newBlockIds[0];
        }

        dispatch(setActiveBlockFromSection(sectionId, newBlockId));
        dispatch(showSectionIntro(sectionId));

        return newBlockId;
    },
);

export const insertColumnInLayout = createInsertFunction(
    (state) => getSectionByName(state, '1b-column').id,
    (state) => {
        const activeCampaign = getActiveCampaign(state);
        const activeColumnId = getActiveBlockId(state);
        const layoutBlock = getBlockParent(state, activeColumnId);
        const columnIndex = getBlockIndexInParent(state, activeColumnId);
        const pageId = getActivePageId(state);

        return {
            campaign: activeCampaign.id,
            index: columnIndex + 1,
            page: pageId,
            parent: layoutBlock.id,
        };
    },
    (responseData, dispatch, state) => {
        const newBlock = responseData as BlockResource;
        const layoutBlock = getBlockParent(state, getActiveBlockId(state));

        const newBlockId = getNewBlockId(newBlock, layoutBlock);

        // Set active block to new column
        dispatch(setActiveBlock(newBlockId));

        return newBlockId;
    },
);

export default insertSlice.reducer;
