import {
    useFloating,
    useInteractions,
    useHover,
    useFocus,
    useRole,
    useDismiss,
    FloatingPortal,
    offset,
    flip,
    shift,
    autoUpdate,
    safePolygon,
} from '@floating-ui/react';
import { animated, useTransition } from '@react-spring/web';
import { cloneElement } from 'react';
import { useState, useMemo } from 'react';
import { mergeRefs } from 'react-merge-refs';

import { cn } from '@/utils/cn';
import { smoothConfig } from '@/utils/spring/configs';

import HotkeyLabels from './HotkeyLabels';

import type { Placement } from '@floating-ui/core';
import type { ReactNode } from 'react';

export interface Props {
    content: ReactNode;
    placement?: Placement;
    children: JSX.Element;
    offsetValue?: number;
    delay?: number;
    disabled?: boolean;
    keepOpenOnHover?: boolean;
    className?: string;
    hotkeys?: string[];
    controlledIsOpen?: boolean;
    onControlledOpen?: (open: boolean) => void;
}

const originMap: {
    [placement in Placement]: string;
} = {
    'top-start': 'origin-bottom-left',
    top: 'origin-bottom',
    'top-end': 'origin-bottom-right',
    'right-start': 'origin-top-left',
    right: 'origin-left',
    'right-end': 'origin-bottom-left',
    'bottom-start': 'origin-top-left',
    bottom: 'origin-top',
    'bottom-end': 'origin-top-right',
    'left-start': 'origin-bottom-right',
    left: 'origin-right',
    'left-end': 'origin-bottom-right',
};

export const Tooltip = ({
    children,
    content,
    placement = 'top',
    offsetValue = 5,
    delay = 40,
    disabled = false,
    className,
    hotkeys,
    onControlledOpen,
    keepOpenOnHover = false,
    controlledIsOpen = false,
}: Props) => {
    const [isOpen, setIsOpen] = useState(controlledIsOpen);

    const handleOpen = (newOpenState: boolean) => {
        setIsOpen(newOpenState);
        onControlledOpen?.(newOpenState);
    };

    const { x, y, strategy, context, refs } = useFloating({
        placement,
        open: isOpen,
        onOpenChange: handleOpen,
        middleware: [offset(offsetValue), flip(), shift({ padding: 8 })],
        whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context, {
            restMs: delay,
            handleClose: keepOpenOnHover ? safePolygon() : null,
        }),
        useFocus(context),
        useRole(context, { role: 'tooltip' }),
        useDismiss(context),
    ]);

    // Preserve the consumer's ref
    const ref = useMemo(
        () => mergeRefs([refs.setReference, (children as any).ref]),
        [refs, children],
    );

    // React spring
    const transitions = useTransition(isOpen, {
        from: { opacity: 0, scale: 0.85 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 1 },
        config: smoothConfig,
        reverse: isOpen,
    });

    return (
        <>
            {cloneElement(children, getReferenceProps({ ref, ...children.props }))}
            {!disabled &&
                content &&
                transitions((style, visible) => {
                    if (visible) {
                        return (
                            <FloatingPortal>
                                <animated.div
                                    style={{
                                        ...style,
                                        position: strategy,
                                        top: y ?? 0,
                                        left: x ?? 0,
                                    }}
                                    ref={refs.setFloating}
                                    className={cn('z-[99999]', originMap[placement])}
                                    {...getFloatingProps()}
                                >
                                    <div
                                        className={cn(
                                            'flex items-center rounded-md bg-gray-800 px-2 py-1.5 font-sans text-sm text-white',
                                            className ?? 'max-w-sm',
                                        )}
                                    >
                                        <span className="whitespace-pre-line">{content}</span>
                                        {!!hotkeys && <HotkeyLabels hotkeys={hotkeys} />}
                                    </div>
                                </animated.div>
                            </FloatingPortal>
                        );
                    }
                })}
        </>
    );
};

export default Tooltip;
