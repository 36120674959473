import { NAME } from '@/app/crm/constants';
import { MODAL_OPTIONS } from '@/app/modals/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { InboxArrowDownIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';

import { PropertyActionType } from '@/app/crm/types';
import { downloadFile } from '@/app/crm/utils/downloadFile';
import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { showToast } from '@/app/toasts/utils/showToast';
import { handleRuntimeError } from '@/core/api';
import { useAppDispatch } from '@/core/redux/hooks';
import { track } from '@/core/tracking';
import { cn } from '@/utils/cn';
import { stopPropagation } from '@/utils/common';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';
import { reportError, reportMessage } from '@/utils/sentry';
import { isValidUrl } from '@/utils/validations';

import { FileInfo } from './FileInfo/FileInfo';
import { FileInfoDefaultActions } from './FileInfo/FileInfoDefaultActions';
import { FileStatus } from './FileStatus';

import type { FieldType, PropertyOwner } from '@/app/crm/types';
import type { AxiosResponse } from 'axios';
import type { FieldRenderProps } from 'react-final-form';

type FileDownloadData = {
    downloadUrl: string;
    infected: boolean;
    status: 'Deleted' | 'InProgress' | 'Finished';
};

interface Props extends FieldRenderProps<string> {
    value: string;
    fieldType: FieldType;
    owner: PropertyOwner;
    inline: boolean;
    onSubmit: () => void;
}

const extractJobIdFromDownloadFieldValue = (value: string) => {
    const url = new URL(value);
    const jobId = url.pathname.split('/')[1];

    return jobId;
};

const buildRequestDownloadURL = (jobId: string) => {
    const apiUrl = process.env.NEXT_PUBLIC_API_URL ?? '';

    return `${apiUrl}/uploads/${jobId}`;
};

export const FileInput = ({
    value,
    fieldType,
    owner,
    onSubmit,
    isTableView,
    input,
    dropZoneProps,
    fileUpload,
}: Props) => {
    const { t } = useTranslation(NAME);
    const campaignId = getCampaignIdFromRouter();
    const dispatch = useAppDispatch();

    const fieldValue = value ?? input.value;

    const validUrl = isValidUrl(fieldValue);

    const filename = validUrl && new URL(fieldValue).searchParams.get('filename');
    const filesize = validUrl && new URL(fieldValue).searchParams.get('filesize');

    const previousFilename = useRef(filename);

    const handleDownload = async () => {
        const jobId = extractJobIdFromDownloadFieldValue(fieldValue);
        const requestDownloadURL = buildRequestDownloadURL(jobId);

        let response: AxiosResponse<FileDownloadData> | null = null;

        try {
            response = await axios<FileDownloadData>(requestDownloadURL);
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'download URL request failed' });

            return;
        }

        const {
            data: { infected, status, downloadUrl },
        } = response;

        if (infected) {
            showToast({
                type: 'error',
                message: `${NAME}:infected-file-error-message`,
            });

            return;
        }

        if (status === 'Deleted') {
            showToast({
                type: 'error',
                message: `${NAME}:deleted-file-error-message`,
            });

            return;
        }

        if (status === 'InProgress') {
            showToast({
                type: 'error',
                message: `${NAME}:in-progress-file-error-message`,
            });

            return;
        }

        if (!downloadUrl) {
            showToast({
                type: 'error',
                message: `${NAME}:unexpected-file-error-message`,
            });

            reportError({
                error: 'Attempted to download a file without downloadUrl',
                source: 'FileInput',
            });

            return;
        }

        downloadFile(downloadUrl);

        // Track Download click
        track(TRACKING_EVENTS.crm.profile.action, {
            campaign_id: campaignId ?? '',
            property_action_type: PropertyActionType.download,
            crm_property_owner: owner,
            crm_property_data_type: fieldType,
        });
    };

    const handleConfirmDeleteFile = () => {
        input.onChange(undefined);
        onSubmit();
    };

    const handleDeleteFile = (event) => {
        stopPropagation(event);

        dispatch(
            showModal(
                Modals.CONFIRM_FILE_DELETION,
                { onConfirm: handleConfirmDeleteFile },
                MODAL_OPTIONS.CONFIRM_FILE_DELETION,
            ),
        );
    };

    if (!!fieldValue && !validUrl) {
        reportMessage({
            message: `Malformed file found: "${fieldValue}. Probably a form with multiple file inputs was created.`,
            source: 'file-input',
        });
    }

    if (!fieldValue || !validUrl) {
        return (
            <FileStatus
                uploadState={fileUpload?.state ?? null}
                inline={isTableView}
                dropZoneProps={dropZoneProps}
            />
        );
    }

    if (filename) {
        const isRecentlyUploaded = previousFilename.current !== filename;
        previousFilename.current = filename;

        return (
            <FileInfo
                filename={filename}
                inline={isTableView}
                filesize={filesize ? +filesize : 0}
                actions={
                    <FileInfoDefaultActions
                        onDelete={handleDeleteFile}
                        onDownload={handleDownload}
                        recentlyUploaded={isRecentlyUploaded}
                    />
                }
            />
        );
    }

    return (
        <div className="relative ml-2 flex h-full items-center justify-between gap-1 py-1.5 pr-2">
            <button
                className={cn(
                    'flex select-none items-center gap-2 rounded-md border border-gray-200 bg-gray-50 px-1.5 py-0.5 text-sm text-gray-600 transition-colors',
                    {
                        'hover:bg-gray-100': fieldValue,
                        'cursor-default opacity-50': !fieldValue,
                    },
                )}
                onClick={handleDownload}
            >
                <InboxArrowDownIcon className="size-4" />
                <span>{t('download-file')}</span>
            </button>
            <button
                className="flex size-6 shrink-0 cursor-pointer items-center justify-center rounded-md text-sm text-gray-500 transition-colors hover:bg-gray-200"
                onClick={handleDeleteFile}
            >
                <TrashIcon className="size-4" />
            </button>
        </div>
    );
};
